import { BookmarkReason } from '@interfaces/graphql'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { DateFilterValue } from '@src/partials/searchv2/components/CommonDateFilter/CommonDateFilter'
import { SaveSearchModal2Actions } from '@src/partials/searchv2/components/SaveSearchModal2/SaveSearchModal2.actions'
import { LEGACY_Bookmark } from '@src/partials/searchv2/redux/LEGACY_BookmarkOptimisticSlice.types'
import { SearchPageSliceActions, commonUpdateSelectedListing } from '@src/partials/searchv2/redux/SearchPageSlice'
import { SearchPageQuerySliceActions } from '@src/partials/searchv2/widgets/DefaultQueryWithSaveButtonAndFiltersWidget/SearchPageQuerySlice'
import { createSAMViewFromQuery } from '../sam-view-state'
import { SearchPageSAMSliceType, isSearchPageSAMSliceReady } from './SearchPageSAMSlice.types'

const initState: SearchPageSAMSliceType = {
  state: 'initial',
}

/**
 * This slice contains some common actions used by all the views.
 */
const SearchPageSAMSlice = createSlice({
  name: 'SearchPageSAMSlice',
  initialState: initState as SearchPageSAMSliceType,
  reducers: {
    setNAICSFilter: (
      slice,
      action: PayloadAction<Array<{ id: string; value: string; label: string }> | null>
    ): void => {
      if (!isSearchPageSAMSliceReady(slice)) return
      slice.view.pageFrom = 0
      slice.view.persisted.naics = action.payload
    },
    setAgencyFilter: (
      slice,
      action: PayloadAction<Array<{ id: string; value: string; label: string }> | null>
    ): void => {
      if (!isSearchPageSAMSliceReady(slice)) return
      slice.view.pageFrom = 0
      slice.view.persisted.agency = action.payload
    },
    setPlaceOfPerformanceFilter: (
      slice,
      action: PayloadAction<Array<{ id: string; value: string; label: string }> | null>
    ): void => {
      if (!isSearchPageSAMSliceReady(slice)) return
      slice.view.pageFrom = 0
      slice.view.persisted.placeOfPerformance = action.payload
    },
    setAwardTypeFilter: (
      slice,
      action: PayloadAction<Array<{ id: string; value: string; label: string }> | null>
    ): void => {
      if (!isSearchPageSAMSliceReady(slice)) return
      slice.view.pageFrom = 0
      slice.view.persisted.awardType = action.payload
    },
    setSetAsideFilter: (
      slice,
      action: PayloadAction<Array<{ id: string; value: string; label: string }> | null>
    ): void => {
      if (!isSearchPageSAMSliceReady(slice)) return
      slice.view.pageFrom = 0
      slice.view.persisted.setaside = action.payload
    },
    setOpenFilter: (slice, action: PayloadAction<boolean | null>): void => {
      if (!isSearchPageSAMSliceReady(slice)) return
      slice.view.pageFrom = 0
      slice.view.persisted.open = action.payload
    },
    setRespondByDateFilter: (slice, action: PayloadAction<DateFilterValue | null>): void => {
      if (!isSearchPageSAMSliceReady(slice)) return
      slice.view.pageFrom = 0
      slice.view.persisted.respondByDate = action.payload
    },
    setPostedOnDateFilter: (slice, action: PayloadAction<DateFilterValue | null>): void => {
      if (!isSearchPageSAMSliceReady(slice)) return
      slice.view.pageFrom = 0
      slice.view.persisted.postedOnDate = action.payload
    },
    setSortBy: (
      slice,
      action: PayloadAction<{
        field: { id: string; value: string; label: string } | null
        direction: 'asc' | 'desc' | null
      }>
    ): void => {
      if (!isSearchPageSAMSliceReady(slice)) return
      slice.view.pageFrom = 0
      slice.view.persisted.sortBy = action.payload.field
      slice.view.persisted.sortByDirection = action.payload.direction
    },
    setActiveSavedSearch: (slice, action: PayloadAction<{ id: string; value: string; label: string } | null>): void => {
      if (!isSearchPageSAMSliceReady(slice)) return
      slice.view.pageFrom = 0
      slice.view.savedSearch = action.payload
    },
    setPageFrom: (slice, action: PayloadAction<number>): void => {
      if (!isSearchPageSAMSliceReady(slice)) return
      slice.view.pageFrom = action.payload
    },
    setReviewStatus: (
      slice,
      action: PayloadAction<{
        id: string
        value: Array<BookmarkReason> | 'all-opps'
        label: string
      } | null>
    ): void => {
      if (!isSearchPageSAMSliceReady(slice)) return
      slice.view.pageFrom = 0
      slice.view.persisted.reviewStatus = action.payload
    },
    setAdvanced: (slice, action: PayloadAction<boolean | null>): void => {
      if (!isSearchPageSAMSliceReady(slice)) return
      slice.view.pageFrom = 0
      slice.view.persisted.advanced = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(
      SearchPageSliceActions.setSelectedListing,
      (
        slice,
        action: PayloadAction<{
          listing: any | null
          source: string
          externalId: string
        } | null>
      ): void => {
        if (!isSearchPageSAMSliceReady(slice)) return
        commonUpdateSelectedListing(slice, action.payload)
      }
    )
    builder.addCase(
      SearchPageSliceActions.onLoadedListings,
      (
        state,
        action: PayloadAction<{
          // As with the legacy code, this expects parallel arrays
          listings: Array<{ id: string }>
          bookmarks: Array<LEGACY_Bookmark | null>
          numHits: number
        }>
      ) => {
        if (!isSearchPageSAMSliceReady(state)) return
        state.view.hitCount = action.payload.numHits
      }
    )
    builder.addCase(SearchPageSliceActions.init, (slice, action) => {
      const { query } = action.payload

      const view = createSAMViewFromQuery(query)
      if (view) {
        return {
          state: 'ready',
          view: view,
        }
      }
    })
    builder.addCase(SearchPageSliceActions.reset, (slice, action) => {
      return initState
    })
    builder.addCase(SaveSearchModal2Actions.created, (slice, action) => {
      const { savedSearchName, savedSearchUUId } = action.payload
      if (!isSearchPageSAMSliceReady(slice)) return
      slice.view.savedSearch = {
        id: savedSearchUUId,
        value: savedSearchUUId,
        label: savedSearchName,
      }
    })
    builder.addCase(SearchPageQuerySliceActions.queryChange, (slice, action) => {
      const { query } = action.payload
      if (!isSearchPageSAMSliceReady(slice)) return
      slice.view.pageFrom = 0
      slice.view.persisted.queryString = query
    })
  },
})

export const SearchPageSAMSliceActions = SearchPageSAMSlice.actions
export const SearchPageSAMReducer = SearchPageSAMSlice.reducer
