import { Feature } from './types'
import { WorkspaceSlice } from '@src/redux/reducers/WorkspaceSlice'

export const ALLEGIENT_WORKSPACE_ID = 6320

export interface IsEnabledFeatureParams {
  feature: Feature
  workspace?: Partial<WorkspaceSlice>
  enabledLocalStorage?: boolean
  developmentEnvironment?: boolean
}

export function isEnabledLocalStorage(feature: Feature) {
  try {
    const enabled = localStorage.getItem(`dev_${feature}`)
    return !!enabled && enabled.toLowerCase() === 'true'
  } catch (e) {
    return false
  }
}

export function isDevelopmentEnvironment(): boolean {
  return process.env.NODE_ENV === 'development'
}

export function isEnabledWorkspaceFeature(params: Pick<IsEnabledFeatureParams, 'feature' | 'workspace'>): boolean {
  const { feature, workspace = {} } = params

  switch (feature) {
    case Feature.PROPOSAL_MANAGEMENT:
      return !!workspace.enableProposalManagement

    // This is related to a specific customer wanting a specific homepage URL
    case Feature.HACK_ALLEGIENT_HOME:
      return workspace.workspaceId === ALLEGIENT_WORKSPACE_ID

    default:
      return false
  }
}

export function isEnabledTestFeature(
  params: Pick<IsEnabledFeatureParams, 'feature' | 'enabledLocalStorage' | 'developmentEnvironment'>
): boolean {
  const {
    feature,
    enabledLocalStorage = isEnabledLocalStorage(feature),
    developmentEnvironment = isDevelopmentEnvironment(),
  } = params

  switch (feature) {
    case Feature.OPPS_PIPELINE_KANBAN_V2:
      return enabledLocalStorage

    case Feature.TIPTAP_INLINE_REWRITE:
      return developmentEnvironment || enabledLocalStorage

    case Feature.ENVIRONMENT_LOGGING:
      return enabledLocalStorage

    case Feature.COMPLIANCE_MATRIX_TAB:
      return enabledLocalStorage

    default:
      return false
  }
}

export function isEnabledPermissionFeature(params: IsEnabledFeatureParams): boolean {
  const { feature } = params

  switch (feature) {
    // For now, just return true. This should have a permission or role check at some point
    case Feature.PAGE_WORKSPACE_ADMIN:
      return true

    default:
      return false
  }
}

function isEnabledFeature(params: IsEnabledFeatureParams): boolean {
  switch (params.feature) {
    case Feature.COMPLIANCE_MATRIX_TAB:
      return isEnabledTestFeature(params)

    case Feature.ENVIRONMENT_LOGGING:
      return isEnabledTestFeature(params)

    case Feature.PROPOSAL_MANAGEMENT:
      return isEnabledWorkspaceFeature(params)

    case Feature.PAGE_WORKSPACE_ADMIN:
      return isEnabledPermissionFeature(params)

    case Feature.HACK_ALLEGIENT_HOME:
      return isEnabledWorkspaceFeature(params)

    case Feature.OPPS_PIPELINE_KANBAN_V2:
      return isEnabledTestFeature(params)

    case Feature.TIPTAP_INLINE_REWRITE:
      return isEnabledTestFeature(params)

    default:
      return false
  }
}

export default isEnabledFeature
