import { useEffect } from 'react'

export function useLockScroll(lock: boolean): void {
  useEffect(() => {
    // This is how bootstrap prevents scrolling with modal open
    if (typeof document !== 'undefined') {
      if (lock) {
        document.body.classList.add('lock-scroll-body')

        return () => {
          document.body.classList.remove('lock-scroll-body')
        }
      } else {
        document.body.classList.remove('lock-scroll-body')
      }
    }
  }, [lock])
}
