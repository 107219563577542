import getConfig from 'next/config'
import { EnvironmentRecord, EnvironmentVariable } from './types'
import { toEnvironmentRecord } from './util'

/**
 * Get the default environment values.
 * These are the critical values that must exist for the app to function correctly.
 *
 * These will be used as a fallback if the actual value is not yet loaded.
 *
 * @returns
 */

export function getDefaultEnvironment(): EnvironmentRecord {
  return {
    [EnvironmentVariable.AZURE_APP_ID]: process.env.NEXT_PUBLIC_DEFAULT_AZURE_APP_ID,
    [EnvironmentVariable.BCC_EMAIL_HOSTNAME]: process.env.NEXT_PUBLIC_DEFAULT_BCC_EMAIL_HOSTNAME,
    [EnvironmentVariable.CUBE_ORIGIN]: process.env.NEXT_PUBLIC_DEFAULT_CUBE_ORIGIN,
    [EnvironmentVariable.GRAPHQL_ORIGIN]: process.env.NEXT_PUBLIC_DEFAULT_GRAPHQL_ORIGIN,
    [EnvironmentVariable.HOCUSPOCUS_ORIGIN]: process.env.NEXT_PUBLIC_DEFAULT_HOCUSPOCUS_ORIGIN,
    [EnvironmentVariable.HOCUSPOCUS_UPDATES_V2_ORIGIN]: process.env.NEXT_PUBLIC_DEFAULT_HOCUSPOCUS_UPDATES_V2_ORIGIN,
    [EnvironmentVariable.HOST]: process.env.NEXT_PUBLIC_DEFAULT_HOST,
    [EnvironmentVariable.WS_ORIGIN]: process.env.NEXT_PUBLIC_DEFAULT_WS_ORIGIN,
  }
}

/**
 * Get the environment values on the server-side.
 *
 * Attempts to load the runtime config, which should always be available on the server-side.
 *
 * @returns
 */
export function getServerSideEnvironment(): EnvironmentRecord {
  // This is deprecated, because NextJS does not recommend runtime config anymore.
  // However, this is what we have available because we're on a very old version of NextJS.
  const { serverRuntimeConfig } = getConfig()
  return toEnvironmentRecord(serverRuntimeConfig)
}

/**
 * Get the environment values on the client-side.
 *
 * Attempts to load the runtime config,
 * but falls back to requesting it from `/api/environment` if that fails.
 *
 * @returns
 */
export function getClientSideEnvironment(): EnvironmentRecord {
  if (typeof window['__NS_ENVIRONMENT'] === 'undefined') {
    console.error('Unable to continue, missing environment...')

    return {}
  }

  return toEnvironmentRecord(window['__NS_ENVIRONMENT'])
}
