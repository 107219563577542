import { isClientSide } from './util'
import { getClientSideEnvironment, getDefaultEnvironment, getServerSideEnvironment } from './getters'
import { Environment, EnvironmentRecord, EnvironmentVariable } from './types'
import { Feature, isEnabledFeature } from '@helpers/feature-flag'

function loadEnvironmentRecord() {
  const values = isClientSide() ? getClientSideEnvironment() : getServerSideEnvironment()
  const record = { ...getDefaultEnvironment(), ...values }

  if (isClientSide() && isEnabledFeature({ feature: Feature.ENVIRONMENT_LOGGING })) {
    console.log('Environment Initialized', { ...record })
  }

  return record
}

/**
 * Makes it easier to interact with the environment variables that are stored in the map.
 */
class EnvironmentSingleton implements Environment {
  // @todo FUTURE: Make these a proper #privateVariable when we target later versions of JS
  _record: EnvironmentRecord

  constructor() {
    this._record = loadEnvironmentRecord()
  }

  getBoolean(key: EnvironmentVariable, options: { defaultValue?: boolean } = {}): boolean {
    const { defaultValue = false } = options

    const value = this._record[key]
    if (!value) return defaultValue

    return value.toLowerCase() === 'true'
  }

  getInteger(key: EnvironmentVariable, options: { defaultValue: number }): number {
    const { defaultValue } = options

    const value = this._record[key]
    if (!value) return defaultValue

    const parsedValue = Number.parseInt(value, 10)
    return Number.isNaN(parsedValue) ? defaultValue : parsedValue
  }

  getString(key: EnvironmentVariable, options: { defaultValue?: string } = {}): string {
    const { defaultValue = '' } = options
    return this._record[key] || defaultValue
  }

  reload(): this {
    this._record = loadEnvironmentRecord()
    return this
  }
}

const environment: EnvironmentSingleton = new EnvironmentSingleton()

/**
 * Get the map of loaded environment variables.
 *
 * @returns
 */
export function getEnvironment(): Environment {
  return environment
}
