import { MouseEventHandler, useCallback, useState } from 'react'
import Select, { MultiValueProps, components } from 'react-select'
import Creatable from 'react-select/creatable'
// Styles
import styles from './Dropdown.module.scss'

import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
  SortableHandle,
  SortStartHandler,
  SortableElementProps,
  SortableContainerProps,
} from 'react-sortable-hoc'

function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = array.slice()
  slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0])
  return slicedArray
}

// https://react-select.com/styles
export const DEFAULT_STYLES = {
  container: (provided) => ({
    ...provided,
    // maxWidth: '18em',
    width: '100%',
    marginRight: '0.5em',
  }),
  singleValue: (provided) => ({
    ...provided,
    marginLeft: 0,
    fontSize: '1em',
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'rgba(10,10,10,.87)',
    backgroundColor: state.isSelected || state.isFocused ? 'rgba(45,156,219,.15)' : null,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '2px',
    display: 'none',
  }),
  multiValue: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '2px',
  }),
  control: (provided) => ({
    ...provided,
    border: '1px solid transparent;',
    minHeight: '32px',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--placeholder-color);',
    fontSize: '1em',
    marginLeft: 0,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: '1000',
    minHeight: 0,
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
  }),
}

export const SHORT_DROPDOWN_STYLES = {
  ...DEFAULT_STYLES,
  container: (provided) => ({ ...provided, width: '10em' }),
}

export const BORDER_DROPDOWN_STYLES = {
  ...DEFAULT_STYLES,
  control: (provided) => ({
    ...provided,
    border: '1px solid hsl(0, 0%, 80%)',
    minHeight: '32px',
  }),
}

export const LEGACY_STYLES = Object.assign({}, DEFAULT_STYLES, {
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '2px',
  }),
  control: (provided) => ({
    ...provided,
    borderWidth: 0,
    minHeight: '38px',
  }),
})

const SortableMultiValue = SortableElement(function SortableElementTemplate<
  T extends {
    id: string
    value: string
    label: string
  },
>(props: MultiValueProps<T> & SortableElementProps) {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const innerProps = { ...props.innerProps, onMouseDown }
  return <components.MultiValue {...props} innerProps={innerProps} />
})

const SortableMultiValueLabel = SortableHandle((props: any) => <components.MultiValueLabel {...props} />)

type SelectTypeProps = ConstructorParameters<typeof Select>['0']
type CreatableTypeProps = ConstructorParameters<typeof Creatable>['0']

const SortableSelect: React.ComponentType<SortableContainerProps & SelectTypeProps> = SortableContainer(Select)
const SortableCreatable: React.ComponentType<SortableContainerProps & CreatableTypeProps> = SortableContainer(Creatable)

interface DropdownV2CommonProps<
  T extends {
    id: string
    value: any
    label: string | React.ReactNode
  },
> {
  options: Array<T>

  inputValue?: string
  // Called when the text changes
  onInputChange?: (value: string, meta: { action: 'input-blur' | 'input-change' | 'menu-close' | string }) => void

  searchable?: boolean
  clearable?: boolean
  creatable?:
    | false
    | {
        creatable: true
        onCreate: (value: string) => void
        createLabel?: (val: string) => React.ReactNode
      }
  filterOptions?: (option: { label: string; value: T['value']; data: T }, raw: string) => boolean
  disabled?: boolean
  closeMenuOnSelect?: boolean
  className?: string
  styles?: any
  placeholder?: string
  noOptionsMessage?: string
  loading?: boolean
  customDropdownIndicator?: any
}

function convertToPlaceholderFriendly<
  T extends {
    id: string
    value: string
    label: string | React.ReactNode
  },
>(val: T | T[] | null) {
  if ((Array.isArray(val) && val.length === 0) || val === null || (!Array.isArray(val) && val.value === '')) {
    return ''
  } else {
    return val
  }
}

export type ExtDropdownV2Props<
  T extends {
    id: string
    value: any
    label: string | React.ReactNode
  },
> = DropdownV2CommonProps<T> &
  (
    | {
        multi: false
        value: {
          id: string
          value: any
          label: string | React.ReactNode
        } | null

        onChange: (selected: T | null) => void
      }
    | {
        multi: true

        value: Array<{
          id: string
          value: any
          label: string | React.ReactNode
        }> | null
        onChange: (selected: T[]) => void
      }
  )

export function ExtDropdownV2<
  T extends {
    id: string
    value: any
    label: string | React.ReactNode
  },
>(props: ExtDropdownV2Props<T>) {
  const {
    multi,
    options,
    value,
    inputValue,
    placeholder = 'Select or search for a value',
    onInputChange,
    filterOptions,
    styles: customStyles,
    className = '',
    searchable = false,
    clearable = false,
    creatable = false,
    disabled = false,
    closeMenuOnSelect = true,
    noOptionsMessage = 'No options',
    loading = false,
    customDropdownIndicator = null,
  } = props

  const [sorting, setSorting] = useState(false)
  const onSortStart: SortStartHandler = useCallback((args) => {
    setSorting(true)
  }, [])

  const onReorder: SortEndHandler = useCallback(
    (args, ev) => {
      const { oldIndex, newIndex } = args

      setSorting(false)

      if (props.multi && props.value) {
        const newOrder = arrayMove(props.value, oldIndex, newIndex)
        props.onChange(newOrder as any)
      }
    },
    [props.value]
  )

  let customComponents
  if (customDropdownIndicator) {
    customComponents = {
      // @ts-ignore We're failing to provide a required index prop to SortableElement!
      MultiValue: SortableMultiValue,
      MultiValueLabel: SortableMultiValueLabel,
      DropdownIndicator: customDropdownIndicator,
    }
  } else {
    customComponents = {
      // @ts-ignore We're failing to provide a required index prop to SortableElement
      MultiValue: SortableMultiValue,
      MultiValueLabel: SortableMultiValueLabel,
    }
  }

  if (creatable !== false) {
    return (
      <SortableCreatable
        isMulti={multi}
        isLoading={loading}
        isDisabled={disabled}
        className={styles['dropdownv2'] + ' ' + className + ` ${sorting ? styles['sorting'] : ''}`}
        classNamePrefix={'dd2'}
        options={options}
        placeholder={placeholder}
        styles={Object.assign({}, DEFAULT_STYLES, customStyles)}
        // This is some crazy weird behavior,
        // This will only show the placehold if value is ''
        filterOption={filterOptions}
        value={convertToPlaceholderFriendly(value) as any}
        inputValue={inputValue}
        getOptionValue={(val) => val.value}
        onChange={(val) => {
          if (props.multi) {
            if (!val) {
              props.onChange([])
            } else if (!Array.isArray(val)) {
              props.onChange([val as T])
            } else {
              props.onChange(val)
            }
          } else if (!Array.isArray(val)) {
            props.onChange((val ?? null) as T | null)
          }
        }}
        onCreateOption={creatable.onCreate}
        isClearable={clearable}
        isSearchable={searchable}
        closeMenuOnSelect={closeMenuOnSelect}
        formatCreateLabel={creatable.createLabel ? creatable.createLabel : (val) => val}
        noOptionsMessage={() => noOptionsMessage}
        onInputChange={onInputChange}
        // DND
        useDragHandle
        // react-sortable-hoc props:
        axis="xy"
        onSortEnd={onReorder}
        onSortStart={onSortStart}
        distance={4}
        // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        components={customComponents}
        helperClass={sorting ? styles['sorting'] : ''}
      />
    )
  } else {
    return (
      <SortableSelect
        isMulti={multi}
        isLoading={loading}
        isDisabled={disabled}
        className={styles['dropdownv2'] + ' ' + className + ` ${sorting ? styles['sorting'] : ''}`}
        helperClass={sorting ? styles['sorting'] : ''}
        classNamePrefix={'dd2'}
        options={options}
        placeholder={placeholder}
        styles={Object.assign({}, DEFAULT_STYLES, customStyles)}
        value={convertToPlaceholderFriendly(value) as any}
        inputValue={inputValue}
        onChange={(val) => {
          if (props.multi) {
            if (!val) {
              props.onChange([])
            } else if (!Array.isArray(val)) {
              props.onChange([val as T])
            } else {
              props.onChange(val)
            }
          } else if (!Array.isArray(val)) {
            props.onChange((val ?? null) as T | null)
          }
        }}
        isClearable={clearable}
        isSearchable={searchable}
        closeMenuOnSelect={closeMenuOnSelect}
        noOptionsMessage={() => noOptionsMessage}
        onInputChange={onInputChange}
        // DND
        useDragHandle
        // react-sortable-hoc props:
        axis="xy"
        onSortEnd={onReorder}
        onSortStart={onSortStart}
        distance={4}
        // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        components={customComponents}
      />
    )
  }
}

type DropdownV2Props<
  T extends {
    id: string
    value: any
    label: string
  },
> = DropdownV2CommonProps<T> & {
  value: {
    id: string
    value: any
    label: string
  } | null

  onChange: (selected: T | null) => void
}

export function DropdownV2<
  T extends {
    id: string
    value: any
    label: string
  },
>(props: DropdownV2Props<T>) {
  return <ExtDropdownV2 {...props} multi={false} />
}
