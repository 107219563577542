export const PUBLIC_ROUTES = [
  'login',
  'signup',
  '',
  'pricing',
  'forgot',
  'reset',
  'privacy',
  'terms',
  'solution',
  'product',
  'sitemap',
  'lp',
  'success',
  'captcha',
  'signout',
]
