import { EnvironmentRecord, EnvironmentVariable } from './types'

/**
 * Determines whether or not we're in a client-side environment
 *
 * @returns
 */
export function isClientSide(): boolean {
  return typeof window !== 'undefined'
}

/**
 * Determines whether or not we're in a server-side environment
 *
 * @returns
 */
export function isServerSide(): boolean {
  return !isClientSide()
}

/**
 * Determines whether or not the `key` is a valid `EnvironmentVariable`.
 *
 * @param key
 * @returns
 */
function isEnvironmentVariable(key: string): key is EnvironmentVariable {
  return Object.values(EnvironmentVariable).includes(key as EnvironmentVariable)
}

/**
 * Ensures that the environment variables only include safe keys.
 *
 * This also normalizes the environment variable record, so we can use it anytime to ensure that as well.
 *
 * @param values
 */
export function toEnvironmentRecord(values: Record<string, string | undefined> | null): EnvironmentRecord {
  const filtered: EnvironmentRecord = {}

  if (!values) return filtered

  Object.entries(values).forEach(([key, value]) => {
    if (isEnvironmentVariable(key)) filtered[key] = value
  })

  return filtered
}
