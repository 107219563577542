// External
import { BookmarkReason } from '@interfaces/graphql'
import { PayloadAction, createReducer, createSlice } from '@reduxjs/toolkit'

export interface MultiSelectSliceSelectedItem {
  id: string
  /** @deprecated this field has no consistent meaning */
  index?: string
  bookmark?: {
    externalId: string
    reason?: BookmarkReason
    createdBy?: {
      fullName: string
    }
    updatedAt?: string
  }
  selected: boolean
}

interface MultiSelectState {
  open: boolean
  items: MultiSelectSliceSelectedItem[]
  clickOptions: ClickOptions[]
}

interface ClickOptions {
  name: string
  onClick: () => void
}

const initState: MultiSelectState = {
  open: false,
  items: [],
  clickOptions: [],
}

const multiSelect = createSlice({
  name: 'drawerReducer',
  initialState: initState,
  reducers: {
    resetMultiSelect: () => initState,

    close: (state) => {
      state.open = false
    },

    registerItemInList(state, action: PayloadAction<MultiSelectSliceSelectedItem>) {
      state.items.push(action.payload)
    },
    unregisterItemInList(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload
      state.items = state.items.filter((item) => item.id !== id)
    },
    syncItem(state, action: PayloadAction<Omit<MultiSelectSliceSelectedItem, 'selected'>>) {
      const { id, bookmark, index } = action.payload
      const item = state.items.find((item) => item.id === id)

      if (item) {
        Object.assign(item, {
          id: id,
          bookmark: bookmark,
          index: index,
        })
      }
    },
    selectAll(state) {
      state.open = true
      state.items.forEach((item) => (item.selected = true))
    },
    deselectAll(state) {
      state.open = false
      state.items.forEach((item) => (item.selected = false))
    },
    selectItem(state, action: PayloadAction<{ id: string; selected: boolean }>) {
      const { id, selected } = action.payload
      const item = state.items.find((item) => item.id === id)
      if (item) item.selected = selected

      state.open = state.items.some((item) => item.selected)
    },
  },
})

export const MultiSelectActions = multiSelect.actions

export default multiSelect.reducer
