import { CIOSP_SAVED_SEARCH_FIELDS, SavedSearch2, SearchTargets } from '@nextweb/common'
import { getReviewStatusDropdownValueFromFilterValue } from '@src/partials/searchv2/saved-search/common-conversions'
import {
  convertCommonDateFilterValueToSavedSearchValue,
  convertSavedSearchValueToCommonDateFilterValue,
} from '@src/partials/searchv2/saved-search/convert-common-date-filter-value-to-saved-search-value'
import _ from 'lodash'
import { CIOSPViewPersisted, CIOSP_VIEW_DEFAULT_STATE } from '../ciosp-view-state'
import {
  CIOSP_VIEW_SORT_BY_POSTED_ON,
  CIOSP_VIEW_SORT_BY_PROPOSAL_DEADLINE,
  CIOSP_VIEW_SORT_BY_SELECTION_DEADLINE,
} from '../components/CIOSPViewSortByDropdown'

/**
 * Convert the CIOSP View Dropdown value into the value expected by the backend.
 * @param dropdownValue
 * @returns
 */
function serializeCIOSPViewSortByValue(dropdownValue: string) {
  switch (dropdownValue) {
    case CIOSP_VIEW_SORT_BY_POSTED_ON:
      return CIOSP_SAVED_SEARCH_FIELDS.sorts.postedOnDate
    case CIOSP_VIEW_SORT_BY_PROPOSAL_DEADLINE:
      return CIOSP_SAVED_SEARCH_FIELDS.sorts.proposalDeadline
    case CIOSP_VIEW_SORT_BY_SELECTION_DEADLINE:
      return CIOSP_SAVED_SEARCH_FIELDS.sorts.selectionDeadline
    default:
      return null
  }
}

function deserializeCIOSPViewSortByValue(value: string) {
  switch (value) {
    case CIOSP_SAVED_SEARCH_FIELDS.sorts.postedOnDate:
      return CIOSP_VIEW_SORT_BY_POSTED_ON
    case CIOSP_SAVED_SEARCH_FIELDS.sorts.proposalDeadline:
      return CIOSP_VIEW_SORT_BY_PROPOSAL_DEADLINE
    case CIOSP_SAVED_SEARCH_FIELDS.sorts.selectionDeadline:
      return CIOSP_VIEW_SORT_BY_SELECTION_DEADLINE
    default:
      return null
  }
}

export function convertCIOSPViewToSavedSearch(view: CIOSPViewPersisted): SavedSearch2.SavedSearch {
  const filters: Array<SavedSearch2.Filter> = []
  const sorts: Array<SavedSearch2.Sort> = []
  const options: Array<SavedSearch2.Option> = []

  if (view.postedOnDate) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.postedOnDate,
      CIOSP_SAVED_SEARCH_FIELDS.filters.postedOnDate
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.selectionDeadlineDate) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.selectionDeadlineDate,
      CIOSP_SAVED_SEARCH_FIELDS.filters.selectionDeadline
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.submissionDeadlineDate) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.submissionDeadlineDate,
      CIOSP_SAVED_SEARCH_FIELDS.filters.submissionDeadline
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.queryString) {
    filters.push({
      type: 'QueryFilter',
      field: 'query',
      display: view.queryString,
      value: view.queryString,
    })
  }

  if (view.reviewStatus) {
    filters.push({
      type: 'MatchAllFilter',
      field: CIOSP_SAVED_SEARCH_FIELDS.filters.reviewStatus,
      display: [view.reviewStatus.label],
      values: view.reviewStatus.value === 'all-opps' ? ['all-opps'] : view.reviewStatus.value,
    })
  }

  if (view.sortBy) {
    const convertToBEUnderstood = serializeCIOSPViewSortByValue(view.sortBy.value)
    if (convertToBEUnderstood) {
      sorts.push({
        type: 'Sort',
        display: '',
        overrideMode: null,
        field: convertToBEUnderstood,
        order: view.sortByDirection === 'asc' ? 'asc' : 'desc',
      })
    }
  }

  if (view.advanced !== null) {
    options.push({
      type: 'FlagOption',
      field: CIOSP_SAVED_SEARCH_FIELDS.options.advanced,
      value: view.advanced,
    })
  }

  return {
    filters: filters,
    sorts: sorts,
    options: options,
    searchTarget: SearchTargets.ciosp.key,
  }
}

export function convertSavedSearchToCIOSPView(savedSearch: SavedSearch2.SavedSearch): CIOSPViewPersisted {
  const view: CIOSPViewPersisted = _.cloneDeep(CIOSP_VIEW_DEFAULT_STATE)

  for (const filter of savedSearch.filters) {
    switch (filter.field) {
      case CIOSP_SAVED_SEARCH_FIELDS.filters.postedOnDate: {
        if (filter.type === 'DateFilter') {
          view.postedOnDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for postedOnDate')
        }
        break
      }
      case CIOSP_SAVED_SEARCH_FIELDS.filters.submissionDeadline: {
        if (filter.type === 'DateFilter') {
          view.submissionDeadlineDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for submissionDeadlineDate')
        }
        break
      }
      case CIOSP_SAVED_SEARCH_FIELDS.filters.selectionDeadline: {
        if (filter.type === 'DateFilter') {
          view.selectionDeadlineDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for selectionDeadline')
        }
        break
      }
      case CIOSP_SAVED_SEARCH_FIELDS.filters.query: {
        if (filter.type === 'QueryFilter') {
          view.queryString = filter.value
        } else {
          console.error('Unexpected filter type for query')
        }
        break
      }
      case CIOSP_SAVED_SEARCH_FIELDS.filters.reviewStatus: {
        if (filter.type === 'MatchAllFilter') {
          view.reviewStatus = getReviewStatusDropdownValueFromFilterValue(filter)
        } else {
          console.error('Unexpected filter type for reviewStatus')
        }
        break
      }

      default:
        console.error('Unexpected filter field: ' + filter.field)
        break
    }
  }

  for (const sort of savedSearch.sorts) {
    if (sort.type === 'Sort') {
      const dropdownValueValue = deserializeCIOSPViewSortByValue(sort.field)
      if (dropdownValueValue) {
        view.sortBy = {
          id: dropdownValueValue,
          value: dropdownValueValue,
          label: sort.display,
        }
        view.sortByDirection = sort.order
      }

      // We only support 1 sort at the moment.
      break
    } else {
      console.error('Unexpected sort type: ' + sort.type)
    }
  }

  for (const option of savedSearch.options) {
    switch (option.field) {
      case CIOSP_SAVED_SEARCH_FIELDS.options.advanced: {
        if (option.type === 'FlagOption') {
          view.advanced = option.value
        } else {
          console.error('Unexpected option type for advanced')
        }
        break
      }
      default:
        console.error('Unexpected option field: ' + option.field)
        break
    }
  }

  return view
}
