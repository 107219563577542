import { BookmarkReason } from '@interfaces/graphql'
import { SavedSearch2, SearchTargets, convertQueryParamsToSavedSearch } from '@nextweb/common'
import { DateFilterValue } from '../../components/CommonDateFilter/CommonDateFilter'
import { firstString } from '../../redux/utils'
import { convertSavedSearchToEBuyView } from './saved-search/convert-ebuy-view-and-saved-search'
import { SEARCH_VIEW_QUERY_PARAM_KEY } from '../../url-management/search-view-query-param-key'
import { getSavedSearchFromQueryParams } from '../../redux/saved-search-query-params'

/**
 * These don't have to be the same but it is convenient if they are.
 */
export const EBUY_VIEW_KEY = SearchTargets.ebuy.label

export function isEBuyViewKey(s?: any): boolean {
  s = Array.isArray(s) ? firstString(s) : s
  return typeof s === 'string' && s.toUpperCase() === EBUY_VIEW_KEY.toUpperCase()
}

// All serializable/saveable state should go into this.
export interface EBuyViewPersisted {
  // Some common stuff, not sure how to colocate this stuff yet.
  // SavedSearch dropdown is not itself persisted.
  // The SavedSearch dropdown is still populated by query, etc.
  // savedSearch: { id: string; value: string; label: string } | null
  advanced: boolean | null
  reviewStatus: {
    id: string
    value: Array<BookmarkReason> | 'all-opps'
    label: string
  } | null
  sortBy: { id: string; value: string; label: string } | null
  sortByDirection: 'asc' | 'desc' | null

  // "The" query string.
  queryString: string

  // EBuy Specific stuff
  sins: Array<{ id: string; value: string; label: string }> | null
  agency: Array<{ id: string; value: string; label: string }> | null
  subAgency: Array<{ id: string; value: string; label: string }> | null

  postedOnDate: DateFilterValue | null
  respondByDate: DateFilterValue | null
}

export interface EBuyViewState {
  type: typeof EBUY_VIEW_KEY

  // All serializable/saveable state should go into this.
  persisted: EBuyViewPersisted

  savedSearch: { id: string; value: string; label: string } | null

  selectedListing: any
  pageFrom: number
  hitCount: number | null
}

export const EBUY_VIEW_DEFAULT_STATE: EBuyViewPersisted = {
  advanced: true,
  reviewStatus: null,
  sortBy: null,
  sortByDirection: null,

  // "The" query string.
  queryString: '',

  // EBuy Specific stuff
  sins: null,
  agency: null,
  subAgency: null,

  postedOnDate: null,
  respondByDate: null,
}

export const EBUY_VIEW_EMPTY_STATE: EBuyViewPersisted = {
  advanced: true,
  reviewStatus: null,
  sortBy: null,
  sortByDirection: null,

  // "The" query string.
  queryString: '',

  // EBuy Specific stuff
  sins: null,
  agency: null,
  subAgency: null,

  postedOnDate: null,
  respondByDate: null,
}

/**
 * This may return null if the query fields are really malformed.
 * @param query
 */
export function createEBuyViewFromQuery(query: Record<string, string | string[]>): EBuyViewState | null {
  // This should be checked before calling this function ideally.
  if (!isEBuyViewKey(query[SEARCH_VIEW_QUERY_PARAM_KEY])) return null

  const savedSearch = convertQueryParamsToSavedSearch({
    params: query,
  })

  return {
    type: EBUY_VIEW_KEY,
    selectedListing: null,
    pageFrom: 0,
    hitCount: null,
    savedSearch: getSavedSearchFromQueryParams({ params: query }),
    persisted:
      savedSearch && !SavedSearch2.isEmptySavedSearch(savedSearch)
        ? convertSavedSearchToEBuyView(savedSearch)
        : EBUY_VIEW_DEFAULT_STATE,
  }
}
