import { BookmarkReason, SavedSearch2 } from '@nextweb/common'
import { firstString } from '../redux/utils'

export function convertCommonDropdownValueToSavedSearchAtLeastOneOfFilter(
  arr: { id: string; value: string; label: string },
  field: string
): SavedSearch2.MatchFilter {
  return {
    type: 'MatchFilter',
    field: field,
    display: arr.label,
    value: arr.value,
  }
}

export function convertCommonMultiDropdownValueToSavedSearchAtLeastOneOfFilter(
  arr: Array<{ id: string; value: string; label: string }>,
  field: string
): SavedSearch2.AtLeastOneOfFilter {
  return {
    type: 'AtLeastOneOfFilter',
    field: field,
    display: arr.map((a) => a.label),
    values: arr.map((a) => a.value),
  }
}

type KeysMatching<T, V> = { [K in keyof T]-?: T[K] extends V ? K : never }[keyof T]

type ViewArrayFields<T> = KeysMatching<T, Array<{ id: string; value: string; label: string }> | null>

type ViewSingleFields<T> = KeysMatching<T, { id: string; value: string; label: string } | null>

/**
 * Assumes that the values returned from the dropdowns are understood by the BE.
 * @param filters
 * @param view
 * @param key
 * @param field
 */
export function serializeCommonMultiDropdownViewProperty<T extends Record<string, any>>(
  filters: Array<SavedSearch2.Filter>,
  view: T,
  key: ViewArrayFields<T>,
  field: string
) {
  const values = view[key]
  if (values && values.length > 0) {
    filters.push(convertCommonMultiDropdownValueToSavedSearchAtLeastOneOfFilter(values, field))
  }
}

export function serializeCommonDropdownViewProperty<T extends Record<string, any>>(
  filters: Array<SavedSearch2.Filter>,
  view: T,
  key: ViewSingleFields<T>,
  field: string
) {
  const value = view[key]
  if (value) {
    filters.push(convertCommonDropdownValueToSavedSearchAtLeastOneOfFilter(value, field))
  }
}

function isBookmarkReasonArray(arr: any): arr is Array<BookmarkReason> {
  return arr.every((a: any) => Object.values(BookmarkReason).includes(a))
}

export function getReviewStatusDropdownValueFromFilterValue(filter: SavedSearch2.MatchAllFilter): {
  id: string
  value: Array<BookmarkReason> | 'all-opps'
  label: string
} | null {
  if ((filter.values.length === 1 && filter.values[0] === 'all-opps') || !isBookmarkReasonArray(filter.values)) {
    return {
      id: 'all-opps',
      value: 'all-opps',
      label: 'All Opportunities',
    }
  }

  return {
    id: 'doesnt-matter',
    value: filter.values,
    label: firstString(filter.display),
  }
}

export function getDropdownValuesFromFilterValues(
  filter: SavedSearch2.AtLeastOneOfFilter
): Array<{ id: string; value: string; label: string }> {
  return filter.values.map((v, index) => {
    return {
      id: v.toString(),
      value: v.toString(),
      label: filter.display[index] ?? v.toString(),
    }
  })
}

export function getDropdownValueFromFilterValue(filter: SavedSearch2.MatchFilter): {
  id: string
  value: string
  label: string
} {
  const v = filter.value
  return {
    id: v.toString(),
    value: v.toString(),
    label: filter.display ?? v.toString(),
  }
}
