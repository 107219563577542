import { BookmarkReason } from '@interfaces/graphql'
import { SavedSearch2, SearchTargets, convertQueryParamsToSavedSearch } from '@nextweb/common'
import { DateFilterValue } from '../../components/CommonDateFilter/CommonDateFilter'
import { getSavedSearchFromQueryParams } from '../../redux/saved-search-query-params'
import { firstString } from '../../redux/utils'
import { SEARCH_VIEW_QUERY_PARAM_KEY } from '../../url-management/search-view-query-param-key'
import { convertSavedSearchToAlexandriaView } from './saved-search/convert-alexandria-view-and-saved-search'
import { ITESSpecializedFields, SEWPSpecializedFields, SeaPortSpecializedFields } from './source-specialized-fields'

export const ALEXANDRIA_VIEW_KEY = SearchTargets.alexandria.label

export function isAlexandriaViewKey(s?: any): boolean {
  s = Array.isArray(s) ? firstString(s) : s
  return typeof s === 'string' && s.toUpperCase() === ALEXANDRIA_VIEW_KEY.toUpperCase()
}

// All serializable/saveable state should go into this.
// Some common stuff, not sure how to colocate this stuff yet.
export interface AlexandriaViewPersisted {
  // SavedSearch dropdown is not itself persisted.
  // The SavedSearch dropdown is still populated by query, etc.
  // savedSearch: { id: string; value: string; label: string } | null
  advanced: boolean | null
  reviewStatus: {
    id: string
    value: Array<BookmarkReason> | 'all-opps'
    label: string
  } | null
  sortBy: { id: string; value: string; label: string } | null
  sortByDirection: 'asc' | 'desc' | null
  source: string | null

  queryString: string
  receivedDate: DateFilterValue | null
  lastModifiedDate: DateFilterValue | null

  /**
   * Attention! If a particular alexandria source begins to deviate too much from
   * this structure, consider creating a new view type!
   */
  specialization: SeaPortSpecializedFields | SEWPSpecializedFields | ITESSpecializedFields | null
}

export interface AlexandriaViewState {
  type: typeof ALEXANDRIA_VIEW_KEY

  // All serializable/saveable state should go into this.
  persisted: AlexandriaViewPersisted

  savedSearch: { id: string; value: string; label: string } | null

  selectedListing: any
  pageFrom: number
  hitCount: number | null
}

/**
 * Attention! If a particular alexandria source begins to deviate too much from
 * this structure, consider creating a new view type!
 */
export const ALEXANDRIA_VIEW_DEFAULT_STATE: AlexandriaViewPersisted = {
  advanced: true,
  reviewStatus: null,
  sortBy: null,
  sortByDirection: null,

  // "The" query string.
  queryString: '',
  source: null,

  specialization: null,
  receivedDate: null,
  lastModifiedDate: null,
}

export const ALEXANDRIA_VIEW_EMPTY_STATE: AlexandriaViewPersisted = {
  advanced: null,
  reviewStatus: null,
  sortBy: null,
  sortByDirection: null,

  // "The" query string.
  queryString: '',
  source: null,

  specialization: null,
  receivedDate: null,
  lastModifiedDate: null,
}

/**
 * This may return null if the query fields are really malformed.
 * @param query
 */
export function createAlexandriaViewFromQuery(query: Record<string, string | string[]>): AlexandriaViewState | null {
  // This should be checked before calling this function ideally.
  if (!isAlexandriaViewKey(query[SEARCH_VIEW_QUERY_PARAM_KEY])) return null

  const savedSearch = convertQueryParamsToSavedSearch({
    params: query,
  })

  return {
    type: ALEXANDRIA_VIEW_KEY,
    selectedListing: null,
    pageFrom: 0,
    hitCount: null,
    savedSearch: getSavedSearchFromQueryParams({ params: query }),
    persisted:
      savedSearch && !SavedSearch2.isEmptySavedSearch(savedSearch)
        ? convertSavedSearchToAlexandriaView(savedSearch)
        : ALEXANDRIA_VIEW_DEFAULT_STATE,
  }
}
