export type MarkdownNormalizerOptions = { streaming?: boolean }
export type MarkdownNormalizer = (data: string, options?: MarkdownNormalizerOptions) => string

function getCommonIndent(lines: string[]): number {
  const truthy = lines.filter(Boolean)
  return Math.min(
    ...truthy.map((line) => {
      const match = line.match(/^\s+/)
      return match ? match[0].length : 0
    })
  )
}

/**
 * Normalizes indentation within markdown text.
 *
 * @param data
 * @returns
 */
export const normalizeMarkdownIndent: MarkdownNormalizer = (data: string) => {
  const lines = data.split('\n').map((line) => line.trimEnd())
  const commonIndent = getCommonIndent(lines)
  return lines.map((line) => (line ? line.slice(commonIndent) : line)).join('\n')
}

/**
 * Removes unneeded blank lines from markdown text.
 * Ensures the content ends with a blank line.
 *
 * @param data
 * @returns
 */
export const normalizeMarkdownBlankLines: MarkdownNormalizer = (data: string) => {
  const result = data
    .split('\n')
    // IDK why I had to do it this way instead of with a regex, but I don't care and it passes tests
    .filter((line, index, lines) => {
      const trimmed = line.trim()
      if (trimmed) return true

      // remove initial blank line
      if (index === 0) return false

      // remove double+ blank lines
      if (lines[index - 1] === '') return false

      return true
    })
    .join('\n')

  if (!result) return ''

  if (!result.endsWith('\n')) return `${result}\n`
  else return result
}

/**
 * Normalizes markdown by standardizing indentation and removing excess lines.
 *
 * @param data
 * @returns
 */
export const normalizeMarkdown: MarkdownNormalizer = (data: string) => {
  return normalizeMarkdownBlankLines(normalizeMarkdownIndent(data))
}

function isMarkdownOutputBlockOpen(params: { line: string; withinOutputBlock: boolean }): boolean {
  const { line, withinOutputBlock } = params

  if (line === '```markdown' || line === '```md') return true
  return !withinOutputBlock && line === '```'
}

function isMarkdownOutputBlockClose(params: { line: string; withinOutputBlock: boolean }): boolean {
  const { line, withinOutputBlock } = params
  return withinOutputBlock && line === '```'
}

/**
 * Normalizes markdown that comes from an AI response.
 * This removes the ```markdown code block and puts the AI explanations within a blockquote.
 *
 * @param data
 * @returns
 */
export const normalizeMarkdownFromAI: MarkdownNormalizer = (data: string) => {
  if (!data.includes('```')) return normalizeMarkdown(data)

  const lines = data.split('\n')
  let withinOutputBlock = false
  let withinQuoteBlock = false

  const transformed = lines
    .map((line) => {
      const trimmed = line.trim()

      if (isMarkdownOutputBlockOpen({ line: trimmed, withinOutputBlock })) {
        withinOutputBlock = true
        withinQuoteBlock = false
        return ''
      }

      if (isMarkdownOutputBlockClose({ line: trimmed, withinOutputBlock })) {
        withinOutputBlock = false
        return ''
      }

      if (withinOutputBlock) return line

      if (!trimmed && !withinQuoteBlock) return line

      withinQuoteBlock = true
      return `> ${line}`
    })
    .join('\n')

  return normalizeMarkdown(transformed)
    .replace(/\n(\>\n)+/g, '\n>\n')
    .replace(/\n\>\n\n/g, '\n\n')
    .replace(/\n\>\n/g, '\n> \n')
}

/**
 * Normalizes markdown that will be inserted into the editor.
 *
 * @param data
 * @returns
 */
export const normalizeMarkdownForInsert: MarkdownNormalizer = (data: string) => {
  const normalized = normalizeMarkdownFromAI(data)

  const transformed = normalized
    .split('\n')
    .filter((line) => !line.startsWith('> ') && line.trim() !== '>')
    .join('\n')

  return normalizeMarkdownBlankLines(transformed)
}
