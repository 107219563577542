// External
import { Permission } from '@interfaces/graphql'
import { createSlice } from '@reduxjs/toolkit'

const permissionsSlice = createSlice({
  name: 'permissionsReducer',
  initialState: [] as Pick<Permission, 'id' | 'scope' | 'crudType'>[],
  reducers: {
    setPermissions: (state, action) => action.payload,
  },
})

export const { setPermissions } = permissionsSlice.actions

export default permissionsSlice.reducer
