import { SavedSearch2, SearchTargets, convertQueryParamsToSavedSearch } from '@nextweb/common'
import { getSavedSearchFromQueryParams } from '../../redux/saved-search-query-params'
import { firstString } from '../../redux/utils'
import { SEARCH_VIEW_QUERY_PARAM_KEY } from '../../url-management/search-view-query-param-key'
import { convertSavedSearchToVendorView } from './saved-search/convert-vendor-view-and-saved-search'

/**
 * These don't have to be the same but it is convenient if they are.
 */
export const VENDOR_VIEW_KEY = SearchTargets.vendors.label

export function isVendorViewKey(s?: any): boolean {
  s = Array.isArray(s) ? firstString(s) : s
  return typeof s === 'string' && s.toUpperCase() === VENDOR_VIEW_KEY.toUpperCase()
}

// All serializable/saveable state should go into this.
export interface VendorViewPersisted {
  // Some common stuff, not sure how to colocate this stuff yet.
  // SavedSearch dropdown is not itself persisted.
  // The SavedSearch dropdown is still populated by query, etc.
  // savedSearch: { id: string; value: string; label: string } | null
  advanced: boolean | null

  // "The" query string.
  queryString: string
}

export interface VendorViewState {
  type: typeof VENDOR_VIEW_KEY

  // All serializable/saveable state should go into this.
  persisted: VendorViewPersisted

  savedSearch: { id: string; value: string; label: string } | null

  selectedListing: any
  pageFrom: number
  hitCount: number | null
}

export const VENDOR_VIEW_DEFAULT_STATE: VendorViewPersisted = {
  advanced: true,

  // "The" query string.
  queryString: '',
}

export const VENDOR_VIEW_EMPTY_STATE: VendorViewPersisted = {
  advanced: null,

  // "The" query string.
  queryString: '',
}

/**
 * This may return null if the query fields are really malformed.
 * @param query
 */
export function createVendorViewFromQuery(query: Record<string, string | string[]>): VendorViewState | null {
  // This should be checked before calling this function ideally.
  if (!isVendorViewKey(query[SEARCH_VIEW_QUERY_PARAM_KEY])) return null

  const savedSearch = convertQueryParamsToSavedSearch({
    params: query,
  })

  return {
    type: VENDOR_VIEW_KEY,
    selectedListing: null,
    pageFrom: 0,
    hitCount: null,
    savedSearch: getSavedSearchFromQueryParams({ params: query }),
    persisted:
      savedSearch && !SavedSearch2.isEmptySavedSearch(savedSearch)
        ? convertSavedSearchToVendorView(savedSearch)
        : VENDOR_VIEW_DEFAULT_STATE,
  }
}
