import { APFS_SAVED_SEARCH_FIELDS, SavedSearch2, SearchTargets } from '@nextweb/common'
import {
  getDropdownValuesFromFilterValues,
  getReviewStatusDropdownValueFromFilterValue,
  serializeCommonMultiDropdownViewProperty,
} from '@src/partials/searchv2/saved-search/common-conversions'
import {
  convertCommonDateFilterValueToSavedSearchValue,
  convertSavedSearchValueToCommonDateFilterValue,
} from '@src/partials/searchv2/saved-search/convert-common-date-filter-value-to-saved-search-value'
import _ from 'lodash'
import { APFSViewPersisted, APFS_VIEW_EMPTY_STATE } from '../apfs-view-state'
import {
  APFS_VIEW_SORT_BY_APFS_PUBLISH_DATE,
  APFS_VIEW_SORT_BY_RFP_RELEASE_DATE,
} from '../components/APFSViewSortByDropdown'

/**
 * Convert the SAM View Dropdown value into the value expected by the backend.
 * @param dropdownValue
 * @returns
 */
function serializeAPFSViewSortByValue(dropdownValue: string) {
  switch (dropdownValue) {
    case APFS_VIEW_SORT_BY_RFP_RELEASE_DATE:
      return APFS_SAVED_SEARCH_FIELDS.sorts.rfpReleaseDate
    case APFS_VIEW_SORT_BY_APFS_PUBLISH_DATE:
      return APFS_SAVED_SEARCH_FIELDS.sorts.apfsPublishDate
    default:
      return null
  }
}

function deserializeAPFSViewSortByValue(dropdownValue: string) {
  switch (dropdownValue) {
    case APFS_SAVED_SEARCH_FIELDS.sorts.rfpReleaseDate:
      return APFS_VIEW_SORT_BY_RFP_RELEASE_DATE
    case APFS_SAVED_SEARCH_FIELDS.sorts.apfsPublishDate:
      return APFS_VIEW_SORT_BY_APFS_PUBLISH_DATE
    default:
      return null
  }
}

export function convertAPFSViewToSavedSearch(view: APFSViewPersisted): SavedSearch2.SavedSearch {
  const filters: Array<SavedSearch2.Filter> = []
  const sorts: Array<SavedSearch2.Sort> = []
  const options: Array<SavedSearch2.Option> = []

  // Note! The key in the state happens to coincide with the "Field" value,
  // but make no mistake, they are not the same, you can store the value
  // in any field you want on the client side, but the "Field" value must match
  // a SAM field on the BE.
  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'placeOfPerformance',
    APFS_SAVED_SEARCH_FIELDS.filters.placeOfPerformance
  )
  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'naics', //
    APFS_SAVED_SEARCH_FIELDS.filters.naics
  )

  if (view.apfsPublishDate) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.apfsPublishDate,
      APFS_SAVED_SEARCH_FIELDS.filters.apfsPublishDate
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.rfpReleaseDate) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.rfpReleaseDate,
      APFS_SAVED_SEARCH_FIELDS.filters.rfpReleaseDate
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.periodOfPerformanceEnd) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.periodOfPerformanceEnd,
      APFS_SAVED_SEARCH_FIELDS.filters.periodOfPerformanceEnd
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.periodOfPerformanceStart) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.periodOfPerformanceStart,
      APFS_SAVED_SEARCH_FIELDS.filters.periodOfPerformanceStart
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.queryString) {
    filters.push({
      type: 'QueryFilter',
      field: 'query',
      display: view.queryString,
      value: view.queryString,
    })
  }

  if (view.reviewStatus) {
    filters.push({
      type: 'MatchAllFilter',
      field: APFS_SAVED_SEARCH_FIELDS.filters.reviewStatus,
      display: [view.reviewStatus.label],
      values: view.reviewStatus.value === 'all-opps' ? ['all-opps'] : view.reviewStatus.value,
    })
  }

  if (view.sortBy) {
    const convertToBEUnderstood = serializeAPFSViewSortByValue(view.sortBy.value)
    if (convertToBEUnderstood) {
      sorts.push({
        type: 'Sort',
        display: '',
        overrideMode: null,
        field: convertToBEUnderstood,
        order: view.sortByDirection === 'asc' ? 'asc' : 'desc',
      })
    }
  }

  if (view.advanced !== null) {
    options.push({
      type: 'FlagOption',
      field: APFS_SAVED_SEARCH_FIELDS.options.advanced,
      value: view.advanced,
    })
  }

  return {
    filters: filters,
    sorts: sorts,
    options: options,
    searchTarget: SearchTargets.apfs.key,
  }
}

export function convertSavedSearchToAPFSView(savedSearch: SavedSearch2.SavedSearch): APFSViewPersisted {
  const view: APFSViewPersisted = _.cloneDeep(APFS_VIEW_EMPTY_STATE)

  for (const filter of savedSearch.filters) {
    switch (filter.field) {
      case APFS_SAVED_SEARCH_FIELDS.filters.query: {
        if (filter.type === 'QueryFilter') {
          view.queryString = filter.value
        } else {
          console.error('Unexpected filter type for query')
        }
        break
      }
      case APFS_SAVED_SEARCH_FIELDS.filters.reviewStatus: {
        if (filter.type === 'MatchAllFilter') {
          view.reviewStatus = getReviewStatusDropdownValueFromFilterValue(filter)
        } else {
          console.error('Unexpected filter type for reviewStatus')
        }
        break
      }
      case APFS_SAVED_SEARCH_FIELDS.filters.naics: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.naics = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for naics')
        }
        break
      }
      case APFS_SAVED_SEARCH_FIELDS.filters.placeOfPerformance: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.placeOfPerformance = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for placeOfPerformance')
        }
        break
      }
      case APFS_SAVED_SEARCH_FIELDS.filters.apfsPublishDate: {
        if (filter.type === 'DateFilter') {
          view.apfsPublishDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for apfsPublishDate')
        }
        break
      }
      case APFS_SAVED_SEARCH_FIELDS.filters.rfpReleaseDate: {
        if (filter.type === 'DateFilter') {
          view.rfpReleaseDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for rfpReleaseDate')
        }
        break
      }
      case APFS_SAVED_SEARCH_FIELDS.filters.periodOfPerformanceEnd: {
        if (filter.type === 'DateFilter') {
          view.periodOfPerformanceEnd = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for periodOfPerformanceEnd')
        }
        break
      }
      case APFS_SAVED_SEARCH_FIELDS.filters.periodOfPerformanceStart: {
        if (filter.type === 'DateFilter') {
          view.periodOfPerformanceStart = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for periodOfPerformanceStart')
        }
        break
      }
      default:
        console.error('Unexpected filter field: ' + filter.field)
        break
    }
  }

  for (const sort of savedSearch.sorts) {
    if (sort.type === 'Sort') {
      const dropdownValueValue = deserializeAPFSViewSortByValue(sort.field)
      if (dropdownValueValue) {
        view.sortBy = {
          id: dropdownValueValue,
          value: dropdownValueValue,
          label: sort.display,
        }
        view.sortByDirection = sort.order
      }

      // We only support 1 sort at the moment.
      break
    } else {
      console.error('Unexpected sort type: ' + sort.type)
    }
  }

  for (const option of savedSearch.options) {
    switch (option.field) {
      case APFS_SAVED_SEARCH_FIELDS.options.advanced: {
        if (option.type === 'FlagOption') {
          view.advanced = option.value
        } else {
          console.error('Unexpected option type for advanced')
        }
        break
      }
      default:
        console.error('Unexpected option field: ' + option.field)
        break
    }
  }

  return view
}
