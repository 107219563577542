import Link from 'next/link'
import styles from './Logo.module.scss'
import Image from '@components/image/Image'
// import { Image as NextImage } from 'next/image'

interface LogoProps {
  customStyles?: any
  noLink?: boolean
  className?: string
}

const Logo = ({ customStyles, noLink, className = '' }: LogoProps) => {
  if (noLink) {
    return (
      <a className={styles.logo + ` ${className}`} style={customStyles}>
        NextStage
      </a>
    )
  }
  return (
    <Link href="/">
      <img
        className={className}
        alt="NextStage Logo"
        src={'/images/logo/wordmark_color_sm.webp'}
        style={{ cursor: 'pointer' }}
      />
    </Link>
  )
}

export default Logo

interface NLogoProps {
  src?: string
  width?: number
  height?: number
}

export const NLogo = ({ src, width = 20, height = 25 }: NLogoProps) => {
  return (
    <Link href="/">
      <a>
        <Image
          alt="NextStage Logo"
          src={src || '/images/logo/emblem_color_sm.webp'}
          width={width}
          height={height}
          customStyles={{ cursor: 'pointer' }}
        />
      </a>
    </Link>
  )
}

export const NextStageWordLogo = ({ src, width = 20, height = 25 }: NLogoProps) => {
  return (
    <Link href="/">
      <a>
        <Image
          alt="NextStage Logo"
          src={src || '/images/logo/wordmark_color_sm.webp'}
          width={width}
          height={height}
          customStyles={{ cursor: 'pointer' }}
        />
      </a>
    </Link>
  )
}
// export const PrimaryLogo = (src) => {
//   return <NextImage className={styles.primaryLogo} src={src} width={200} height={180} />
// }
