import { TextProps, Text } from '@mantine/core'
import * as React from 'react'
import { Flex, FlexProps } from '@components/flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export interface LoaderProps extends FlexProps {
  /**
   * @default 'Loading...'
   */
  message?: string

  /**
   * Hide the message inside the loader
   * @default false
   */
  hideMessage?: boolean

  icon?: React.ReactNode

  size?: TextProps['size']

  color?: TextProps['color']

  textAlign?: TextProps['align']
}

const Loader: React.FC<LoaderProps> = (props) => {
  const {
    message = 'Loading...',
    hideMessage = false,
    size = 'xl',
    color = 'dark',
    textAlign,
    // Keeping things the same as before.
    // We'll want to pick a new loader icon later on, since we are moving away from FontAwesome.
    icon = <FontAwesomeIcon spin icon={faSpinner} />,
    ...forwarded
  } = props

  return (
    <Flex align="flex-start" {...forwarded}>
      <Text size={size} color={color} align={textAlign}>
        {icon}
        {hideMessage ? null : <span style={{ marginLeft: '0.5em' }}>{message}</span>}
      </Text>
    </Flex>
  )
}

export default Loader
