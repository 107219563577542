import { classNames } from '@helpers/scls'
import styles from './Page.module.scss'
import { Breakpoint } from '@helpers/hooks/useBreakpoint'

export interface PageContentProps extends React.HTMLAttributes<HTMLElement> {
  bright?: boolean

  p?: 'none' | 'sm' | 'md' | 'lg'
  px?: 'none' | 'sm' | 'md' | 'lg'
  py?: 'none' | 'sm' | 'md' | 'lg'

  disableOverflowX?: boolean
  disableOverflowY?: boolean
  disableOverflow?: boolean

  /** Avoids having its own scrolling. Useful if you want the header to be part of the scrolling. */
  disableContentScrolling?: boolean

  maxWidth?: Breakpoint
}

const PageContent: React.FC<PageContentProps> = (props: PageContentProps) => {
  const {
    className: propsClassName,
    bright,
    disableOverflow,
    disableOverflowX = disableOverflow,
    disableOverflowY = disableOverflow,
    p = 'md',
    px = p,
    py = p,
    disableContentScrolling,
    maxWidth,
    children,
    ...forwarded
  } = props

  const hasChildWrapper = !!maxWidth

  const className = classNames(
    styles.content,
    {
      [styles.contentWithChildWrapper]: hasChildWrapper,
      [styles.bright]: bright,
      [styles.contentScroll]: !disableContentScrolling,
      [styles.pxNone]: px === 'none',
      [styles.pxSm]: px === 'sm',
      [styles.pxMd]: px === 'md',
      [styles.pxLg]: px === 'lg',
      [styles.pyNone]: py === 'none',
      [styles.pySm]: py === 'sm',
      [styles.pyMd]: py === 'md',
      [styles.pyLg]: py === 'lg',
      [styles.disableOverflowX]: disableOverflowX,
      [styles.disableOverflowY]: disableOverflowY,
    },
    propsClassName
  )

  if (!hasChildWrapper) {
    return (
      <div className={className} {...forwarded}>
        {children}
      </div>
    )
  }

  const childWrapperClassName = classNames(styles.contentChildWrapper, {
    [styles.maxWidthTiny]: maxWidth === Breakpoint.TINY,
    [styles.maxWidthMobile]: maxWidth === Breakpoint.MOBILE,
    [styles.maxWidthTablet]: maxWidth === Breakpoint.TABLET,
    [styles.maxWidthLaptopSm]: maxWidth === Breakpoint.LAPTOP_SM,
    [styles.maxWidthDesktopSm]: maxWidth === Breakpoint.DESKTOP_SM,
    [styles.maxWidthDesktopMd]: maxWidth === Breakpoint.DESKTOP_MD,
  })

  return (
    <div className={className} {...forwarded}>
      <div className={childWrapperClassName}>{children}</div>
    </div>
  )
}

export default PageContent
