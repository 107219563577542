import { ALEXANDRIA_SAVED_SEARCH_FIELDS, SavedSearch2, SearchTargets } from '@nextweb/common'
import { DateFilterValue } from '@src/partials/searchv2/components/CommonDateFilter/CommonDateFilter'
import { getReviewStatusDropdownValueFromFilterValue } from '@src/partials/searchv2/saved-search/common-conversions'
import {
  convertCommonDateFilterValueToSavedSearchValue,
  convertSavedSearchValueToCommonDateFilterValue,
} from '@src/partials/searchv2/saved-search/convert-common-date-filter-value-to-saved-search-value'
import _ from 'lodash'
import { ALEXANDRIA_VIEW_EMPTY_STATE, AlexandriaViewPersisted } from '../alexandria-view-state'
import {
  ALEXANDRIA_VIEW_SORT_BY_LAST_MODIFIED,
  ALEXANDRIA_VIEW_SORT_BY_POSTED_ON,
  ALEXANDRIA_VIEW_SORT_BY_QA_CUTOFF,
  ALEXANDRIA_VIEW_SORT_BY_RECEIVED_DATE,
  ALEXANDRIA_VIEW_SORT_BY_RESPOND_BY,
} from '../components/AlexandriaViewSortByDropdown'

/**
 * Convert the SAM View Dropdown value into the value expected by the backend.
 * @param dropdownValue
 * @returns
 */
function serializeAlexandriaViewSortByValue(dropdownValue: string) {
  switch (dropdownValue) {
    case ALEXANDRIA_VIEW_SORT_BY_RECEIVED_DATE:
      return ALEXANDRIA_SAVED_SEARCH_FIELDS.sorts.receivedDate
    case ALEXANDRIA_VIEW_SORT_BY_LAST_MODIFIED:
      return ALEXANDRIA_SAVED_SEARCH_FIELDS.sorts.lastModified
    case ALEXANDRIA_VIEW_SORT_BY_POSTED_ON:
      return ALEXANDRIA_SAVED_SEARCH_FIELDS.sorts.postedOnDate
    case ALEXANDRIA_VIEW_SORT_BY_QA_CUTOFF:
      return ALEXANDRIA_SAVED_SEARCH_FIELDS.sorts.qaCutoffDate
    case ALEXANDRIA_VIEW_SORT_BY_RESPOND_BY:
      return ALEXANDRIA_SAVED_SEARCH_FIELDS.sorts.respondByDate
    default:
      return null
  }
}

function deserializeAlexandriaViewSortByValue(dropdownValue: string) {
  switch (dropdownValue) {
    case ALEXANDRIA_SAVED_SEARCH_FIELDS.sorts.receivedDate:
      return ALEXANDRIA_VIEW_SORT_BY_RECEIVED_DATE
    case ALEXANDRIA_SAVED_SEARCH_FIELDS.sorts.lastModified:
      return ALEXANDRIA_VIEW_SORT_BY_LAST_MODIFIED
    case ALEXANDRIA_SAVED_SEARCH_FIELDS.sorts.postedOnDate:
      return ALEXANDRIA_VIEW_SORT_BY_POSTED_ON
    case ALEXANDRIA_SAVED_SEARCH_FIELDS.sorts.respondByDate:
      return ALEXANDRIA_VIEW_SORT_BY_RESPOND_BY
    case ALEXANDRIA_SAVED_SEARCH_FIELDS.sorts.qaCutoffDate:
      return ALEXANDRIA_VIEW_SORT_BY_QA_CUTOFF
    default:
      return null
  }
}

export function convertAlexandriaViewToSavedSearch(view: AlexandriaViewPersisted): SavedSearch2.SavedSearch {
  const filters: Array<SavedSearch2.Filter> = []
  const sorts: Array<SavedSearch2.Sort> = []
  const options: Array<SavedSearch2.Option> = []

  // Note! The key in the state happens to coincide with the "Field" value,
  // but make no mistake, they are not the same, you can store the value
  // in any field you want on the client side, but the "Field" value must match
  // a SAM field on the BE.

  if (view.lastModifiedDate) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.lastModifiedDate,
      ALEXANDRIA_SAVED_SEARCH_FIELDS.filters.lastModified
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.receivedDate) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.receivedDate,
      ALEXANDRIA_SAVED_SEARCH_FIELDS.filters.receivedDate
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.specialization) {
    if (view.specialization.type === 'ITESSpecializedFields') {
      if (view.specialization.postedOnDate) {
        const filterValue = convertCommonDateFilterValueToSavedSearchValue(
          view.specialization.postedOnDate,
          ALEXANDRIA_SAVED_SEARCH_FIELDS.filters.postedOnDate
        )
        if (filterValue) filters.push(filterValue)
      }

      if (view.specialization.respondByDate) {
        const filterValue = convertCommonDateFilterValueToSavedSearchValue(
          view.specialization.respondByDate,
          ALEXANDRIA_SAVED_SEARCH_FIELDS.filters.respondByDate
        )
        if (filterValue) filters.push(filterValue)
      }
    }

    if (view.specialization.type === 'SeaPortSpecializedFields') {
      if (view.specialization.postedOnDate) {
        const filterValue = convertCommonDateFilterValueToSavedSearchValue(
          view.specialization.postedOnDate,
          ALEXANDRIA_SAVED_SEARCH_FIELDS.filters.postedOnDate
        )
        if (filterValue) filters.push(filterValue)
      }

      if (view.specialization.respondByDate) {
        const filterValue = convertCommonDateFilterValueToSavedSearchValue(
          view.specialization.respondByDate,
          ALEXANDRIA_SAVED_SEARCH_FIELDS.filters.respondByDate
        )
        if (filterValue) filters.push(filterValue)
      }
    }

    if (view.specialization.type === 'SEWPSpecializedFields') {
      if (view.specialization.postedOnDate) {
        const filterValue = convertCommonDateFilterValueToSavedSearchValue(
          view.specialization.postedOnDate,
          ALEXANDRIA_SAVED_SEARCH_FIELDS.filters.postedOnDate
        )
        if (filterValue) filters.push(filterValue)
      }

      if (view.specialization.respondByDate) {
        const filterValue = convertCommonDateFilterValueToSavedSearchValue(
          view.specialization.respondByDate,
          ALEXANDRIA_SAVED_SEARCH_FIELDS.filters.respondByDate
        )
        if (filterValue) filters.push(filterValue)
      }

      if (view.specialization.qaCutoffDate) {
        const filterValue = convertCommonDateFilterValueToSavedSearchValue(
          view.specialization.qaCutoffDate,
          ALEXANDRIA_SAVED_SEARCH_FIELDS.filters.qaCutoffDate
        )
        if (filterValue) filters.push(filterValue)
      }
    }
  }

  if (view.queryString) {
    filters.push({
      type: 'QueryFilter',
      field: 'query',
      display: view.queryString,
      value: view.queryString,
    })
  }

  if (view.reviewStatus) {
    filters.push({
      type: 'MatchAllFilter',
      field: ALEXANDRIA_SAVED_SEARCH_FIELDS.filters.reviewStatus,
      display: [view.reviewStatus.label],
      values: view.reviewStatus.value === 'all-opps' ? ['all-opps'] : view.reviewStatus.value,
    })
  }

  if (view.sortBy) {
    const convertToBEUnderstood = serializeAlexandriaViewSortByValue(view.sortBy.value)
    if (convertToBEUnderstood) {
      sorts.push({
        type: 'Sort',
        display: '',
        overrideMode: null,
        field: convertToBEUnderstood,
        order: view.sortByDirection === 'asc' ? 'asc' : 'desc',
      })
    }
  }

  if (view.source) {
    options.push({
      type: 'StringOption',
      field: ALEXANDRIA_SAVED_SEARCH_FIELDS.options.source,
      value: view.source,
    })
  }

  if (view.advanced !== null) {
    options.push({
      type: 'FlagOption',
      field: ALEXANDRIA_SAVED_SEARCH_FIELDS.options.advanced,
      value: view.advanced,
    })
  }

  return {
    filters: filters,
    sorts: sorts,
    options: options,
    searchTarget: SearchTargets.alexandria.key,
  }
}

export function convertSavedSearchToAlexandriaView(savedSearch: SavedSearch2.SavedSearch): AlexandriaViewPersisted {
  const view: AlexandriaViewPersisted = _.cloneDeep(ALEXANDRIA_VIEW_EMPTY_STATE)

  // Convert options first so we can use them in the filters.
  for (const option of savedSearch.options) {
    switch (option.field) {
      case ALEXANDRIA_SAVED_SEARCH_FIELDS.options.source: {
        if (option.type === 'StringOption') {
          view.source = option.value
        } else {
          console.error('Unexpected option type for source')
        }
        break
      }
      case ALEXANDRIA_SAVED_SEARCH_FIELDS.options.advanced: {
        if (option.type === 'FlagOption') {
          view.advanced = option.value
        } else {
          console.error('Unexpected option type for advanced')
        }
        break
      }
      default:
        console.error('Unexpected option field: ' + option.field)
        break
    }
  }

  let respondByDate: DateFilterValue | null = null
  let postedOnDate: DateFilterValue | null = null
  let qaCutoffDate: DateFilterValue | null = null
  for (const filter of savedSearch.filters) {
    switch (filter.field) {
      case ALEXANDRIA_SAVED_SEARCH_FIELDS.filters.query: {
        if (filter.type === 'QueryFilter') {
          view.queryString = filter.value
        } else {
          console.error('Unexpected filter type for query')
        }
        break
      }
      case ALEXANDRIA_SAVED_SEARCH_FIELDS.filters.reviewStatus: {
        if (filter.type === 'MatchAllFilter') {
          view.reviewStatus = getReviewStatusDropdownValueFromFilterValue(filter)
        } else {
          console.error('Unexpected filter type for reviewStatus')
        }
        break
      }
      case ALEXANDRIA_SAVED_SEARCH_FIELDS.filters.lastModified: {
        if (filter.type === 'DateFilter') {
          view.lastModifiedDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for lastModified')
        }
        break
      }
      case ALEXANDRIA_SAVED_SEARCH_FIELDS.filters.receivedDate: {
        if (filter.type === 'DateFilter') {
          view.receivedDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for receivedDate')
        }
        break
      }
      case ALEXANDRIA_SAVED_SEARCH_FIELDS.filters.respondByDate: {
        if (filter.type === 'DateFilter') {
          respondByDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for respondByDate')
        }
        break
      }
      case ALEXANDRIA_SAVED_SEARCH_FIELDS.filters.postedOnDate: {
        if (filter.type === 'DateFilter') {
          postedOnDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for postedOnDate')
        }
        break
      }
      case ALEXANDRIA_SAVED_SEARCH_FIELDS.filters.qaCutoffDate: {
        if (filter.type === 'DateFilter') {
          qaCutoffDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for qaCutoffDate')
        }
        break
      }
      default:
        console.error('Unexpected filter field: ' + filter.field)
        break
    }
  }

  switch (view.source) {
    case 'seaport':
    case 'ites': {
      view.specialization = {
        type: view.source === 'ites' ? 'ITESSpecializedFields' : 'SeaPortSpecializedFields',
        postedOnDate: postedOnDate,
        respondByDate: respondByDate,
      }
      break
    }
    case 'sewp': {
      view.specialization = {
        type: 'SEWPSpecializedFields',
        postedOnDate: postedOnDate,
        respondByDate: respondByDate,
        qaCutoffDate: qaCutoffDate,
      }
      break
    }
  }

  for (const sort of savedSearch.sorts) {
    if (sort.type === 'Sort') {
      const dropdownValueValue = deserializeAlexandriaViewSortByValue(sort.field)
      if (dropdownValueValue) {
        view.sortBy = {
          id: dropdownValueValue,
          value: dropdownValueValue,
          label: sort.display,
        }
        view.sortByDirection = sort.order
      }

      // We only support 1 sort at the moment.
      break
    } else {
      console.error('Unexpected sort type: ' + sort.type)
    }
  }

  return view
}
