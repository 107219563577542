import { Fragment, useState } from 'react'
import { ModalV2Context } from './ModalV2Context'
import styles from './ModalV2.module.css'

interface ModalV2AnchorProps {
  children: React.ReactNode
}

export const ModalV2Anchor = (props: ModalV2AnchorProps) => {
  const { children } = props

  const [anchor, setAnchor] = useState<HTMLElement | null>(null)

  return (
    <Fragment>
      <div ref={setAnchor} className={styles['root']} />
      <ModalV2Context.Provider value={anchor}>{children}</ModalV2Context.Provider>
    </Fragment>
  )
}
