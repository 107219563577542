import TextDropdown, { TextDropdownControlled } from '@components/dropdown/TextDropdown'
import styles from './CommonTextDropdown.module.scss'
import { scls } from '@helpers/scls'

const SORT_BY_STYLES = {
  placeholder: (provided) => ({
    ...provided,
    color: 'rgba(10,10,10,.7)',
  }),
  control: (provided) => ({
    ...provided,
    background: 'transparent',
    border: '0',
    minHeight: '20px',
    height: '20px',
  }),
  container: (provided) => ({
    ...provided,
    minWidth: '8rem',
    height: 'auto',
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: '16px',
    alignSelf: 'flex-start',
    padding: '0px 2px',
  }),
  singleValue: () => ({
    fontSize: '16px',
    fontWeight: '400',
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    color: '$blue-primary',
    backgroundColor: state.isSelected || state.isFocused ? 'rgba(45,156,219,.15)' : null,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: () => ({
    height: '20px',
    alignSelf: 'flex-start',
    padding: '0',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0',
    height: '20px',
  }),
}

interface CommonTextDropdownProps {
  options: Array<{
    id: string
    label: string
    value: any
  }>
  disabled?: boolean
  placeholder: string
  label: string
  value: null | { id: string; label: string; value: any }
  onChange: (value: { id: string; label: string; value: any } | null) => void

  className?: string
}

export const CommonTextDropdown = (props: CommonTextDropdownProps) => {
  const { options, disabled, placeholder, value, onChange, label, className } = props

  return (
    <div className={`${className} ` + scls(styles, 'dropdown-wrapper')}>
      <span className="common-text-dropdown-label">{label}:</span>
      <TextDropdownControlled
        className={scls(styles, 'dropdown') + ' common-text-dropdown-dropdown'}
        disabled={disabled}
        placeholder={placeholder}
        styles={SORT_BY_STYLES}
        value={value}
        options={options}
        closeMenuOnSelect
        onChange={onChange}
      />
    </div>
  )
}
