import { classNames } from '@helpers/scls'
import styles from './Page.module.scss'
import PageContent, { PageContentProps } from './PageContent'
import PageDrawer, { PageDrawerProps } from './PageDrawer'
import PageError, { PageErrorProps } from './PageError'
import PageHeader, { PageHeaderProps } from './PageHeader'
import PageLoader, { PageLoaderProps } from './PageLoader'
import PageMessage, { PageMessageProps } from './PageMessage'
import PageHeaderTitle, { PageHeaderTitleProps } from './PageHeaderTitle'
import PageBack, { PageBackProps } from './PageBack'
import PageDemo, { PageDemoProps } from './PageDemo'

export interface PageProps extends React.HTMLAttributes<HTMLElement> {
  bright?: boolean

  p?: 'none' | 'sm' | 'md' | 'lg'
  px?: 'none' | 'sm' | 'md' | 'lg'
  py?: 'none' | 'sm' | 'md' | 'lg'

  disableOverflow?: boolean
  disableOverflowX?: boolean
  disableOverflowY?: boolean

  drawer?: React.ReactNode
}

interface PageComponent extends React.FC<PageProps> {
  Back: React.FC<PageBackProps>
  Content: React.FC<PageContentProps>
  Drawer: React.FC<PageDrawerProps>
  Demo: React.FC<PageDemoProps>
  Error: React.FC<PageErrorProps>
  Header: React.FC<PageHeaderProps>
  HeaderTitle: React.FC<PageHeaderTitleProps>
  Loader: React.FC<PageLoaderProps>
  Message: React.FC<PageMessageProps>
}

/**
 * This component represents the page layout for the main content.
 * It is used to provide consistent p and centering for the main content.
 *
 * @param props
 * @returns
 */
const Page: PageComponent = ((props) => {
  const {
    title,
    className: propsClassName,
    bright,
    disableOverflow,
    disableOverflowX = disableOverflow,
    disableOverflowY = disableOverflow,
    p = 'none',
    px = p,
    py = p,
    drawer,
    ...forwarded
  } = props

  const className = classNames(
    styles.page,
    {
      [styles.bright]: bright,
      [styles.pagePaddingNone]: p === 'none',
      [styles.pxNone]: px === 'none',
      [styles.pxSm]: px === 'sm',
      [styles.pxMd]: px === 'md',
      [styles.pxLg]: px === 'lg',
      [styles.pyNone]: py === 'none',
      [styles.pySm]: py === 'sm',
      [styles.pyMd]: py === 'md',
      [styles.pyLg]: py === 'lg',
      [styles.disableOverflowX]: disableOverflowX,
      [styles.disableOverflowY]: disableOverflowY,
    },
    propsClassName
  )

  if (!drawer) return <div className={className} {...forwarded} />

  return (
    <div className={styles.drawerWrapper}>
      {drawer}
      <div className={className} {...forwarded} />
    </div>
  )
}) as PageComponent

Page.Back = PageBack
Page.Content = PageContent
Page.Demo = PageDemo
Page.Drawer = PageDrawer
Page.Error = PageError
Page.Header = PageHeader
Page.HeaderTitle = PageHeaderTitle
Page.Loader = PageLoader
Page.Message = PageMessage

export default Page
