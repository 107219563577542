// External
import { combineReducers, createStore } from 'redux'

import { LEGACY_BookmarkOptimisticReducer } from '@src/partials/searchv2/redux/LEGACY_BookmarkOptimisticSlice'
import { SearchPageReducer } from '@src/partials/searchv2/redux/SearchPageSlice'
import { SearchPageAPFSReducer } from '@src/partials/searchv2/views/APFS/redux/SearchPageAPFSSlice'
import { SearchPageAlexandriaReducer } from '@src/partials/searchv2/views/Alexandria/redux/SearchPageAlexandriaSlice'
import { SearchPageAwardReducer } from '@src/partials/searchv2/views/Award/redux/SearchPageAwardSlice'
import { SearchPageCIOSPReducer } from '@src/partials/searchv2/views/CIOSP/redux/SearchPageCIOSPSlice'
import { SearchPageContractVehicleReducer } from '@src/partials/searchv2/views/ContractVehicles/redux/SearchPageContractVehicleSlice'
import { SearchPageEBuyReducer } from '@src/partials/searchv2/views/EBuy/redux/SearchPageEBuySlice'
import { SearchPageFedConnectReducer } from '@src/partials/searchv2/views/FedConnect/redux/SearchPageFedConnectSlice'
import { SearchPageGovWinReducer } from '@src/partials/searchv2/views/GovWin/redux/SearchPageGovWinSlice'
import { SearchPageSAMReducer } from '@src/partials/searchv2/views/SAM/redux/SearchPageSAMSlice'
import { SearchPageSymphonyReducer } from '@src/partials/searchv2/views/Symphony/redux/SearchPageSymphonySlice'
import { SearchPageVendorReducer } from '@src/partials/searchv2/views/Vendor/redux/SearchPageVendorSlice'
import { SearchPageQueryReducer } from '@src/partials/searchv2/widgets/DefaultQueryWithSaveButtonAndFiltersWidget/SearchPageQuerySlice'
import currentOpportunityReducer from './reducers/CurrentOpportunitySlice'
import pipelineReducer from './reducers/CurrentPipelineSlice'
import drawerReducer from './reducers/DrawerSlice'
import isMobileReducer from './reducers/IsMobileSlice'
import modalReducer from './reducers/ModalSlice'
import multiSelectReducer from './reducers/MultiSelectSlice'
import { OppSummarySlice } from './reducers/OppSummarySlice.ts/OppSummarySlice'
import permissionsReducer from './reducers/PermissionsSlice'
import signUpSlice from './reducers/SignUpSlice'
import taskListSlice from './reducers/TaskListSlice'
import userReducer from './reducers/UserSlice'
import workspaceReducer from './reducers/WorkspaceSlice'
import usersReducer from './reducers/WorkspaceUsersSlice'

export const permStore = createStore(permissionsReducer)

export function createRootReducer() {
  return combineReducers({
    oppSummary: OppSummarySlice.reducer,
    currentPipeline: pipelineReducer,
    currentOpportunity: currentOpportunityReducer,
    drawer: drawerReducer,
    multiSelect: multiSelectReducer,
    isMobile: isMobileReducer,
    modal: modalReducer,
    permissions: permissionsReducer,
    signUp: signUpSlice,
    taskList: taskListSlice,
    user: userReducer,
    users: usersReducer,
    workspace: workspaceReducer,
    search: SearchPageReducer,
    searchQuery: SearchPageQueryReducer,
    searchSAMView: SearchPageSAMReducer,
    searchAwardView: SearchPageAwardReducer,
    searchAlexandriaView: SearchPageAlexandriaReducer,
    searchAPFSView: SearchPageAPFSReducer,
    searchVendorView: SearchPageVendorReducer,
    searchContractVehicleView: SearchPageContractVehicleReducer,
    searchGovWinView: SearchPageGovWinReducer,
    searchEBuyView: SearchPageEBuyReducer,
    searchCIOSPView: SearchPageCIOSPReducer,
    searchSymphonyView: SearchPageSymphonyReducer,
    searchFedConnectView: SearchPageFedConnectReducer,
    LEGACY_searchV2Bookmarks: LEGACY_BookmarkOptimisticReducer,
  })
}
