import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { LEGACY_Bookmark, LEGACY_BookmarkOptimisticSliceType } from './LEGACY_BookmarkOptimisticSlice.types'
import { SearchPageSliceActions } from './SearchPageSlice'

const initState: LEGACY_BookmarkOptimisticSliceType = {
  bookmarkExternalIdToDataMap: {},
  selectedListing: null,
  loading: false,
}

/**
 * This slice serves to support legacy ListingRenderer and ResultsRenderer which
 * expect bookmarks that can be optimisitically updated.
 */
const LEGACY_BookmarkOptimisticSlice = createSlice({
  name: 'LEGACY_BookmarkOptimisticSlice',
  initialState: initState as LEGACY_BookmarkOptimisticSliceType,
  reducers: {
    init(
      slice,
      action: PayloadAction<{
        // As with the legacy code, this expects parallel arrays
        listings: Array<{ id: string }>
        bookmarks: Array<LEGACY_Bookmark | null>
      }>
    ) {
      const { listings, bookmarks } = action.payload

      const mapping = listings.reduce(
        (m, listing, index) => {
          if (!listing.id) return m

          const correspondingBookmark = bookmarks[index] ?? null
          m[listing.id] = correspondingBookmark
          return m
        },
        {} as Record<string, LEGACY_Bookmark | null>
      )

      slice.bookmarkExternalIdToDataMap = mapping
    },

    setBookmarkLoading(slice, action: PayloadAction<boolean>) {
      slice.loading = action.payload
    },

    updateBookmark(
      slice,
      action: PayloadAction<{
        listingId: string
        bookmark: LEGACY_Bookmark | null
      }>
    ) {
      const { listingId, bookmark } = action.payload
      slice.bookmarkExternalIdToDataMap[listingId] = bookmark
    },
  },
  extraReducers(builder) {
    builder.addCase(SearchPageSliceActions.setSelectedListing, (slice, action) => {
      slice.selectedListing = action.payload?.listing ?? null
    })
  },
})

export const LEGACY_BookmarkOptimisticSliceActions = LEGACY_BookmarkOptimisticSlice.actions
export const LEGACY_BookmarkOptimisticReducer = LEGACY_BookmarkOptimisticSlice.reducer
