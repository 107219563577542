import { SortByDropdown } from '@src/partials/searchv2/components/SortByDropdown/SortByDropdown'
import { Fragment, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { SearchPageGovWinSliceActions } from '../redux/SearchPageGovWinSlice'
import { useGovWinSortBy, useGovWinSortByDirection } from '../redux/selectors'
import { GovWinSearchRestrictionsTooltip } from './GovWinSearchRestrictionsTooltip'

export const GOVWIN_VIEW_SORT_BY_RELEVANCY = 'relevancy'
export const GOVWIN_VIEW_SORT_BY_TITLE = 'title'
export const GOVWIN_VIEW_SORT_BY_BUYING_ORG = 'buying-org'
export const GOVWIN_VIEW_SORT_BY_SOLICITATION_DATE = 'solicitation-date'
export const GOVWIN_VIEW_SORT_BY_VALUE = 'value'
export const GOVWIN_VIEW_SORT_BY_REQUIREMENT = 'requirement'
export const GOVWIN_VIEW_SORT_BY_CREATED_DATE = 'created-date'
export const GOVWIN_VIEW_SORT_BY_UPDATED_DATE = 'updated-date'

const SORT_BY_OPTIONS = [
  {
    id: 'relevancy',
    label: 'Relevancy',
    value: GOVWIN_VIEW_SORT_BY_RELEVANCY,
  },
  {
    id: 'title',
    label: 'Title',
    value: GOVWIN_VIEW_SORT_BY_TITLE,
  },
  {
    id: 'buying-org',
    label: 'Buying Org',
    value: GOVWIN_VIEW_SORT_BY_BUYING_ORG,
  },
  {
    id: 'solicitation-date',
    label: 'Solicitation Date',
    value: GOVWIN_VIEW_SORT_BY_SOLICITATION_DATE,
  },
  {
    id: 'value',
    label: 'Value',
    value: GOVWIN_VIEW_SORT_BY_VALUE,
  },
  {
    id: 'requirement',
    label: 'Requirement',
    value: GOVWIN_VIEW_SORT_BY_REQUIREMENT,
  },
  {
    id: 'created-date',
    label: 'Created Date',
    value: GOVWIN_VIEW_SORT_BY_CREATED_DATE,
  },
  {
    id: 'updated-date',
    label: 'Updated Date',
    value: GOVWIN_VIEW_SORT_BY_UPDATED_DATE,
  },
]

interface GovWinViewSortByDropdownProps {
  disabled?: boolean
}

export const GovWinViewSortByDropdown = (props: GovWinViewSortByDropdownProps) => {
  const { disabled } = props

  const dispatch = useDispatch()
  const value = useGovWinSortBy()
  const direction = useGovWinSortByDirection()
  const onChange = useCallback(
    (nextValue: { id: string; label: string; value: any } | null, nextDirection: 'asc' | 'desc') => {
      // If you select the same value again from the dropdown, it will clear the dropdown.
      if (nextValue && nextValue.value === value?.value && direction === nextDirection) {
        dispatch(
          SearchPageGovWinSliceActions.setSortBy({
            field: null,
            direction: 'desc',
          })
        )
      } else {
        dispatch(
          SearchPageGovWinSliceActions.setSortBy({
            field: nextValue,
            direction: nextDirection,
          })
        )
      }
    },
    [value, direction]
  )

  return (
    <Fragment>
      <GovWinSearchRestrictionsTooltip visible />
      <SortByDropdown
        onChange={onChange}
        options={SORT_BY_OPTIONS}
        value={value}
        direction={direction ?? 'desc'}
        disabled={disabled}
      />
    </Fragment>
  )
}
