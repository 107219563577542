import { SavedSearch2 } from '@nextweb/common/src/saved-search/saved-search'
import moment from 'moment'
import {
  DEFAULT_COMMON_DATE_FILTER_OPTIONS,
  DateFilterAbsoluteDateTime,
  DateFilterDateRangeValue,
  DateFilterPreSupportedDateFilterValue,
  DateFilterValue,
  isDateFilterDateRangeValue,
  isDateFilterPreSupportedDateFilterValue,
} from '../components/CommonDateFilter/CommonDateFilter'

function createRelativeDateRangeValue(days: number, direction: 'last' | 'next'): SavedSearch2.DateRangeValue {
  if (direction === 'last') {
    return {
      type: 'DateRangeValue',
      begin: {
        type: 'RelativeToNowDateTime',
        offsetInSeconds: -1 * moment.duration(days, 'days').asSeconds(),
      },
      end: {
        type: 'RelativeToNowDateTime',
        offsetInSeconds: 0,
      },
    }
  } else {
    return {
      type: 'DateRangeValue',
      begin: {
        type: 'RelativeToNowDateTime',
        offsetInSeconds: 0,
      },
      end: {
        type: 'RelativeToNowDateTime',
        offsetInSeconds: moment.duration(days, 'days').asSeconds(),
      },
    }
  }
}

/**
 * Attention! Be sure to keep this in sync with the dropdown options in CommonDateFilter.tsx
 * @param value
 * @returns
 */
function convertCommonDateFilterPreSupportedValueToDateFilterValue(
  value: DateFilterPreSupportedDateFilterValue
): SavedSearch2.DateRangeValue | SavedSearch2.CalendarPeriodDateValue | null {
  switch (value.value.value) {
    default:
      console.error("Unknown date filter value: '" + value.value.value + "'")
      return null
    case 'next-7-days':
      return createRelativeDateRangeValue(7, 'next')
    case 'last-7-days':
      return createRelativeDateRangeValue(7, 'last')
    case 'next-30-days':
      return createRelativeDateRangeValue(30, 'next')
    case 'last-30-days':
      return createRelativeDateRangeValue(30, 'last')
    case 'next-60-days':
      return createRelativeDateRangeValue(60, 'next')
    case 'last-60-days':
      return createRelativeDateRangeValue(60, 'last')
    case 'next-90-days':
      return createRelativeDateRangeValue(90, 'next')
    case 'last-90-days':
      return createRelativeDateRangeValue(90, 'last')
    case 'next-180-days':
      return createRelativeDateRangeValue(180, 'next')
    case 'last-180-days':
      return createRelativeDateRangeValue(180, 'last')
    case 'calendar-year':
    case 'fiscal-year':
      return {
        type: 'CalendarPeriodDateValue',
        value: {
          type: 'CalendarPeriodDateTime',
          keyTime: value.value.value,
        },
      }
  }
}

const PRESUPPORTED_DISPLAY_KEY = 'pre-'
export function convertCommonDateFilterValueToSavedSearchValue(
  val: DateFilterValue,
  field: string
): SavedSearch2.DateFilter | null {
  const filterValue = val.value
  if (filterValue === null) {
    return null
  }

  if (isDateFilterDateRangeValue(filterValue)) {
    const begin = filterValue.begin
      ? ({
          type: 'SemanticDateTime',
          timestampISOString: filterValue.begin.timestampISOString,
          meaning: 'start-of-day',
        } as const)
      : null
    const end = filterValue.end
      ? ({
          type: 'SemanticDateTime',
          timestampISOString: filterValue.end.timestampISOString,
          meaning: 'end-of-day',
        } as const)
      : null
    return {
      type: 'DateFilter',
      display: 'key-absolute',
      field: field,
      value: {
        type: 'DateRangeValue',
        begin: begin,
        end: end,
      },
    }
  } else if (isDateFilterPreSupportedDateFilterValue(filterValue)) {
    return {
      type: 'DateFilter',
      display: `${PRESUPPORTED_DISPLAY_KEY}${filterValue.value.value}`,
      field: field,
      value: convertCommonDateFilterPreSupportedValueToDateFilterValue(filterValue),
    }
  } else {
    return null
  }
}

/**
 *
 * Functions to handle the conversion of SavedSearch2.DateFilter to CommonDateFilter types.
 *
 */

function convertSavedSearchDateRangeValueDateTimeToCommonFilterDateTime(
  dt:
    | SavedSearch2.AbsoluteDateTime
    | SavedSearch2.RelativeToNowDateTime
    | SavedSearch2.SemanticDateTime
    // | ['inclusive' | 'exclusive', CalendarPeriodDateTime]
    | null
): DateFilterAbsoluteDateTime | null {
  if (dt === null) {
    return null
  }

  switch (dt.type) {
    case 'AbsoluteDateTime':
      return {
        type: 'DateFilterAbsoluteDateTime',
        timestampISOString: dt.timestampISOString,
      }
    case 'RelativeToNowDateTime':
      // The date filter does not support relative times, so we convert it to an absolute time.
      return {
        type: 'DateFilterAbsoluteDateTime',
        timestampISOString: moment().add(dt.offsetInSeconds, 'seconds').toISOString(),
      }
    case 'SemanticDateTime':
      return {
        type: 'DateFilterAbsoluteDateTime',
        timestampISOString: dt.timestampISOString,
      }
    default:
      return null
  }
}

function convertSavedSearchDateRangeValueToCommonFilterValue(
  filter: SavedSearch2.DateFilter
): DateFilterDateRangeValue | DateFilterPreSupportedDateFilterValue | null {
  if (!filter.value || filter.value.type !== 'DateRangeValue') return null

  // The datefilter uses the 'display' field to store where the field is a
  // presupported value or a date range value.
  if (filter.display.startsWith(PRESUPPORTED_DISPLAY_KEY)) {
    // Skipp passed pre-
    const preSupportedValue = filter.display.substring(PRESUPPORTED_DISPLAY_KEY.length)
    const match = DEFAULT_COMMON_DATE_FILTER_OPTIONS.find((opt) => {
      return opt.value === preSupportedValue
    })

    if (match) {
      return {
        type: 'DateFilterPreSupportedDateFilterValue',
        value: {
          label: match.label,
          value: match.value,
        },
      }
    }
  }

  // Else coerce the value into a date range value.
  return {
    type: 'DateFilterDateRangeValue',
    begin: convertSavedSearchDateRangeValueDateTimeToCommonFilterDateTime(filter.value.begin),
    end: convertSavedSearchDateRangeValueDateTimeToCommonFilterDateTime(filter.value.end),
  }
}

export function convertSavedSearchValueToCommonDateFilterValue(
  val: SavedSearch2.DateFilter | null
): DateFilterValue | null {
  if (val === null || val.value === null) {
    return null
  }

  if (val.value.type === 'DateRangeValue') {
    return {
      type: 'DateFilterValue',
      value: convertSavedSearchDateRangeValueToCommonFilterValue(val),
    }
  } else if (val.value.type === 'CalendarPeriodDateValue') {
    return {
      type: 'DateFilterValue',
      value: {
        type: 'DateFilterPreSupportedDateFilterValue',
        value: {
          label: val.display,
          value: val.value.value.keyTime,
        },
      },
    }
  } else {
    return null
  }
}
