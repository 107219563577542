import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import createSagaMiddleware from 'redux-saga'

import { createRootReducer } from './create-root-reducer'

export const sagaMiddleware = createSagaMiddleware()

export function createNextStageStore(init = {}) {
  const store = configureStore({
    preloadedState: init,
    reducer: createRootReducer(),
    middleware(getDefaultMiddleware) {
      return getDefaultMiddleware().concat([sagaMiddleware])
    },
  })

  if (typeof window !== 'undefined') {
    ;(window as any).store = store
  }

  return store
}

export type StoreType = ReturnType<typeof createNextStageStore>

export type RootState = ReturnType<StoreType['getState']>
export type AppDispatch = StoreType['dispatch']

export function useAppDispatch(): AppDispatch {
  return useDispatch()
}

// export default store
