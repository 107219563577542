import { createUrlPathname, isValidUrl, toUrlPathParts } from '@nextweb/common'

export function mapToValidHref(href: string) {
  if (!href) return undefined
  if (!href.includes('.') || href.match(/^\w+:\/\//)) return href
  return `http://${href}`
}

export function mapToWebsocketUrl(url: string) {
  return url.replace(/^https/, 'wss').replace(/^http/, 'ws')
}

/**
 *
 * @deprecated Use `isValidUrl` from `@nextweb/common`
 *
 * @param url
 * @returns
 */
export function isUrl(url: string) {
  return isValidUrl(url)
}

/**
 * Creates the path portion of a URL from the given parts.
 *
 * @deprecated Use `createUrlPathname` from `@nextweb/common`
 *
 * @param parts
 * @returns
 */
export function createPath(...parts: string[]) {
  return createUrlPathname(...parts)
}

/**
 * Creates the path portion of a URL from the given parts.
 *
 * @deprecated Use `toUrlPathParts` from `@nextweb/common`
 *
 * @param parts
 * @returns
 */
export function toPathParts(url: string) {
  return toUrlPathParts(url)
}
