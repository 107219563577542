import React from 'react'
import ReactDOM from 'react-dom'
import { ModalV2Context } from './ModalV2Context'

interface ModalV2PortalProps {
  children: React.ReactNode
}

export const ModalV2Portal = (props: ModalV2PortalProps) => {
  const { children } = props

  const portal = React.useContext(ModalV2Context)
  if (!portal) {
    return null
  }

  return ReactDOM.createPortal(children, portal)
}
