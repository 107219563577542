// External
import { createSlice } from '@reduxjs/toolkit'

interface DrawerState {
  open: boolean
  content?: any
}

const initState: DrawerState = {
  open: false,
  content: undefined,
}

const drawerSlice = createSlice({
  name: 'drawerReducer',
  initialState: initState,
  reducers: {
    resetDrawer: () => initState,

    setDrawer(state, action) {
      const { content } = action.payload
      state.open = true
      state.content = content
    },
  },
})

export const { setDrawer, resetDrawer } = drawerSlice.actions

export default drawerSlice.reducer
