import { APFSViewState } from '../apfs-view-state'

export type SearchPageAPFSSliceType = SearchPageAPFSSliceTypeInitial | SearchPageAPFSSliceTypeReady

export type SearchPageAPFSSliceTypeInitial = {
  state: 'initial'
}

export type SearchPageAPFSSliceTypeReady = {
  state: 'ready'

  // 'view' contains state as the state is displayed on the page, or, in a form
  // that the components on the page require.
  view: APFSViewState
}

export type SearchPageAPFSSliceViewType = Exclude<SearchPageAPFSSliceTypeReady['view'], null>['type']

export type SearchPageViewWithType<T extends SearchPageAPFSSliceViewType> = Extract<
  SearchPageAPFSSliceTypeReady['view'],
  { type: T }
>

export function isSearchPageAPFSSliceReady(s: SearchPageAPFSSliceType): s is SearchPageAPFSSliceTypeReady {
  return s.state === 'ready'
}
