import { FlyInModalPortal } from '@components/ModalV2/modals/FlyInModal/FlyInModalPortal'
import Button from '@components/button/Button'
import { scls } from '@helpers/scls'
import { GetMeDocument, useGetMeQuery, useUpdateCurrentUserWorkspaceMutation } from '@interfaces/graphql'
import { WORKSPACE_SCOPE_EVENT_TYPE, WorkspaceScopeEvent } from '@src/apollo/workspace/WorkspaceScopeEvent'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './WorkspaceChangeModal.module.css'

interface WorkspaceChangeModalDisplayedProps {
  event: WorkspaceScopeEvent
  onClose: () => void
}
const WorkspaceChangeModalDisplayed = (props: WorkspaceChangeModalDisplayedProps) => {
  const { event, onClose } = props
  const { data, loading, error } = useGetMeQuery()

  const isInWorkspace = useMemo(() => {
    if (!data || !Array.isArray(data?.getMe.userWorkspaces)) return false
    return data.getMe.userWorkspaces.find((uw) => uw?.workspaceId === event.targetWorkspace) !== undefined
  }, [event, data])

  const userWorkspace = useMemo(() => {
    if (!data || !Array.isArray(data?.getMe.userWorkspaces)) return null
    return data.getMe.userWorkspaces.find((uw) => uw?.workspaceId === event.targetWorkspace) ?? null
  }, [event, data])

  const router = useRouter()
  const onGoBackClick = useCallback(() => {
    router.push('/pipeline')
  }, [])

  const [updateCurrentUserWorkspaceMutation, { loading: awaitingWorkspaceChange }] =
    useUpdateCurrentUserWorkspaceMutation({
      awaitRefetchQueries: true,
      refetchQueries: [GetMeDocument],
    })

  const onChangeWorkspace = useCallback(() => {
    if (!userWorkspace) return
    updateCurrentUserWorkspaceMutation({
      variables: {
        userWorkspaceId: userWorkspace.id,
      },
    }).finally(() => {
      if (typeof window !== 'undefined') window.location.reload()
      onClose()
    })
  }, [userWorkspace])

  if (loading || error || !data || !isInWorkspace || !userWorkspace) {
    return null
  }

  return (
    <div className={scls(styles, 'container')}>
      <h3>Change Workspace Required</h3>
      <div className={scls(styles, 'hitline')}>
        <span>
          You must be in the <b>{userWorkspace.workspaceName}</b> workspace to view this page.
        </span>
      </div>
      <div className={scls(styles, 'buttons')}>
        <Button btnType="blue" onClick={onChangeWorkspace} disabled={awaitingWorkspaceChange}>
          Change Workspace
        </Button>
        <Button onClick={onGoBackClick}>Go Back</Button>
      </div>
    </div>
  )
}

interface WorkspaceChangeModalInternalProps {
  event: WorkspaceScopeEvent | null
  onClose: () => void
}
const WorkspaceChangeModalInternal = (props: WorkspaceChangeModalInternalProps) => {
  const { event, onClose } = props
  if (!event) return null

  return <WorkspaceChangeModalDisplayed event={event} onClose={onClose} />
}

interface WorkspaceChangeModalProps {}

export const WorkspaceChangeModal = (props: WorkspaceChangeModalProps) => {
  const {} = props

  const [visible, setVisible] = useState(false)
  const [event, setEvent] = useState<WorkspaceScopeEvent | null>(null)

  useEffect(() => {
    function onWorkspaceScopeChangeEvent(e: Event) {
      if (e instanceof WorkspaceScopeEvent) {
        setVisible(true)
        setEvent(e)
      }
    }
    if (typeof window !== 'undefined') {
      window.addEventListener(WORKSPACE_SCOPE_EVENT_TYPE, onWorkspaceScopeChangeEvent)

      return () => {
        window.removeEventListener(WORKSPACE_SCOPE_EVENT_TYPE, onWorkspaceScopeChangeEvent)
      }
    }
  }, [])

  const router = useRouter()
  const onClickOff = useCallback(() => {
    setVisible(false)
    router.push('/pipeline')
  }, [])

  const onClose = useCallback(() => {
    setVisible(false)
  }, [])

  // For now, only allow this modal when attempting to view a note.
  if (router.query['utm_campaign'] !== 'note_mention_notification') {
    return null
  }

  return (
    <FlyInModalPortal onClickOff={onClickOff} visible={visible} dim>
      <WorkspaceChangeModalInternal event={event} onClose={onClose} />
    </FlyInModalPortal>
  )
}
