import ReactTooltip, { TooltipProps as ReactTooltipProps } from 'react-tooltip'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import styles from './Tooltip.module.scss'

interface TooltipProps extends ReactTooltipProps {
  id: string
  tooltipContent: any
  className?: string
  customStyles?: any
  icon?: FontAwesomeIconProps['icon']
}

const Tooltip = ({
  id,
  tooltipContent,
  className = '',
  customStyles = {},
  icon = faQuestionCircle,
  place = 'top',
  type = 'dark',
  effect = 'float',
  arrowColor = '',
  ...props
}: TooltipProps) => {
  const realArrowColor = arrowColor === '' ? undefined : arrowColor
  return (
    <div data-tip data-for={id} className={`${styles.tooltip} ${className}`} style={customStyles} {...props}>
      <FontAwesomeIcon icon={icon} />
      <ReactTooltip id={id} uuid={id} place={place} type={type} effect="solid" arrowColor={realArrowColor}>
        {tooltipContent}
      </ReactTooltip>
    </div>
  )
}

export default Tooltip
