// External
import { createSlice } from '@reduxjs/toolkit'
import { Task } from '@interfaces/graphql'

interface TaskState {
  open: boolean
  task?: Task
}

const initState: TaskState = {
  open: false,
  task: undefined,
}

const taskListSlice = createSlice({
  name: 'taskListReducer',
  initialState: initState,
  reducers: {
    resetTaskListDetails: () => initState,

    setTaskListDetails(state, action) {
      const { task } = action.payload
      state.open = true
      state.task = task
    },
  },
})

export const { setTaskListDetails, resetTaskListDetails } = taskListSlice.actions

export default taskListSlice.reducer
