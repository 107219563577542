export const WORKSPACE_SCOPE_EVENT_TYPE = 'WORKSPACE_SCOPE_EVENT_TYPE'

export class WorkspaceScopeEvent extends Event {
  public message: string
  public targetWorkspace: number
  constructor(message: string, targetWorkspace: number) {
    super(WORKSPACE_SCOPE_EVENT_TYPE, {
      bubbles: false,
    })
    this.targetWorkspace = targetWorkspace
    this.message = message
  }
}
