import { FedConnectViewState } from '../fedconnect-view-state'

export type SearchPageFedConnectSliceType = SearchPageFedConnectSliceTypeInitial | SearchPageFedConnectSliceTypeReady

export type SearchPageFedConnectSliceTypeInitial = {
  state: 'initial'
}

export type SearchPageFedConnectSliceTypeReady = {
  state: 'ready'

  // 'view' contains state as the state is displayed on the page, or, in a form
  // that the components on the page require.
  view: FedConnectViewState
}

export type SearchPageFedConnectSliceViewType = Exclude<SearchPageFedConnectSliceTypeReady['view'], null>['type']

export type SearchPageViewWithType<T extends SearchPageFedConnectSliceViewType> = Extract<
  SearchPageFedConnectSliceTypeReady['view'],
  { type: T }
>

export function isSearchPageFedConnectSliceReady(
  s: SearchPageFedConnectSliceType
): s is SearchPageFedConnectSliceTypeReady {
  return s.state === 'ready'
}
