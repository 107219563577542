import { EnvironmentVariable, getEnvironment, isClientSide } from './environment'
import { mapToWebsocketUrl } from './links'

export function getHostOrigin() {
  if (isClientSide()) return window.location.origin
  return getEnvironment().getString(EnvironmentVariable.HOST)
}

export function getWSOrigin() {
  if (isClientSide()) {
    return window.location.origin.replace(/^https/, 'wss').replace(/^http/, 'ws')
  }
  return getEnvironment().getString(EnvironmentVariable.WS_ORIGIN)
}

export function getGraphQLOrigin() {
  if (isClientSide()) return window.location.origin
  return getEnvironment().getString(EnvironmentVariable.GRAPHQL_ORIGIN)
}

export function getCubeOrigin() {
  if (isClientSide()) return window.location.origin
  return getEnvironment().getString(EnvironmentVariable.CUBE_ORIGIN)
}

export function getUpdatesV2HocuspocusOrigin() {
  const origin = isClientSide()
    ? mapToWebsocketUrl(window.location.origin)
    : getEnvironment().getString(EnvironmentVariable.HOCUSPOCUS_UPDATES_V2_ORIGIN)

  if (origin.endsWith('/opp-updates-v2-sync')) return origin
  return `${origin}/opp-updates-v2-sync`
}

export function getHocuspocusOrigin() {
  const origin = isClientSide()
    ? mapToWebsocketUrl(window.location.origin)
    : getEnvironment().getString(EnvironmentVariable.HOCUSPOCUS_ORIGIN)

  if (origin.endsWith('/document-sync')) return origin
  return `${origin}/document-sync`
}
