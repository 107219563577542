import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SearchPageQuerySliceType } from './SearchPageQuerySlice.types'

const initState: SearchPageQuerySliceType = {
  queryString: '',
  showFilters: true,
}

const SearchPageQuerySlice = createSlice({
  name: 'SearchPageQuerySlice',
  initialState: initState as SearchPageQuerySliceType,
  reducers: {
    queryChange(slice, action: PayloadAction<{ query: string }>) {
      const { query } = action.payload
      slice.queryString = query
    },
    showFiltersChange(slice, action: PayloadAction<{ show: boolean }>) {
      const { show } = action.payload
      slice.showFilters = show
    },
  },
})

export const SearchPageQuerySliceActions = SearchPageQuerySlice.actions
export const SearchPageQueryReducer = SearchPageQuerySlice.reducer
