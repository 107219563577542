export enum Feature {
  PROPOSAL_MANAGEMENT = 'PROPOSAL_MANAGEMENT',

  /**
   * Feature to view the workspace admin page.
   * We'll want a check to determine if the user is a workspace admin.
   */
  PAGE_WORKSPACE_ADMIN = 'PAGE_WORKSPACE_ADMIN',

  /**
   * This customer wanted a specific report to be available as home.
   */
  HACK_ALLEGIENT_HOME = 'HACK_ALLEGIENT_HOME',

  /**
   * Enable the new opps pipeline kanban view.
   */
  OPPS_PIPELINE_KANBAN_V2 = 'OPPS_PIPELINE_KANBAN_V2',

  /**
   * Enable the new opps pipeline kanban view.
   */
  TIPTAP_INLINE_REWRITE = 'TIPTAP_INLINE_REWRITE',

  COMPLIANCE_MATRIX_TAB = 'COMPLIANCE_MATRIX_TAB',

  /**
   * Developer feature to specify that the environment keys should be logged when set.
   */
  ENVIRONMENT_LOGGING = 'ENVIRONMENT_LOGGING',
}

/**
 * Features that are enabled/disabled by workspace
 */
export type WorkspaceFeature = Feature.PROPOSAL_MANAGEMENT | Feature.HACK_ALLEGIENT_HOME

/**
 * Features that are enabled/disabled locally on the browser via local storage.
 *
 * This is generally a temporary state.
 */
export type TestFeature =
  | Feature.OPPS_PIPELINE_KANBAN_V2
  | Feature.TIPTAP_INLINE_REWRITE
  | Feature.COMPLIANCE_MATRIX_TAB
  | Feature.ENVIRONMENT_LOGGING

/**
 * Features that are enabled/disabled based on the current user's workspace permissions.
 */
export type PermissionFeature = Feature.PAGE_WORKSPACE_ADMIN
