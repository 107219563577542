export const GOVWIN_EVENT_TYPE = 'GOVWIN_EVENT_TYPE'

export class GovWinEvent extends Event {
  public govWinErrorType: 'Expired' | 'Bad'
  constructor(type: 'Expired' | 'Bad') {
    super(GOVWIN_EVENT_TYPE, {
      bubbles: false,
    })

    this.govWinErrorType = type
  }
}
