import { BookmarkReason } from '@interfaces/graphql'
import { SavedSearch2, SearchTargets, convertQueryParamsToSavedSearch } from '@nextweb/common'
import { DateFilterValue } from '../../components/CommonDateFilter/CommonDateFilter'
import { getSavedSearchFromQueryParams } from '../../redux/saved-search-query-params'
import { firstString } from '../../redux/utils'
import { SEARCH_VIEW_QUERY_PARAM_KEY } from '../../url-management/search-view-query-param-key'
import {
  FEDCONNECT_VIEW_SORT_BY_ISSUE_DATE,
  FEDCONNECT_VIEW_SORT_BY_OPTIONS,
} from './components/FedConnectViewSortByDropdown'
import { convertSavedSearchToFedConnectView } from './saved-search/convert-fedconnect-view-and-saved-search'

export const FEDCONNECT_VIEW_KEY = SearchTargets.fedconnect.label

export function isFedConnectViewKey(s?: any): boolean {
  s = Array.isArray(s) ? firstString(s) : s
  return typeof s === 'string' && s.toUpperCase() === FEDCONNECT_VIEW_KEY.toUpperCase()
}

// All serializable/saveable state should go into this.
export interface FedConnectViewPersisted {
  advanced: boolean | null
  reviewStatus: {
    id: string
    value: Array<BookmarkReason> | 'all-opps'
    label: string
  } | null
  sortBy: { id: string; value: string; label: string } | null
  sortByDirection: 'asc' | 'desc' | null

  queryString: string

  // type: Array<{ id: string; value: string; label: string }> | null

  setAside: Array<{ id: string; value: string; label: string }> | null
  naics: Array<{ id: string; value: string; label: string }> | null
  agency: Array<{ id: string; value: string; label: string }> | null
  placeOfPerformance: Array<{ id: string; value: string; label: string }> | null
  type: Array<{ id: string; value: string; label: string }> | null
  psc: Array<{ id: string; value: string; label: string }> | null

  issueDate: DateFilterValue | null
  responseDueDate: DateFilterValue | null
}

export interface FedConnectViewState {
  type: typeof FEDCONNECT_VIEW_KEY

  // All serializable/saveable state should go into this.
  persisted: FedConnectViewPersisted

  savedSearch: { id: string; value: string; label: string } | null

  selectedListing: any
  pageFrom: number
  hitCount: number | null
}

/**
 * Attention! If a particular alexandria source begins to deviate too much from
 * this structure, consider creating a new view type!
 */
export const FEDCONNECT_VIEW_DEFAULT_STATE: FedConnectViewPersisted = {
  advanced: true,
  reviewStatus: null,
  sortBy: FEDCONNECT_VIEW_SORT_BY_OPTIONS.find(({ value }) => value === FEDCONNECT_VIEW_SORT_BY_ISSUE_DATE)!,
  sortByDirection: 'desc',

  // "The" query string.
  queryString: '',

  naics: null,
  setAside: null,
  placeOfPerformance: null,
  agency: null,
  type: null,
  psc: null,

  responseDueDate: null,
  issueDate: null,
}

export const FEDCONNECT_VIEW_EMPTY_STATE: FedConnectViewPersisted = {
  advanced: null,
  reviewStatus: null,
  sortBy: null,
  sortByDirection: null,

  // "The" query string.
  queryString: '',

  naics: null,
  setAside: null,
  placeOfPerformance: null,
  agency: null,
  type: null,
  psc: null,

  // placeOfPerformance: null,

  responseDueDate: null,
  issueDate: null,
}

/**
 * This may return null if the query fields are really malformed.
 * @param query
 */
export function createFedConnectViewFromQuery(query: Record<string, string | string[]>): FedConnectViewState | null {
  // This should be checked before calling this function ideally.
  if (!isFedConnectViewKey(query[SEARCH_VIEW_QUERY_PARAM_KEY])) return null

  const savedSearch = convertQueryParamsToSavedSearch({
    params: query,
  })

  return {
    type: FEDCONNECT_VIEW_KEY,
    selectedListing: null,
    pageFrom: 0,
    hitCount: null,
    savedSearch: getSavedSearchFromQueryParams({ params: query }),
    persisted:
      savedSearch && !SavedSearch2.isEmptySavedSearch(savedSearch)
        ? convertSavedSearchToFedConnectView(savedSearch)
        : FEDCONNECT_VIEW_DEFAULT_STATE,
  }
}
