import React, { useCallback, useEffect, useState } from 'react'

export interface BooleanHelperParams {
  initialValue?: boolean
}

export interface BooleanHelper {
  value: boolean
  setTrue: () => void
  setFalse: () => void
  toggle: () => void
  setValue: React.Dispatch<React.SetStateAction<boolean>>
}

/**
 *
 * @param params
 * @returns
 */
function useBooleanHelper(params: BooleanHelperParams = {}): BooleanHelper {
  const { initialValue = false } = params

  const [value, setValue] = useState(initialValue)

  const setTrue = useCallback(() => setValue(true), [setValue])
  const setFalse = useCallback(() => setValue(false), [setValue])
  const toggle = useCallback(() => setValue((prev) => !prev), [setValue])

  useEffect(() => setValue(initialValue), [setValue, initialValue])

  return { value, setTrue, setFalse, toggle, setValue }
}

export default useBooleanHelper
