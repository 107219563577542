// External
import { User } from '@interfaces/graphql'
import { createSlice } from '@reduxjs/toolkit'

const initState: { users: User[] } = {
  users: [],
}

const usersSlice = createSlice({
  name: 'modalReducer',
  initialState: initState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload
    },

    resetUsers: () => initState,
  },
})

export const { setUsers, resetUsers } = usersSlice.actions

export default usersSlice.reducer
