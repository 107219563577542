// External
import { createSlice } from '@reduxjs/toolkit'
import { GetPrivOppQueryResult } from '@interfaces/graphql'

interface CurrentPipelineState {
  currentOpportunity?: GetPrivOppQueryResult['data'] | undefined
}

const initState: CurrentPipelineState = { currentOpportunity: undefined }

const currentOpportunitySlice = createSlice({
  name: 'modalReducer',
  initialState: initState,
  reducers: {
    setCurrentOpportunity(state, action) {
      state.currentOpportunity = action.payload as GetPrivOppQueryResult['data']
    },
    resetCurrentOpportunity: () => initState,
  },
})

export const { setCurrentOpportunity, resetCurrentOpportunity } = currentOpportunitySlice.actions

export default currentOpportunitySlice.reducer
