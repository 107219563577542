import { isNil } from 'lodash'
import { useLayoutEffect, useState } from 'react'

export enum Breakpoint {
  TINY = 380,
  MOBILE = 520,
  TABLET = 940,
  LAPTOP_SM = 1280,
  DESKTOP_SM = 1400,
  DESKTOP_MD = 1920,
}

export type UseBreakpointOptions = {
  minWidth?: Breakpoint
  maxWidth?: Breakpoint
}

function getWindowWidth(): number | null {
  if (typeof window === 'undefined') return null
  return window.innerWidth
}

function useWindowWidth(): number | null {
  const [width, setWidth] = useState(getWindowWidth())
  const globalWindow = typeof window !== 'undefined' ? window : null

  useLayoutEffect(() => {
    if (!!globalWindow) {
      const updateWidth = () => setWidth(getWindowWidth())

      globalWindow.addEventListener('resize', updateWidth)

      return () => {
        globalWindow.removeEventListener('resize', updateWidth)
      }
    }
  }, [globalWindow, setWidth])

  return width
}

function useBreakpoint(options: UseBreakpointOptions): boolean {
  const { minWidth, maxWidth } = options
  const width = useWindowWidth()

  if (isNil(width)) return false

  if (!isNil(minWidth) && width < minWidth) return false
  if (!isNil(maxWidth) && width > maxWidth) return false

  return true
}

export default useBreakpoint
