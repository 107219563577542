// External
import { createSlice } from '@reduxjs/toolkit'

interface SignUpState {
  firstName?: string
  lastName?: string
  email?: string
  token?: string
  workPhone?: string
}

const initState: SignUpState = {
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  token: undefined,
  workPhone: undefined,
}

const signUpSlice = createSlice({
  name: 'signUpReducer',
  initialState: initState,
  reducers: {
    setSignUpSlice: (state, action) => {
      const { firstName, email, lastName, token, workPhone } = action.payload
      state.email = email
      state.firstName = firstName
      state.lastName = lastName
      state.token = token
      state.workPhone = workPhone
    },
  },
})

export const { setSignUpSlice } = signUpSlice.actions

export default signUpSlice.reducer
