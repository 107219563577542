import { BookmarkReason } from '@interfaces/graphql'
import { SavedSearch2, SearchTargets, convertQueryParamsToSavedSearch } from '@nextweb/common'
import { DateFilterValue } from '../../components/CommonDateFilter/CommonDateFilter'
import { getSavedSearchFromQueryParams } from '../../redux/saved-search-query-params'
import { firstString } from '../../redux/utils'
import { SEARCH_VIEW_QUERY_PARAM_KEY } from '../../url-management/search-view-query-param-key'
import { convertSavedSearchToSAMView } from './saved-search/convert-sam-view-and-saved-search'

/**
 * These don't have to be the same but it is convenient if they are.
 */
export const SAM_VIEW_KEY = SearchTargets.sam.label

export function isSAMViewKey(s?: any): boolean {
  s = Array.isArray(s) ? firstString(s) : s
  return typeof s === 'string' && s.toUpperCase() === SAM_VIEW_KEY.toUpperCase()
}

// All serializable/saveable state should go into this.
export interface SAMViewPersisted {
  // Some common stuff, not sure how to colocate this stuff yet.
  // SavedSearch dropdown is not itself persisted.
  // The SavedSearch dropdown is still populated by query, etc.
  // savedSearch: { id: string; value: string; label: string } | null
  advanced: boolean | null
  reviewStatus: {
    id: string
    value: Array<BookmarkReason> | 'all-opps'
    label: string
  } | null
  sortBy: { id: string; value: string; label: string } | null
  sortByDirection: 'asc' | 'desc' | null

  // "The" query string.
  queryString: string

  // SAM Specific stuff
  open: boolean | null
  naics: Array<{ id: string; value: string; label: string }> | null
  agency: Array<{ id: string; value: string; label: string }> | null
  placeOfPerformance: Array<{ id: string; value: string; label: string }> | null
  setaside: Array<{ id: string; value: string; label: string }> | null
  awardType: Array<{ id: string; value: string; label: string }> | null

  postedOnDate: DateFilterValue | null
  respondByDate: DateFilterValue | null
}

export interface SAMViewState {
  type: typeof SAM_VIEW_KEY

  // All serializable/saveable state should go into this.
  persisted: SAMViewPersisted

  savedSearch: { id: string; value: string; label: string } | null

  selectedListing: any
  pageFrom: number
  hitCount: number | null
}

export const SAM_VIEW_DEFAULT_STATE: SAMViewPersisted = {
  advanced: true,
  reviewStatus: null,
  sortBy: null,
  sortByDirection: null,

  // "The" query string.
  queryString: '',

  // SAM Specific stuff
  open: null,
  naics: null,
  agency: null,
  placeOfPerformance: null,
  setaside: null,
  awardType: null,

  postedOnDate: null,
  respondByDate: null,
}

export const SAM_VIEW_EMPTY_STATE: SAMViewPersisted = {
  advanced: null,
  reviewStatus: null,
  sortBy: null,
  sortByDirection: null,

  // "The" query string.
  queryString: '',

  // SAM Specific stuff
  open: null,
  naics: null,
  agency: null,
  placeOfPerformance: null,
  setaside: null,
  awardType: null,

  postedOnDate: null,
  respondByDate: null,
}

/**
 * This may return null if the query fields are really malformed.
 * @param query
 */
export function createSAMViewFromQuery(query: Record<string, string | string[]>): SAMViewState | null {
  // This should be checked before calling this function ideally.
  if (!isSAMViewKey(query[SEARCH_VIEW_QUERY_PARAM_KEY])) return null

  const savedSearch = convertQueryParamsToSavedSearch({
    params: query,
  })

  return {
    type: SAM_VIEW_KEY,
    selectedListing: null,
    pageFrom: 0,
    hitCount: null,
    savedSearch: getSavedSearchFromQueryParams({ params: query }),
    persisted:
      savedSearch && !SavedSearch2.isEmptySavedSearch(savedSearch)
        ? convertSavedSearchToSAMView(savedSearch)
        : SAM_VIEW_DEFAULT_STATE,
  }
}
