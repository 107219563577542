import { classNames } from '@helpers/scls'
import styles from './Page.module.scss'
import Link from 'next/link'
import { Button } from '@mantine/core'

export interface PageBackProps extends React.HTMLAttributes<HTMLElement> {
  url?: string | null
}

const PageBack = (props: PageBackProps) => {
  const { className: propsClassName, url, children, ...forwarded } = props

  const className = classNames(styles.back, propsClassName)

  if (!url) return null

  return (
    <Link href={url}>
      <Button {...forwarded} component="a" className={className} variant="default">
        Back
      </Button>
    </Link>
  )
}

export default PageBack
