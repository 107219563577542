// External
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface UserResourcePermissionV2 {
  includeResourceId: string[]
  excludeResourceId: string[]
  includeOperationType: string[]
  excludeOperationType: string[]
  includeResourceType: string[]
  excludeResourceType: string[]
}

export interface MeType {
  id: number
  firstName: string
  lastName: string
  userId: string
  freeTrial: boolean
  isPayingCustomer: boolean
  isActive: boolean | null
  isVerifiedEmail: boolean | null

  TOBEREMOVED_pipelinesWithUserManagementEnabled: string[]
  TOBEREMOVED_superDuperCanEditPipelineUserAccessEvenWithoutPipelineAdminAccess: boolean
  permissions: UserResourcePermissionV2[]

  // TODO: Fix type
  defaultPipeline?: any
  defaultPipelineView?: any
  defaultTimelineViewUUId?: string
  defaultTimelineViewType?: 'TimelineView' | 'PipelineView'
}

export type UserSliceType =
  | (MeType & {
      state: 'ready'
    })
  | {
      state: 'init'
      id: undefined
      firstName: undefined
      lastName: undefined
      userId: undefined
      TOBEREMOVED_pipelinesWithUserManagementEnabled: string[]
      TOBEREMOVED_superDuperCanEditPipelineUserAccessEvenWithoutPipelineAdminAccess: undefined
      permissions: UserResourcePermissionV2[]
      defaultPipeline: undefined
      defaultPipelineView: undefined
      defaultTimelineViewUUId: undefined
      defaultTimelineViewType: undefined
      freeTrial: undefined
      isPayingCustomer: undefined
    }

export function isUserSliceReady(state: UserSliceType): state is MeType & {
  state: 'ready'
} {
  return state.state === 'ready'
}

const initState: UserSliceType = {
  state: 'init',
  id: undefined,
  userId: undefined,
  TOBEREMOVED_pipelinesWithUserManagementEnabled: [],
  TOBEREMOVED_superDuperCanEditPipelineUserAccessEvenWithoutPipelineAdminAccess: undefined,
  firstName: undefined,
  lastName: undefined,
  defaultPipeline: undefined,
  defaultPipelineView: undefined,
  defaultTimelineViewUUId: undefined,
  defaultTimelineViewType: undefined,
  freeTrial: undefined,
  isPayingCustomer: undefined,
  permissions: [],
}

const userSlice = createSlice({
  name: 'userIdReducer',
  initialState: initState as UserSliceType,
  reducers: {
    setUser: (state, action: PayloadAction<MeType>) => {
      let {
        id,
        userId,
        firstName,
        lastName,
        defaultPipeline,
        defaultPipelineView,
        defaultTimelineViewUUId,
        defaultTimelineViewType,
        TOBEREMOVED_pipelinesWithUserManagementEnabled,
        TOBEREMOVED_superDuperCanEditPipelineUserAccessEvenWithoutPipelineAdminAccess,
        freeTrial,
        isActive,
        isPayingCustomer,
        isVerifiedEmail,
        permissions,
      } = action.payload

      return {
        state: 'ready',
        id: id,
        firstName: firstName,
        lastName: lastName,
        userId: userId,
        TOBEREMOVED_pipelinesWithUserManagementEnabled: TOBEREMOVED_pipelinesWithUserManagementEnabled,
        TOBEREMOVED_superDuperCanEditPipelineUserAccessEvenWithoutPipelineAdminAccess:
          TOBEREMOVED_superDuperCanEditPipelineUserAccessEvenWithoutPipelineAdminAccess,
        defaultPipeline: defaultPipeline,
        defaultPipelineView: defaultPipelineView,
        defaultTimelineViewUUId: defaultTimelineViewUUId,
        defaultTimelineViewType: defaultTimelineViewType,
        freeTrial: freeTrial,
        isActive: isActive,
        isPayingCustomer: isPayingCustomer,
        isVerifiedEmail: isVerifiedEmail,
        permissions: permissions,
      }
    },
    resetUser: () => initState,
  },
})

export const { setUser, resetUser } = userSlice.actions

export default userSlice.reducer
