import { firstString } from './utils'

export const SAVED_SEARCH_UUID_QUERY_PARAM_KEY = 'savedSearchUUId'
export const SAVED_SEARCH_NAME_QUERY_PARAM_KEY = 'savedSearchName'

export function getSavedSearchFromQueryParams(args: {
  params: Record<string, string | string[]>
}): { id: string; label: string; value: string } | null {
  const { params } = args

  const savedSearchUUIdParam = params[SAVED_SEARCH_UUID_QUERY_PARAM_KEY]
  const savedSearchUUId = savedSearchUUIdParam ? firstString(savedSearchUUIdParam) : null

  const savedSearchNameParam = params[SAVED_SEARCH_NAME_QUERY_PARAM_KEY]
  const savedSearchName = savedSearchNameParam ? firstString(savedSearchNameParam) : null

  return savedSearchUUId
    ? {
        id: savedSearchUUId,
        label: savedSearchName ?? '',
        value: savedSearchUUId,
      }
    : null
}
