import { GOVWIN_SAVED_SEARCH_FIELDS, SavedSearch2, SearchTargets } from '@nextweb/common'
import {
  getDropdownValueFromFilterValue,
  getReviewStatusDropdownValueFromFilterValue,
  serializeCommonDropdownViewProperty,
} from '@src/partials/searchv2/saved-search/common-conversions'
import {
  convertCommonDateFilterValueToSavedSearchValue,
  convertSavedSearchValueToCommonDateFilterValue,
} from '@src/partials/searchv2/saved-search/convert-common-date-filter-value-to-saved-search-value'
import _ from 'lodash'
import {
  GOVWIN_VIEW_SORT_BY_BUYING_ORG,
  GOVWIN_VIEW_SORT_BY_CREATED_DATE,
  GOVWIN_VIEW_SORT_BY_RELEVANCY,
  GOVWIN_VIEW_SORT_BY_REQUIREMENT,
  GOVWIN_VIEW_SORT_BY_SOLICITATION_DATE,
  GOVWIN_VIEW_SORT_BY_TITLE,
  GOVWIN_VIEW_SORT_BY_UPDATED_DATE,
  GOVWIN_VIEW_SORT_BY_VALUE,
} from '../components/GovWinViewSortByDropdown'
import { GOVWIN_VIEW_EMPTY_STATE, GovWinViewPersisted } from '../govwin-view-state'

/**
 * Convert the SAM View Dropdown value into the value expected by the backend.
 * @param dropdownValue
 * @returns
 */
function serializeGovWinViewSortByValue(dropdownValue: string) {
  switch (dropdownValue) {
    case GOVWIN_VIEW_SORT_BY_RELEVANCY:
      return GOVWIN_SAVED_SEARCH_FIELDS.sorts.relevancy
    case GOVWIN_VIEW_SORT_BY_TITLE:
      return GOVWIN_SAVED_SEARCH_FIELDS.sorts.title
    case GOVWIN_VIEW_SORT_BY_BUYING_ORG:
      return GOVWIN_SAVED_SEARCH_FIELDS.sorts.buyingOrg
    case GOVWIN_VIEW_SORT_BY_SOLICITATION_DATE:
      return GOVWIN_SAVED_SEARCH_FIELDS.sorts.soliciationDate
    case GOVWIN_VIEW_SORT_BY_VALUE:
      return GOVWIN_SAVED_SEARCH_FIELDS.sorts.value
    case GOVWIN_VIEW_SORT_BY_REQUIREMENT:
      return GOVWIN_SAVED_SEARCH_FIELDS.sorts.requirements
    case GOVWIN_VIEW_SORT_BY_CREATED_DATE:
      return GOVWIN_SAVED_SEARCH_FIELDS.sorts.createdDate
    case GOVWIN_VIEW_SORT_BY_UPDATED_DATE:
      return GOVWIN_SAVED_SEARCH_FIELDS.sorts.updatedDate
    default:
      return null
  }
}

function deserializeGovWinViewSortByValue(dropdownValue: string) {
  switch (dropdownValue) {
    case GOVWIN_SAVED_SEARCH_FIELDS.sorts.relevancy:
      return GOVWIN_VIEW_SORT_BY_RELEVANCY
    case GOVWIN_SAVED_SEARCH_FIELDS.sorts.title:
      return GOVWIN_VIEW_SORT_BY_TITLE
    case GOVWIN_SAVED_SEARCH_FIELDS.sorts.buyingOrg:
      return GOVWIN_VIEW_SORT_BY_BUYING_ORG
    case GOVWIN_SAVED_SEARCH_FIELDS.sorts.soliciationDate:
      return GOVWIN_VIEW_SORT_BY_SOLICITATION_DATE
    case GOVWIN_SAVED_SEARCH_FIELDS.sorts.value:
      return GOVWIN_VIEW_SORT_BY_VALUE
    case GOVWIN_SAVED_SEARCH_FIELDS.sorts.requirements:
      return GOVWIN_VIEW_SORT_BY_REQUIREMENT
    case GOVWIN_SAVED_SEARCH_FIELDS.sorts.createdDate:
      return GOVWIN_VIEW_SORT_BY_CREATED_DATE
    case GOVWIN_SAVED_SEARCH_FIELDS.sorts.updatedDate:
      return GOVWIN_VIEW_SORT_BY_UPDATED_DATE
    default:
      return null
  }
}

export function convertGovWinViewToSavedSearch(view: GovWinViewPersisted): SavedSearch2.SavedSearch {
  const filters: Array<SavedSearch2.Filter> = []
  const sorts: Array<SavedSearch2.Sort> = []
  const options: Array<SavedSearch2.Option> = []

  serializeCommonDropdownViewProperty(
    filters,
    view,
    'market', //
    GOVWIN_SAVED_SEARCH_FIELDS.filters.market
  )

  if (view.oppUpdatedDate) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.oppUpdatedDate,
      GOVWIN_SAVED_SEARCH_FIELDS.filters.oppUpdatedDate
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.responseDate) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.responseDate,
      GOVWIN_SAVED_SEARCH_FIELDS.filters.responseDate
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.solDate) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.solDate,
      GOVWIN_SAVED_SEARCH_FIELDS.filters.solDate
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.queryString) {
    filters.push({
      type: 'QueryFilter',
      field: 'query',
      display: view.queryString,
      value: view.queryString,
    })
  }

  if (view.reviewStatus) {
    filters.push({
      type: 'MatchAllFilter',
      field: GOVWIN_SAVED_SEARCH_FIELDS.filters.reviewStatus,
      display: [view.reviewStatus.label],
      values: view.reviewStatus.value === 'all-opps' ? ['all-opps'] : view.reviewStatus.value,
    })
  }

  if (view.sortBy) {
    const convertToBEUnderstood = serializeGovWinViewSortByValue(view.sortBy.value)
    if (convertToBEUnderstood) {
      sorts.push({
        type: 'Sort',
        display: '',
        overrideMode: null,
        field: convertToBEUnderstood,
        order: view.sortByDirection === 'asc' ? 'asc' : 'desc',
      })
    }
  }

  return {
    filters: filters,
    sorts: sorts,
    options: options,
    searchTarget: SearchTargets.govwin.key,
  }
}

export function convertSavedSearchToGovWinView(savedSearch: SavedSearch2.SavedSearch): GovWinViewPersisted {
  const view: GovWinViewPersisted = _.cloneDeep(GOVWIN_VIEW_EMPTY_STATE)

  for (const filter of savedSearch.filters) {
    switch (filter.field) {
      case GOVWIN_SAVED_SEARCH_FIELDS.filters.query: {
        if (filter.type === 'QueryFilter') {
          view.queryString = filter.value
        } else {
          console.error('Unexpected filter type for query')
        }
        break
      }
      case GOVWIN_SAVED_SEARCH_FIELDS.filters.market: {
        if (filter.type === 'MatchFilter') {
          view.market = getDropdownValueFromFilterValue(filter)
        } else {
          console.error('Unexpected filter type for market')
        }
        break
      }
      case GOVWIN_SAVED_SEARCH_FIELDS.filters.solDate: {
        if (filter.type === 'DateFilter') {
          view.solDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for market')
        }
        break
      }
      case GOVWIN_SAVED_SEARCH_FIELDS.filters.responseDate: {
        if (filter.type === 'DateFilter') {
          view.responseDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for market')
        }
        break
      }
      case GOVWIN_SAVED_SEARCH_FIELDS.filters.oppUpdatedDate: {
        if (filter.type === 'DateFilter') {
          view.oppUpdatedDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for market')
        }
        break
      }
      case GOVWIN_SAVED_SEARCH_FIELDS.filters.reviewStatus: {
        if (filter.type === 'MatchAllFilter') {
          view.reviewStatus = getReviewStatusDropdownValueFromFilterValue(filter)
        } else {
          console.error('Unexpected filter type for reviewStatus')
        }
        break
      }

      default:
        console.error('Unexpected filter field: ' + filter.field)
        break
    }
  }

  for (const sort of savedSearch.sorts) {
    if (sort.type === 'Sort') {
      const dropdownValueValue = deserializeGovWinViewSortByValue(sort.field)
      if (dropdownValueValue) {
        view.sortBy = {
          id: dropdownValueValue,
          value: dropdownValueValue,
          label: sort.display,
        }
        view.sortByDirection = sort.order
      }

      // We only support 1 sort at the moment.
      break
    } else {
      console.error('Unexpected sort type: ' + sort.type)
    }
  }

  for (const option of savedSearch.options) {
    switch (option.field) {
      default:
        console.error('Unexpected option field: ' + option.field)
        break
    }
  }

  return view
}
