import { useCallback } from 'react'
import styles from './AscDescToggle.module.scss'

interface AscDescToggleProps {
  value: 'asc' | 'desc'

  onChange: (value: 'asc' | 'desc') => void

  className?: string
}

export const AscDescToggle = (props: AscDescToggleProps) => {
  const { value, onChange, className } = props

  const onClick = useCallback(() => {
    if (value === 'asc') {
      onChange('desc')
    } else {
      onChange('asc')
    }
  }, [onChange, value])

  return (
    <span className={styles.sortDirection + ' ' + className} onClick={onClick}>
      {value === 'asc' ? 'Asc' : 'Desc'}
    </span>
  )
}
