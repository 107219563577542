import { BookmarkReason } from '@interfaces/graphql'
import { SavedSearch2, SearchTargets, convertQueryParamsToSavedSearch } from '@nextweb/common'
import { DateFilterValue } from '../../components/CommonDateFilter/CommonDateFilter'
import { getSavedSearchFromQueryParams } from '../../redux/saved-search-query-params'
import { firstString } from '../../redux/utils'
import { SEARCH_VIEW_QUERY_PARAM_KEY } from '../../url-management/search-view-query-param-key'
import { convertSavedSearchToAwardView } from './saved-search/convert-award-view-and-saved-search'

/**
 * These don't have to be the same but it is convenient if they are.
 */
export const AWARD_VIEW_KEY = SearchTargets.award.label

export function isAwardViewKey(s?: any): boolean {
  s = Array.isArray(s) ? firstString(s) : s
  return typeof s === 'string' && s.toUpperCase() === AWARD_VIEW_KEY.toUpperCase()
}

// All serializable/saveable state should go into this.
export interface AwardViewPersisted {
  // Some common stuff, not sure how to colocate this stuff yet.
  // SavedSearch dropdown is not itself persisted.
  // The SavedSearch dropdown is still populated by query, etc.
  // savedSearch: { id: string; value: string; label: string } | null
  advanced: boolean | null
  reviewStatus: {
    id: string
    value: Array<BookmarkReason> | 'all-opps'
    label: string
  } | null
  sortBy: { id: string; value: string; label: string } | null
  sortByDirection: 'asc' | 'desc' | null

  queryString: string

  awardType: Array<{ id: string; value: string; label: string }> | null
  contractVehicle: Array<{ id: string; value: string; label: string }> | null
  naics: Array<{ id: string; value: string; label: string }> | null
  psc: Array<{ id: string; value: string; label: string }> | null

  fundingAgency: Array<{ id: string; value: string; label: string }> | null
  fundingSubAgency: Array<{ id: string; value: string; label: string }> | null
  awardingAgency: Array<{ id: string; value: string; label: string }> | null
  awardingSubAgency: Array<{ id: string; value: string; label: string }> | null

  placeOfPerformance: Array<{ id: string; value: string; label: string }> | null
  setaside: Array<{ id: string; value: string; label: string }> | null
  pricingType: Array<{ id: string; value: string; label: string }> | null

  endDate: DateFilterValue | null
  potentialEndDate: DateFilterValue | null
  awardDate: DateFilterValue | null
}

export interface AwardViewState {
  type: typeof AWARD_VIEW_KEY

  // All serializable/saveable state should go into this.
  persisted: AwardViewPersisted

  savedSearch: { id: string; value: string; label: string } | null

  selectedListing: any
  pageFrom: number
  hitCount: number | null
}

export const AWARD_VIEW_DEFAULT_STATE: AwardViewPersisted = {
  advanced: true,
  reviewStatus: null,
  sortBy: null,
  sortByDirection: null,

  // "The" query string.
  queryString: '',

  awardType: null,
  contractVehicle: null,
  naics: null,
  psc: null,

  fundingAgency: null,
  fundingSubAgency: null,
  awardingAgency: null,
  awardingSubAgency: null,

  placeOfPerformance: null,
  setaside: null,
  pricingType: null,

  endDate: null,
  potentialEndDate: null,
  awardDate: null,
}

export const AWARD_VIEW_EMPTY_STATE: AwardViewPersisted = {
  advanced: null,
  reviewStatus: null,
  sortBy: null,
  sortByDirection: null,

  // "The" query string.
  queryString: '',

  awardType: null,
  contractVehicle: null,
  naics: null,
  psc: null,

  fundingAgency: null,
  fundingSubAgency: null,
  awardingAgency: null,
  awardingSubAgency: null,

  placeOfPerformance: null,
  setaside: null,
  pricingType: null,

  endDate: null,
  potentialEndDate: null,
  awardDate: null,
}

/**
 * This may return null if the query fields are really malformed.
 * @param query
 */
export function createAwardViewFromQuery(query: Record<string, string | string[]>): AwardViewState | null {
  // This should be checked before calling this function ideally.
  if (!isAwardViewKey(query[SEARCH_VIEW_QUERY_PARAM_KEY])) return null

  const savedSearch = convertQueryParamsToSavedSearch({
    params: query,
  })

  return {
    type: AWARD_VIEW_KEY,
    selectedListing: null,
    pageFrom: 0,
    hitCount: null,
    savedSearch: getSavedSearchFromQueryParams({ params: query }),
    persisted:
      savedSearch && !SavedSearch2.isEmptySavedSearch(savedSearch)
        ? convertSavedSearchToAwardView(savedSearch)
        : AWARD_VIEW_DEFAULT_STATE,
  }
}
