import { ModalV2Portal } from '@components/ModalV2/ModalV2Portal'
import { scls } from '@helpers/scls'
import React, { useEffect } from 'react'
import styles from './FlyInModalPortal.module.scss'
import { useLockScroll } from '@components/ModalV2/use-lock-scroll'

/**
 * When value changes,
 *
 * false => true first second
 * true => false second first
 *
 * I.e. First is guaranteed to be true longer than second.
 *
 * If there is an animation, delayMs should be the animation time.
 * @param value
 * @param args
 * @returns
 */
function useBooleanInboundHysteresisStack(
  value: boolean,
  args?: {
    delayMs?: number
  }
): { first: boolean; second: boolean } {
  const { delayMs = 160 } = args ?? {}
  const [active, setActive] = React.useState(value)
  useEffect(() => {
    if (value !== active) {
      const tm = setTimeout(() => {
        setActive(value)
      }, delayMs)

      return () => {
        clearTimeout(tm)
      }
    }
  }, [value])

  if (value) {
    return {
      first: true,
      second: active,
    }
  } else {
    return {
      first: active,
      second: false,
    }
  }
}

interface FlyInModalPortalProps {
  visible: boolean
  children: React.ReactNode

  // For mobile.
  fullscreen?: boolean

  // Dim background
  dim?: boolean

  // Called if user clicked off modal contents (i.e. the children)
  onClickOff?: () => void

  Portal?: React.ComponentType<{ children: React.ReactNode }>
}

/**
 * Defaults to flying in at the nearest ModalV2Portal.
 * Note this modal does not size to parent - it sizes to children.
 *
 * @param props
 * @returns
 */
export const FlyInModalPortal = (props: FlyInModalPortalProps) => {
  const { visible, children, fullscreen, dim, onClickOff, Portal = ModalV2Portal } = props

  useLockScroll(visible)

  const { first: renderChildren, second: visibleCss } = useBooleanInboundHysteresisStack(visible, {
    delayMs: 160,
  })

  return (
    <Portal>
      {renderChildren && (
        <div
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onMouseDown={() => {
            onClickOff?.()
          }}
          className={scls(
            styles,
            'container',
            visibleCss && 'visible',
            dim && 'dim',
            fullscreen && 'fullscreen-container'
          )}
        >
          <div
            className={scls(styles, 'fly-in-modal', fullscreen && 'fullscreen')}
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        </div>
      )}
    </Portal>
  )
}
