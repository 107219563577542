import { SNACKBAR_EVENT_TYPE, SnackbarEvent } from '@src/apollo/snackbar/SnackbarEvent'
import { useEffect } from 'react'
import { toast } from 'react-toastify'

interface SnackbarToastProps {
  message: string
}
export const SnackbarToast = (props: SnackbarToastProps) => {
  const { message } = props

  return (
    <div>
      <div className="red">
        <b>Attention!</b>
      </div>
      <span>{message}</span>
    </div>
  )
}

export function useSnackbarEvents() {
  useEffect(() => {
    function onSnackbarEventListener(e: Event) {
      if (e instanceof SnackbarEvent) {
        toast(<SnackbarToast message={e.message} />, {
          position: 'bottom-center',
          autoClose: 10000,
          hideProgressBar: true,
        })
      }
    }
    if (typeof window !== 'undefined') {
      window.addEventListener(SNACKBAR_EVENT_TYPE, onSnackbarEventListener)

      return () => {
        window.removeEventListener(SNACKBAR_EVENT_TYPE, onSnackbarEventListener)
      }
    }
  }, [])
}
