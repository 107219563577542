import { DateFilterValue } from '@src/partials/searchv2/components/CommonDateFilter/CommonDateFilter'
import { useSelector } from '@src/redux/use-selector'
import { SearchPageCIOSPSliceTypeReady, isSearchPageCIOSPSliceReady } from './SearchPageCIOSPSlice.types'

export function useCIOSPSelector<T extends (state: SearchPageCIOSPSliceTypeReady) => any>(
  selector: T
): null | ReturnType<T> {
  return useSelector((state) => {
    if (isSearchPageCIOSPSliceReady(state.searchCIOSPView)) return selector(state.searchCIOSPView)
    else return null
  })
}

export function useCIOSPSelectionDeadlineDateFilter(): DateFilterValue | null {
  return useCIOSPSelector((state) => {
    return state.view.persisted.selectionDeadlineDate
  })
}

export function useCIOSPSubmissionDeadlineDateFilter(): DateFilterValue | null {
  return useCIOSPSelector((state) => {
    return state.view.persisted.submissionDeadlineDate
  })
}

export function useCIOSPPostedOnDateFilter(): DateFilterValue | null {
  return useCIOSPSelector((state) => {
    return state.view.persisted.postedOnDate
  })
}

export function useCIOSPSortBy(): {
  id: string
  value: string
  label: string
} | null {
  return useCIOSPSelector((state) => {
    return state.view.persisted.sortBy
  })
}

export function useCIOSPSortByDirection(): 'asc' | 'desc' | null {
  return useCIOSPSelector((state) => {
    return state.view.persisted.sortByDirection
  })
}

export function useCIOSPReviewStatus(): {
  id: string
  value: any
  label: string
} | null {
  return useCIOSPSelector((state) => {
    return state.view.persisted.reviewStatus
  })
}

export function useCIOSPAdvanced(): boolean | null {
  return useCIOSPSelector((state) => {
    return state.view.persisted.advanced
  })
}

export function useCIOSPQuery(): string {
  const value = useCIOSPSelector((state) => {
    return state.view.persisted.queryString
  })

  return value ?? ''
}

export function useCIOSPActiveSavedSearch(): {
  id: string
  value: string
  label: string
} | null {
  return useCIOSPSelector((state) => {
    return state.view.savedSearch
  })
}

export function useCIOSPSelectedListing(): any {
  return useCIOSPSelector((state) => {
    return state.view.selectedListing
  })
}

export function useCIOSPPageFrom(): number | null {
  return useCIOSPSelector((state) => {
    return state.view.pageFrom
  })
}

export function useCIOSPHitCount(): number | null {
  return useCIOSPSelector((state) => {
    return state.view.hitCount
  })
}

export const CIOSP_PAGE_SIZE = 40
