import { useSelector } from '@src/redux/use-selector'
import {
  SearchPageContractVehicleSliceTypeReady,
  isSearchPageContractVehicleSliceReady,
} from './SearchPageContractVehicleSlice.types'

export function useContractVehicleSelector<T extends (state: SearchPageContractVehicleSliceTypeReady) => any>(
  selector: T
): null | ReturnType<T> {
  return useSelector((state) => {
    if (isSearchPageContractVehicleSliceReady(state.searchContractVehicleView))
      return selector(state.searchContractVehicleView)
    else return null
  })
}

export function useContractVehicleAdvanced(): boolean | null {
  return useContractVehicleSelector((state) => {
    return state.view.persisted.advanced
  })
}

export function useContractVehicleQuery(): string {
  const value = useContractVehicleSelector((state) => {
    return state.view.persisted.queryString
  })

  return value ?? ''
}

export function useContractVehicleActiveSavedSearch(): {
  id: string
  value: string
  label: string
} | null {
  return useContractVehicleSelector((state) => {
    return state.view.savedSearch
  })
}

export function useContractVehicleSelectedListing(): any {
  return useContractVehicleSelector((state) => {
    return state.view.selectedListing
  })
}

export function useContractVehiclePageFrom(): number | null {
  return useContractVehicleSelector((state) => {
    return state.view.pageFrom
  })
}

export function useContractVehicleHitCount(): number | null {
  return useContractVehicleSelector((state) => {
    return state.view.hitCount
  })
}

export function useContractVehicleSortBy(): {
  id: string
  value: string
  label: string
} | null {
  return useContractVehicleSelector((state) => {
    return state.view.persisted.sortBy
  })
}

export function useContractVehicleSortByDirection(): 'asc' | 'desc' | null {
  return useContractVehicleSelector((state) => {
    return state.view.persisted.sortByDirection
  })
}

export const CONTRACT_VEHICLE_PAGE_SIZE = 40
