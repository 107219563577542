// External
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface OppSummarySliceState {
  templateUpdateLoading: boolean
}

const initState: OppSummarySliceState = {
  templateUpdateLoading: false,
}

export const OppSummarySlice = createSlice({
  name: 'OppSummarySlice',
  initialState: initState,
  reducers: {
    setTemplateUpdateLoading(state, action: PayloadAction<{ loading: boolean }>) {
      let { loading } = action.payload
      state.templateUpdateLoading = loading
    },
  },
})
