import { useRouter } from 'next/router'
import { useRef } from 'react'
import { toast } from 'react-toastify'
import { useInterval } from '../ForceRefresh'
import { PUBLIC_ROUTES } from './public-routes'

export function useBuildVersionInterval() {
  const router = useRouter()
  const showUpdateApp = useRef(false)

  return useInterval(
    async () => {
      try {
        const response = await fetch('/api/build-id')
        const { buildId } = await response.json()

        const route = router.pathname.split('/')[1]
        const isPublicRoute = PUBLIC_ROUTES.includes(route)

        if (
          buildId &&
          process.env.BUILD_ID &&
          buildId !== process.env.BUILD_ID &&
          !isPublicRoute &&
          !showUpdateApp.current
        ) {
          toast(
            <div className="flexBoxRow--center" style={{ fontSize: '14px' }}>
              <span>New version available</span>
              <span
                className="link"
                style={{ marginLeft: 'auto', marginRight: '0.5rem' }}
                onClick={() => window.location.reload()}
              >
                Refresh
              </span>
            </div>,
            {
              position: 'top-center',
              autoClose: false,
              onClose: () => {
                showUpdateApp.current = !showUpdateApp.current
              },
              hideProgressBar: true,
              style: {
                padding: '2px',
                paddingLeft: '8px',
                minHeight: '2rem',
                width: '16rem',
                margin: '0.1rem auto',
              },
            }
          )
          showUpdateApp.current = true
        }
      } catch (e) {}
    },
    { interval: 30000 }
  )
}
