import { AWARD_SAVED_SEARCH_FIELDS, SavedSearch2, SearchTargets } from '@nextweb/common'
import {
  getDropdownValuesFromFilterValues,
  getReviewStatusDropdownValueFromFilterValue,
  serializeCommonMultiDropdownViewProperty,
} from '@src/partials/searchv2/saved-search/common-conversions'
import {
  convertCommonDateFilterValueToSavedSearchValue,
  convertSavedSearchValueToCommonDateFilterValue,
} from '@src/partials/searchv2/saved-search/convert-common-date-filter-value-to-saved-search-value'
import _ from 'lodash'
import { AWARD_VIEW_EMPTY_STATE, AwardViewPersisted } from '../award-view-state'
import {
  AWARD_VIEW_SORT_BY_CURRENT_VALUE,
  AWARD_VIEW_SORT_BY_PERFORMANCE_END,
  AWARD_VIEW_SORT_BY_PERFORMANCE_START,
  AWARD_VIEW_SORT_BY_POTENTIAL_VALUE,
  AWARD_VIEW_SORT_BY_SORT_BY_LAST_MODIFIED,
} from '../components/AwardViewSortByDropdown'

/**
 * Convert the SAM View Dropdown value into the value expected by the backend.
 * @param dropdownValue
 * @returns
 */
function serializeAwardViewSortByValue(dropdownValue: string) {
  switch (dropdownValue) {
    case AWARD_VIEW_SORT_BY_CURRENT_VALUE:
      return AWARD_SAVED_SEARCH_FIELDS.sorts.currentValue
    case AWARD_VIEW_SORT_BY_POTENTIAL_VALUE:
      return AWARD_SAVED_SEARCH_FIELDS.sorts.potentialValue
    case AWARD_VIEW_SORT_BY_PERFORMANCE_END:
      return AWARD_SAVED_SEARCH_FIELDS.sorts.periodOfPerformanceEndDate
    case AWARD_VIEW_SORT_BY_PERFORMANCE_START:
      return AWARD_SAVED_SEARCH_FIELDS.sorts.periodOfPerformanceStartDate
    case AWARD_VIEW_SORT_BY_SORT_BY_LAST_MODIFIED:
      return AWARD_SAVED_SEARCH_FIELDS.sorts.lastModified
    default:
      return null
  }
}

function deserializeAwardViewSortByValue(dropdownValue: string) {
  switch (dropdownValue) {
    case AWARD_SAVED_SEARCH_FIELDS.sorts.currentValue:
      return AWARD_VIEW_SORT_BY_CURRENT_VALUE
    case AWARD_SAVED_SEARCH_FIELDS.sorts.potentialValue:
      return AWARD_VIEW_SORT_BY_POTENTIAL_VALUE
    case AWARD_SAVED_SEARCH_FIELDS.sorts.periodOfPerformanceEndDate:
      return AWARD_VIEW_SORT_BY_PERFORMANCE_END
    case AWARD_SAVED_SEARCH_FIELDS.sorts.periodOfPerformanceStartDate:
      return AWARD_VIEW_SORT_BY_PERFORMANCE_START
    case AWARD_SAVED_SEARCH_FIELDS.sorts.lastModified:
      return AWARD_VIEW_SORT_BY_SORT_BY_LAST_MODIFIED
    default:
      return null
  }
}

export function convertAwardViewToSavedSearch(view: AwardViewPersisted): SavedSearch2.SavedSearch {
  const filters: Array<SavedSearch2.Filter> = []
  const sorts: Array<SavedSearch2.Sort> = []
  const options: Array<SavedSearch2.Option> = []

  // Note! The key in the state happens to coincide with the "Field" value,
  // but make no mistake, they are not the same, you can store the value
  // in any field you want on the client side, but the "Field" value must match
  // a SAM field on the BE.
  serializeCommonMultiDropdownViewProperty(filters, view, 'awardType', AWARD_SAVED_SEARCH_FIELDS.filters.awardType)
  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'contractVehicle', //
    AWARD_SAVED_SEARCH_FIELDS.filters.contractVehicle
  )
  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'naics', //
    AWARD_SAVED_SEARCH_FIELDS.filters.naics
  )
  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'psc', //
    AWARD_SAVED_SEARCH_FIELDS.filters.psc
  )

  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'awardingAgency', //
    AWARD_SAVED_SEARCH_FIELDS.filters.awardingAgency
  )
  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'awardingSubAgency', //
    AWARD_SAVED_SEARCH_FIELDS.filters.awardingSubAgency
  )
  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'fundingAgency', //
    AWARD_SAVED_SEARCH_FIELDS.filters.fundingAgency
  )
  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'fundingSubAgency', //
    AWARD_SAVED_SEARCH_FIELDS.filters.fundingSubAgency
  )

  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'placeOfPerformance',
    AWARD_SAVED_SEARCH_FIELDS.filters.placeOfPerformance
  )
  serializeCommonMultiDropdownViewProperty(filters, view, 'setaside', AWARD_SAVED_SEARCH_FIELDS.filters.setaside)
  serializeCommonMultiDropdownViewProperty(filters, view, 'pricingType', AWARD_SAVED_SEARCH_FIELDS.filters.pricingType)

  if (view.endDate) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.endDate,
      AWARD_SAVED_SEARCH_FIELDS.filters.endDate
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.potentialEndDate) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.potentialEndDate,
      AWARD_SAVED_SEARCH_FIELDS.filters.potentialEndDate
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.awardDate) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.awardDate,
      AWARD_SAVED_SEARCH_FIELDS.filters.awardDate
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.queryString) {
    filters.push({
      type: 'QueryFilter',
      field: 'query',
      display: view.queryString,
      value: view.queryString,
    })
  }

  if (view.reviewStatus) {
    filters.push({
      type: 'MatchAllFilter',
      field: AWARD_SAVED_SEARCH_FIELDS.filters.reviewStatus,
      display: [view.reviewStatus.label],
      values: view.reviewStatus.value === 'all-opps' ? ['all-opps'] : view.reviewStatus.value,
    })
  }

  if (view.sortBy) {
    const convertToBEUnderstood = serializeAwardViewSortByValue(view.sortBy.value)
    if (convertToBEUnderstood) {
      sorts.push({
        type: 'Sort',
        display: '',
        overrideMode: null,
        field: convertToBEUnderstood,
        order: view.sortByDirection === 'asc' ? 'asc' : 'desc',
      })
    }
  }

  if (view.advanced !== null) {
    options.push({
      type: 'FlagOption',
      field: AWARD_SAVED_SEARCH_FIELDS.options.advanced,
      value: view.advanced,
    })
  }

  return {
    filters: filters,
    sorts: sorts,
    options: options,
    searchTarget: SearchTargets.award.key,
  }
}

export function convertSavedSearchToAwardView(savedSearch: SavedSearch2.SavedSearch): AwardViewPersisted {
  const view: AwardViewPersisted = _.cloneDeep(AWARD_VIEW_EMPTY_STATE)

  for (const filter of savedSearch.filters) {
    switch (filter.field) {
      case AWARD_SAVED_SEARCH_FIELDS.filters.query: {
        if (filter.type === 'QueryFilter') {
          view.queryString = filter.value
        } else {
          console.error('Unexpected filter type for query')
        }
        break
      }
      case AWARD_SAVED_SEARCH_FIELDS.filters.reviewStatus: {
        if (filter.type === 'MatchAllFilter') {
          view.reviewStatus = getReviewStatusDropdownValueFromFilterValue(filter)
        } else {
          console.error('Unexpected filter type for reviewStatus')
        }
        break
      }
      case AWARD_SAVED_SEARCH_FIELDS.filters.awardType: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.awardType = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for awardType')
        }
        break
      }
      case AWARD_SAVED_SEARCH_FIELDS.filters.contractVehicle: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.contractVehicle = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for contractVehicle')
        }
        break
      }
      case AWARD_SAVED_SEARCH_FIELDS.filters.naics: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.naics = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for naics')
        }
        break
      }
      case AWARD_SAVED_SEARCH_FIELDS.filters.psc: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.psc = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for psc')
        }
        break
      }
      case AWARD_SAVED_SEARCH_FIELDS.filters.fundingAgency: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.fundingAgency = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for fundingAgency')
        }
        break
      }
      case AWARD_SAVED_SEARCH_FIELDS.filters.fundingSubAgency: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.fundingSubAgency = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for fundingSubAgency')
        }
        break
      }
      case AWARD_SAVED_SEARCH_FIELDS.filters.awardingAgency: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.awardingAgency = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for awardingAgency')
        }
        break
      }
      case AWARD_SAVED_SEARCH_FIELDS.filters.awardingSubAgency: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.awardingSubAgency = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for awardingSubAgency')
        }
        break
      }

      case AWARD_SAVED_SEARCH_FIELDS.filters.placeOfPerformance: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.placeOfPerformance = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for placeOfPerformance')
        }
        break
      }
      case AWARD_SAVED_SEARCH_FIELDS.filters.setaside: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.setaside = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for setaside')
        }
        break
      }
      case AWARD_SAVED_SEARCH_FIELDS.filters.pricingType: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.pricingType = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for pricingType')
        }
        break
      }
      case AWARD_SAVED_SEARCH_FIELDS.filters.endDate: {
        if (filter.type === 'DateFilter') {
          view.endDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for endDate')
        }
        break
      }
      case AWARD_SAVED_SEARCH_FIELDS.filters.potentialEndDate: {
        if (filter.type === 'DateFilter') {
          view.potentialEndDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for potentialEndDate')
        }
        break
      }
      case AWARD_SAVED_SEARCH_FIELDS.filters.awardDate: {
        if (filter.type === 'DateFilter') {
          view.awardDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for awardDate')
        }
        break
      }
      default:
        console.error('Unexpected filter field: ' + filter.field)
        break
    }
  }

  for (const sort of savedSearch.sorts) {
    if (sort.type === 'Sort') {
      const dropdownValueValue = deserializeAwardViewSortByValue(sort.field)
      if (dropdownValueValue) {
        view.sortBy = {
          id: dropdownValueValue,
          value: dropdownValueValue,
          label: sort.display,
        }
        view.sortByDirection = sort.order
      }

      // We only support 1 sort at the moment.
      break
    } else {
      console.error('Unexpected sort type: ' + sort.type)
    }
  }

  for (const option of savedSearch.options) {
    switch (option.field) {
      case AWARD_SAVED_SEARCH_FIELDS.options.advanced: {
        if (option.type === 'FlagOption') {
          view.advanced = option.value
        } else {
          console.error('Unexpected option type for advanced')
        }
        break
      }
      default:
        console.error('Unexpected option field: ' + option.field)
        break
    }
  }

  return view
}
