import { DateFilterValue } from '@src/partials/searchv2/components/CommonDateFilter/CommonDateFilter'
import { useSelector } from '@src/redux/use-selector'
import { SearchPageAwardSliceTypeReady, isSearchPageAwardSliceReady } from './SearchPageAwardSlice.types'

export function useAwardSelector<T extends (state: SearchPageAwardSliceTypeReady) => any>(
  selector: T
): null | ReturnType<T> {
  return useSelector((state) => {
    if (isSearchPageAwardSliceReady(state.searchAwardView)) return selector(state.searchAwardView)
    else return null
  })
}

export function useAwardNAICSFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useAwardSelector((state) => {
    return state.view.persisted.naics
  })
}

export function useAwardContractVehicleFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useAwardSelector((state) => {
    return state.view.persisted.contractVehicle
  })
}

export function useAwardTypeFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useAwardSelector((state) => {
    return state.view.persisted.awardType
  })
}

export function useAwardPSCFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useAwardSelector((state) => {
    return state.view.persisted.psc
  })
}

export function useAwardPricingTypeFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useAwardSelector((state) => {
    return state.view.persisted.pricingType
  })
}

export function useAwardAwardingAgencyFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useAwardSelector((state) => {
    return state.view.persisted.awardingAgency
  })
}

export function useAwardAwardingSubAgencyFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useAwardSelector((state) => {
    return state.view.persisted.awardingSubAgency
  })
}

export function useAwardFundingAgencyFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useAwardSelector((state) => {
    return state.view.persisted.fundingAgency
  })
}

export function useAwardFundingSubAgencyFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useAwardSelector((state) => {
    return state.view.persisted.fundingSubAgency
  })
}

export function useAwardPlaceOfPerformanceFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useAwardSelector((state) => {
    return state.view.persisted.placeOfPerformance
  })
}

export function useAwardAwardTypeFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useAwardSelector((state) => {
    return state.view.persisted.awardType
  })
}

export function useAwardSetAsideFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useAwardSelector((state) => {
    return state.view.persisted.setaside
  })
}

export function useAwardCurrentEndDateFilter(): DateFilterValue | null {
  return useAwardSelector((state) => {
    return state.view.persisted.endDate
  })
}

export function useAwardPotentialEndDateFilter(): DateFilterValue | null {
  return useAwardSelector((state) => {
    return state.view.persisted.potentialEndDate
  })
}

export function useAwardAwardDateFilter(): DateFilterValue | null {
  return useAwardSelector((state) => {
    return state.view.persisted.awardDate
  })
}

export function useAwardSortBy(): {
  id: string
  value: string
  label: string
} | null {
  return useAwardSelector((state) => {
    return state.view.persisted.sortBy
  })
}

export function useAwardSortByDirection(): 'asc' | 'desc' | null {
  return useAwardSelector((state) => {
    return state.view.persisted.sortByDirection
  })
}

export function useAwardReviewStatus(): {
  id: string
  value: any
  label: string
} | null {
  return useAwardSelector((state) => {
    return state.view.persisted.reviewStatus
  })
}

export function useAwardAdvanced(): boolean | null {
  return useAwardSelector((state) => {
    return state.view.persisted.advanced
  })
}

export function useAwardQuery(): string {
  const value = useAwardSelector((state) => {
    return state.view.persisted.queryString
  })

  return value ?? ''
}

export function useAwardActiveSavedSearch(): {
  id: string
  value: string
  label: string
} | null {
  return useAwardSelector((state) => {
    return state.view.savedSearch
  })
}

export function useAwardSelectedListing(): any {
  return useAwardSelector((state) => {
    return state.view.selectedListing
  })
}

export function useAwardPageFrom(): number | null {
  return useAwardSelector((state) => {
    return state.view.pageFrom
  })
}

export function useAwardHitCount(): number | null {
  return useAwardSelector((state) => {
    return state.view.hitCount
  })
}

export const AWARD_PAGE_SIZE = 40
