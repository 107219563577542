import { GetMeQuery, MeFragment, useGetMeQuery } from '@interfaces/graphql'
import { useDispatch } from 'react-redux'
import Cohere from 'cohere-js'
import { setPermissions } from '@src/redux/reducers/PermissionsSlice'
import { MeType, UserResourcePermissionV2, isUserSliceReady, resetUser, setUser } from '@src/redux/reducers/UserSlice'
import { clearFromAccountStore, getFromAccountStore, saveToAccountStore } from '@src/indexeddb/openNextStageDatabase'
import { useContext, useEffect, useState } from 'react'
import { NextStageDbContext } from '@src/indexeddb/NextStageDbContext'
import * as Sentry from '@sentry/nextjs'
import { useSelector } from '@src/redux/use-selector'

export function getMeTypeFromGraphQLUserType(user: {
  id: number
  userId: string
  firstName: string
  lastName: string
  TOBEREMOVED_pipelinesWithUserManagementEnabled: Array<string>
  TOBEREMOVED_superDuperCanEditPipelineUserAccessEvenWithoutPipelineAdminAccess: boolean
  permissions: Array<UserResourcePermissionV2>
  defaultPipeline?: { pipelineId: string; id: number } | null | undefined
  defaultPipelineView?: { id: string; name: string } | null | undefined
  defaultTimelineView?: { timelineViewUUId: string } | null | undefined
  workspace: { freeTrial: boolean; isPayingCustomer: boolean }
  isVerifiedEmail: boolean
  isActive: boolean
}): MeType {
  return {
    id: user.id,
    userId: user.userId,
    firstName: user.firstName,
    lastName: user.lastName,
    permissions: user.permissions,
    TOBEREMOVED_pipelinesWithUserManagementEnabled: user.TOBEREMOVED_pipelinesWithUserManagementEnabled,
    TOBEREMOVED_superDuperCanEditPipelineUserAccessEvenWithoutPipelineAdminAccess:
      user.TOBEREMOVED_superDuperCanEditPipelineUserAccessEvenWithoutPipelineAdminAccess,
    defaultPipeline: user.defaultPipeline ?? null,
    defaultPipelineView: user.defaultPipelineView ?? null,
    defaultTimelineViewUUId: user.defaultTimelineView?.timelineViewUUId,
    defaultTimelineViewType: 'TimelineView',
    freeTrial: !!user.workspace.freeTrial,
    isPayingCustomer: !!user.workspace.isPayingCustomer,
    isVerifiedEmail: !!user.isVerifiedEmail,
    isActive: !!user.isActive,
  }
}

export function dispatchGetMeResult(
  dispatch: (any) => void,
  results?: MeFragment
): { perms: Array<{ id: number; scope: string; crudType: string }>; me: MeType } | null {
  if (!results) return null

  const id = results.id

  if (!id) return null

  const meAugmented: MeType = getMeTypeFromGraphQLUserType(results)

  dispatch(setUser(meAugmented))

  const perms = results.role?.permissions || []
  dispatch(setPermissions(perms))

  return {
    perms: perms,
    me: meAugmented,
  }
}

/**
 * TODO: This is a bit much; how to improve?
 */
export function useGetMe() {
  const dispatch = useDispatch()

  const nextstageDb = useContext(NextStageDbContext)

  const initialMe = useSelector((state) => state.user)

  const [me, setMe] = useState<MeType | null>(isUserSliceReady(initialMe) ? initialMe : null)

  const { loading, error, refetch } = useGetMeQuery({
    onError: async () => {
      console.error('Failed to get user. Clearing user information.')
      setMe(null)
      dispatch(resetUser())
      dispatch(setPermissions(false))

      if (nextstageDb) {
        await clearFromAccountStore(nextstageDb, 'me')
        await clearFromAccountStore(nextstageDb, 'perms')
      }
    },
    onCompleted: async (results: GetMeQuery) => {
      const id = results.getMe.id

      if (!id) return

      Sentry.setContext('user', results.getMe)

      const { me, perms } = dispatchGetMeResult(dispatch, results.getMe) ?? {}

      try {
        window.analytics.identify(id, {
          email: results?.getMe?.email,
          firstName: results?.getMe?.firstName,
          lastName: results?.getMe?.lastName,
        })

        Cohere.identify(results.getMe.userId, {
          displayName: `${results.getMe.firstName} ${results.getMe.lastName}`, // Optional
          email: results.getMe.email, // Optional
        })
      } catch (err) {
        console.error('window analytics error: ', err)
      }

      if (nextstageDb) {
        await saveToAccountStore(nextstageDb, 'me', {
          type: 'me',
          value: me,
        })

        await saveToAccountStore(nextstageDb, 'perms', {
          type: 'perms',
          value: perms,
        })
      }
    },
  })

  useEffect(() => {
    if (nextstageDb && !me) {
      Promise.all([getFromAccountStore(nextstageDb, 'me'), getFromAccountStore(nextstageDb, 'perms')]).then(
        ([cachedMe, cachedPerms]) => {
          if (!me) {
            if (cachedPerms && cachedPerms.type === 'perms') {
              const perms = cachedPerms.value
              dispatch(setPermissions(perms))
            }

            if (cachedMe && cachedMe.type === 'me') {
              setMe(cachedMe.value)
            }
          }
        }
      )
    }
  }, [nextstageDb])

  useEffect(() => {
    if (me) {
      dispatch(setUser(me))
    }
  }, [me])

  return {
    loading,
    error,
    data: me
      ? {
          getMe: me,
        }
      : undefined,
    refetch,
  }
}
