import { DateFilterValue } from '@src/partials/searchv2/components/CommonDateFilter/CommonDateFilter'
import { useSelector } from '@src/redux/use-selector'
import { SearchPageEBuySliceTypeReady, isSearchPageEBuySliceReady } from './SearchPageEBuySlice.types'

export function useEBuySelector<T extends (state: SearchPageEBuySliceTypeReady) => any>(
  selector: T
): null | ReturnType<T> {
  return useSelector((state) => {
    if (isSearchPageEBuySliceReady(state.searchEBuyView)) return selector(state.searchEBuyView)
    else return null
  })
}

export function useEBuySINFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useEBuySelector((state) => {
    return state.view.persisted.sins
  })
}

export function useEBuyAgencyFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useEBuySelector((state) => {
    return state.view.persisted.agency
  })
}
export function useEBuySubAgencyFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useEBuySelector((state) => {
    return state.view.persisted.subAgency
  })
}

export function useEBuyRespondByDateFilter(): DateFilterValue | null {
  return useEBuySelector((state) => {
    return state.view.persisted.respondByDate
  })
}

export function useEBuyPostedOnDateFilter(): DateFilterValue | null {
  return useEBuySelector((state) => {
    return state.view.persisted.postedOnDate
  })
}

export function useEBuySortBy(): {
  id: string
  value: string
  label: string
} | null {
  return useEBuySelector((state) => {
    return state.view.persisted.sortBy
  })
}

export function useEBuySortByDirection(): 'asc' | 'desc' | null {
  return useEBuySelector((state) => {
    return state.view.persisted.sortByDirection
  })
}

export function useEBuyReviewStatus(): {
  id: string
  value: any
  label: string
} | null {
  return useEBuySelector((state) => {
    return state.view.persisted.reviewStatus
  })
}

export function useEBuyAdvanced(): boolean | null {
  return useEBuySelector((state) => {
    return state.view.persisted.advanced
  })
}

export function useEBuyQuery(): string {
  const value = useEBuySelector((state) => {
    return state.view.persisted.queryString
  })

  return value ?? ''
}

export function useEBuyActiveSavedSearch(): {
  id: string
  value: string
  label: string
} | null {
  return useEBuySelector((state) => {
    return state.view.savedSearch
  })
}

export function useEBuySelectedListing(): any {
  return useEBuySelector((state) => {
    return state.view.selectedListing
  })
}

export function useEBuyPageFrom(): number | null {
  return useEBuySelector((state) => {
    return state.view.pageFrom
  })
}

export function useEBuyHitCount(): number | null {
  return useEBuySelector((state) => {
    return state.view.hitCount
  })
}

export const EBUY_PAGE_SIZE = 40
