import { SavedSearch2, SearchTargets, convertQueryParamsToSavedSearch } from '@nextweb/common'
import { getSavedSearchFromQueryParams } from '../../redux/saved-search-query-params'
import { firstString } from '../../redux/utils'
import { SEARCH_VIEW_QUERY_PARAM_KEY } from '../../url-management/search-view-query-param-key'
import {
  CONTRACT_VEHICLE_SORT_BY_OPTIONS,
  CONTRACT_VEHICLE_VIEW_SORT_BY_CURRENT_VALUE,
} from './components/ContractVehicleViewSortByDropdown'
import { convertSavedSearchToContractVehicleView } from './saved-search/convert-contract-vehicle-view-and-saved-search'

/**
 * These don't have to be the same but it is convenient if they are.
 */
export const CONTRACT_VEHCILE_VIEW_KEY = SearchTargets.contractVehicles.label

export function isContractVehicleViewKey(s?: any): boolean {
  s = Array.isArray(s) ? firstString(s) : s
  return typeof s === 'string' && s.toUpperCase() === CONTRACT_VEHCILE_VIEW_KEY.toUpperCase()
}

// All serializable/saveable state should go into this.
export interface ContractVehicleViewPersisted {
  // Some common stuff, not sure how to colocate this stuff yet.
  // SavedSearch dropdown is not itself persisted.
  // The SavedSearch dropdown is still populated by query, etc.
  // savedSearch: { id: string; value: string; label: string } | null
  advanced: boolean | null

  sortBy: { id: string; value: string; label: string } | null
  sortByDirection: 'asc' | 'desc' | null

  // "The" query string.
  queryString: string
}

export interface ContractVehicleViewState {
  type: typeof CONTRACT_VEHCILE_VIEW_KEY

  // All serializable/saveable state should go into this.
  persisted: ContractVehicleViewPersisted

  savedSearch: { id: string; value: string; label: string } | null

  selectedListing: any
  pageFrom: number
  hitCount: number | null
}

export const CONTRACT_VEHICLE_VIEW_DEFAULT_STATE: ContractVehicleViewPersisted = {
  advanced: true,
  sortBy: CONTRACT_VEHICLE_SORT_BY_OPTIONS.find(({ value }) => value === CONTRACT_VEHICLE_VIEW_SORT_BY_CURRENT_VALUE)!,
  sortByDirection: 'desc',

  // "The" query string.
  queryString: '',
}

export const CONTRACT_VEHICLE_VIEW_EMPTY_STATE: ContractVehicleViewPersisted = {
  advanced: null,
  sortBy: null,
  sortByDirection: null,

  // "The" query string.
  queryString: '',
}

/**
 * This may return null if the query fields are really malformed.
 * @param query
 */
export function createContractVehicleViewFromQuery(
  query: Record<string, string | string[]>
): ContractVehicleViewState | null {
  // This should be checked before calling this function ideally.
  if (!isContractVehicleViewKey(query[SEARCH_VIEW_QUERY_PARAM_KEY])) return null

  const savedSearch = convertQueryParamsToSavedSearch({
    params: query,
  })

  return {
    type: CONTRACT_VEHCILE_VIEW_KEY,
    selectedListing: null,
    pageFrom: 0,
    hitCount: null,
    savedSearch: getSavedSearchFromQueryParams({ params: query }),
    persisted:
      savedSearch && !SavedSearch2.isEmptySavedSearch(savedSearch)
        ? convertSavedSearchToContractVehicleView(savedSearch)
        : CONTRACT_VEHICLE_VIEW_DEFAULT_STATE,
  }
}
