import { classNames } from '@helpers/scls'
import styles from './Page.module.scss'

export interface PageHeaderProps extends React.HTMLAttributes<HTMLElement> {
  bright?: boolean

  p?: 'none' | 'sm' | 'md' | 'lg'
  px?: 'none' | 'sm' | 'md' | 'lg'
  py?: 'none' | 'sm' | 'md' | 'lg'
}

const PageHeader: React.FC<PageHeaderProps> = (props: PageHeaderProps) => {
  const { className: propsClassName, bright, p = 'md', px = p, py = p, children, ...forwarded } = props

  const className = classNames(
    styles.header,
    {
      [styles.bright]: bright,
      [styles.pxNone]: px === 'none',
      [styles.pxSm]: px === 'sm',
      [styles.pxMd]: px === 'md',
      [styles.pxLg]: px === 'lg',
      [styles.pyNone]: py === 'none',
      [styles.pySm]: py === 'sm',
      [styles.pyMd]: py === 'md',
      [styles.pyLg]: py === 'lg',
    },
    propsClassName
  )

  return (
    <div className={className} {...forwarded}>
      {children}
    </div>
  )
}

export default PageHeader
