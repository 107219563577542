import { isBoolean, isNil, isObject, isString } from 'lodash'

/** Record of class names, usually imported from the CSS module. */
export type StyleModuleMap = Record<string, string>

/** Class name param that is not an object and cannot be confused with the StyleModuleMap type. */
export type PrimitiveClassNameParam = string | null | undefined | boolean | number

export type ClassNameParam = PrimitiveClassNameParam | Record<string, PrimitiveClassNameParam>

/**
 * Mix between original `scls` function and the third-party `clsx`.
 *
 * If no style map is provided, the remaining arguments will be combined with a similar usage to `clsx`.
 *
 * If the style map is provided, all strings will be interpreted as keys in the style map.
 *
 * @param styles
 * @param classes
 * @returns
 */
const classNamesWithStyleMap = (styles: StyleModuleMap | null, ...classes: ClassNameParam[]): string => {
  /** Class name extracted from style map, if available */
  const trueClassName = (value: string) => {
    if (styles) return styles[value] ?? ''
    return value.trim()
  }

  return classes
    .map((value) => {
      if (isString(value)) return trueClassName(value)
      if (!value) return false
      return Object.entries(value)
        .filter(([className, shouldInclude]) => !!shouldInclude && !!className.trim())
        .map(([className]) => trueClassName(className))
        .join(' ')
    })
    .filter((className) => !!className)
    .join(' ')
    .trim()
}

/**
 * Joins strings in a convenient way for inline classes.
 * Ideal for conditionally using class names from a CSS module.
 *
 * Usage:
 *
 * ```
 * const styles = {
 *  anchor: 'anchor-1234',
 *  success:'success-5678',
 * }
 * const success = true;
 *
 * scls(styles, 'anchor', success && 'success') // => 'anchor-1234 success-5678'
 * ```
 *
 * or, to emit global styles:
 *
 * ```
 * scls('anchor', success && 'success') // => 'anchor success' if success is true, otherwise 'anchor'
 * ```
 *
 * @param styles
 * @param classes
 * @returns Combined and mapped class names
 */
export function scls(styles: StyleModuleMap | PrimitiveClassNameParam, ...classes: ClassNameParam[]): string {
  if (isNil(styles) || !isObject(styles)) {
    return classNamesWithStyleMap(null, styles, ...classes) + ' '
  }
  return classNamesWithStyleMap(styles, ...classes) + ' '
}

/**
 * Similar usage to `clsx` or `classnames`.
 *
 * Usage:
 *
 * ```
 * const styles = {
 *  anchor: 'anchor-1234',
 *  success:'success-5678',
 * }
 *
 * classNames({ anchor: true, success: false }) // => 'anchor'
 * classNames({ anchor: true, success: 52 }) // => 'anchor success'
 * classNames(styles.anchor, { [styles.success]: 52 }) // => 'anchor-1234 success-5678'
 * classNames(styles.anchor, { [styles.success]: 0 }) // => 'anchor-1234'
 * ```
 *
 * @param classes
 * @returns Combined class names
 */
export function classNames(...classes: ClassNameParam[]): string {
  return classNamesWithStyleMap(null, ...classes)
}
