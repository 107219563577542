// External
import { createSlice } from '@reduxjs/toolkit'

const isMobileSlice = createSlice({
  name: 'isMobileReducer',
  initialState: false,
  reducers: {
    isMobileTrue: (state) => true,
    isMobileFalse: (state) => false,
  },
})

export const { isMobileTrue, isMobileFalse } = isMobileSlice.actions

export default isMobileSlice.reducer
