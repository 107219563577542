import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SaveSearchModal2Actions } from '@src/partials/searchv2/components/SaveSearchModal2/SaveSearchModal2.actions'
import { LEGACY_Bookmark } from '@src/partials/searchv2/redux/LEGACY_BookmarkOptimisticSlice.types'
import { SearchPageSliceActions, commonUpdateSelectedListing } from '@src/partials/searchv2/redux/SearchPageSlice'
import { SearchPageQuerySliceActions } from '@src/partials/searchv2/widgets/DefaultQueryWithSaveButtonAndFiltersWidget/SearchPageQuerySlice'
import { createVendorViewFromQuery } from '../vendor-view-state'
import { SearchPageVendorSliceType, isSearchPageVendorSliceReady } from './SearchPageVendorSlice.types'

const initState: SearchPageVendorSliceType = {
  state: 'initial',
}

/**
 * This slice contains some common actions used by all the views.
 */
const SearchPageVendorSlice = createSlice({
  name: 'SearchPageVendorSlice',
  initialState: initState as SearchPageVendorSliceType,
  reducers: {
    setActiveSavedSearch: (slice, action: PayloadAction<{ id: string; value: string; label: string } | null>): void => {
      if (!isSearchPageVendorSliceReady(slice)) return
      slice.view.pageFrom = 0
      slice.view.savedSearch = action.payload
    },
    setPageFrom: (slice, action: PayloadAction<number>): void => {
      if (!isSearchPageVendorSliceReady(slice)) return
      slice.view.pageFrom = action.payload
    },
    setAdvanced: (slice, action: PayloadAction<boolean | null>): void => {
      if (!isSearchPageVendorSliceReady(slice)) return
      slice.view.pageFrom = 0
      slice.view.persisted.advanced = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(SearchPageSliceActions.setSelectedListing, (slice, action: PayloadAction<any | null>): void => {
      if (!isSearchPageVendorSliceReady(slice)) return

      commonUpdateSelectedListing(slice, action.payload)
    })
    builder.addCase(
      SearchPageSliceActions.onLoadedListings,
      (
        state,
        action: PayloadAction<{
          // As with the legacy code, this expects parallel arrays
          listings: Array<{ id: string }>
          bookmarks: Array<LEGACY_Bookmark | null>
          numHits: number
        }>
      ) => {
        if (!isSearchPageVendorSliceReady(state)) return
        state.view.hitCount = action.payload.numHits
      }
    )
    builder.addCase(SearchPageSliceActions.init, (slice, action) => {
      const { query } = action.payload

      const view = createVendorViewFromQuery(query)
      if (view) {
        return {
          state: 'ready',
          view: view,
        }
      }
    })
    builder.addCase(SearchPageSliceActions.reset, (slice, action) => {
      return initState
    })
    builder.addCase(SaveSearchModal2Actions.created, (slice, action) => {
      const { savedSearchName, savedSearchUUId } = action.payload
      if (!isSearchPageVendorSliceReady(slice)) return
      slice.view.savedSearch = {
        id: savedSearchUUId,
        value: savedSearchUUId,
        label: savedSearchName,
      }
    })
    builder.addCase(SearchPageQuerySliceActions.queryChange, (slice, action) => {
      const { query } = action.payload
      if (!isSearchPageVendorSliceReady(slice)) return
      slice.view.pageFrom = 0
      slice.view.persisted.queryString = query
    })
  },
})

export const SearchPageVendorSliceActions = SearchPageVendorSlice.actions
export const SearchPageVendorReducer = SearchPageVendorSlice.reducer
