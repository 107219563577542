import { IDBPDatabase } from 'idb'
import { useEffect, useState } from 'react'
import { NextStageDbContext } from './NextStageDbContext'
import { openNextStageDatabase } from './openNextStageDatabase'

interface NextStageDbProps {
  children: React.ReactChild
}

export const NextStageDb = (props: NextStageDbProps) => {
  const { children } = props

  const [db, setDb] = useState<IDBPDatabase<any> | null>(null)
  useEffect(() => {
    openNextStageDatabase().then((nextstageDb) => setDb(nextstageDb))
  }, [])

  return <NextStageDbContext.Provider value={db}>{children}</NextStageDbContext.Provider>
}
