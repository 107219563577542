export const SNACKBAR_EVENT_TYPE = 'SNACKBAR_EVENT_TYPE'

export class SnackbarEvent extends Event {
  public message: string
  constructor(message: string) {
    super(SNACKBAR_EVENT_TYPE, {
      bubbles: false,
    })

    this.message = message
  }
}
