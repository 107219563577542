import { DateFilterValue } from '@src/partials/searchv2/components/CommonDateFilter/CommonDateFilter'
import { useSelector } from '@src/redux/use-selector'
import { ITESSpecializedFields, SEWPSpecializedFields, SeaPortSpecializedFields } from '../source-specialized-fields'
import { SearchPageAlexandriaSliceTypeReady, isSearchPageAlexandriaSliceReady } from './SearchPageAlexandriaSlice.types'

export function useAlexandriaSelector<T extends (state: SearchPageAlexandriaSliceTypeReady) => any>(
  selector: T
): null | ReturnType<T> {
  return useSelector((state) => {
    if (isSearchPageAlexandriaSliceReady(state.searchAlexandriaView)) return selector(state.searchAlexandriaView)
    else return null
  })
}

export function useAlexandriaLastModifiedDateFilter(): DateFilterValue | null {
  return useAlexandriaSelector((state) => {
    return state.view.persisted.lastModifiedDate
  })
}

export function useAlexandriaReceivedDateFilter(): DateFilterValue | null {
  return useAlexandriaSelector((state) => {
    return state.view.persisted.receivedDate
  })
}

export function useAlexandriaSortBy(): {
  id: string
  value: string
  label: string
} | null {
  return useAlexandriaSelector((state) => {
    return state.view.persisted.sortBy
  })
}

export function useAlexandriaSortByDirection(): 'asc' | 'desc' | null {
  return useAlexandriaSelector((state) => {
    return state.view.persisted.sortByDirection
  })
}

export function useAlexandriaReviewStatus(): {
  id: string
  value: any
  label: string
} | null {
  return useAlexandriaSelector((state) => {
    return state.view.persisted.reviewStatus
  })
}

export function useAlexandriaQuery(): string {
  const value = useAlexandriaSelector((state) => {
    return state.view.persisted.queryString
  })

  return value ?? ''
}

export function useAlexandriaActiveSavedSearch(): {
  id: string
  value: string
  label: string
} | null {
  return useAlexandriaSelector((state) => {
    return state.view.savedSearch
  })
}

export function useAlexandriaSelectedListing(): any {
  return useAlexandriaSelector((state) => {
    return state.view.selectedListing
  })
}

export function useAlexandriaPageFrom(): number | null {
  return useAlexandriaSelector((state) => {
    return state.view.pageFrom
  })
}

export function useAlexandriaHitCount(): number | null {
  return useAlexandriaSelector((state) => {
    return state.view.hitCount
  })
}

export function useAlexandriaSource(): string | null {
  return useAlexandriaSelector((state) => {
    return state.view.persisted.source
  })
}

export function useAlexandriaAdvanced(): boolean | null {
  return useAlexandriaSelector((state) => {
    return state.view.persisted.advanced
  })
}

export const ALEXANDRIA_PAGE_SIZE = 40

/**
 * Attention! If the alexandria source starts to deviate too much from "just a few extra filters",
 * then consider making a separate view for it.
 */
export function useAlexandriaSeaPortSelector<T extends (state: SeaPortSpecializedFields) => any>(
  selector: T
): null | ReturnType<T> {
  return useSelector((state) => {
    if (
      isSearchPageAlexandriaSliceReady(state.searchAlexandriaView) &&
      state.searchAlexandriaView.view.persisted.specialization?.type === 'SeaPortSpecializedFields'
    )
      return selector(state.searchAlexandriaView.view.persisted.specialization)
    else return null
  })
}

/**
 * Attention! If the alexandria source starts to deviate too much from "just a few extra filters",
 * then consider making a separate view for it.
 */
export function useAlexandriaSEWPSelector<T extends (state: SEWPSpecializedFields) => any>(
  selector: T
): null | ReturnType<T> {
  return useSelector((state) => {
    if (
      isSearchPageAlexandriaSliceReady(state.searchAlexandriaView) &&
      state.searchAlexandriaView.view.persisted.specialization?.type === 'SEWPSpecializedFields'
    )
      return selector(state.searchAlexandriaView.view.persisted.specialization)
    else return null
  })
}

/**
 * Attention! If the alexandria source starts to deviate too much from "just a few extra filters",
 * then consider making a separate view for it.
 */
export function useAlexandriaITESSelector<T extends (state: ITESSpecializedFields) => any>(
  selector: T
): null | ReturnType<T> {
  return useSelector((state) => {
    if (
      isSearchPageAlexandriaSliceReady(state.searchAlexandriaView) &&
      state.searchAlexandriaView.view.persisted.specialization?.type === 'ITESSpecializedFields'
    )
      return selector(state.searchAlexandriaView.view.persisted.specialization)
    else return null
  })
}
