import { BookmarkReason } from '@interfaces/graphql'
import { SavedSearch2, SearchTargets, convertQueryParamsToSavedSearch } from '@nextweb/common'
import { DateFilterValue } from '../../components/CommonDateFilter/CommonDateFilter'
import { getSavedSearchFromQueryParams } from '../../redux/saved-search-query-params'
import { firstString } from '../../redux/utils'
import { SEARCH_VIEW_QUERY_PARAM_KEY } from '../../url-management/search-view-query-param-key'
import { convertSavedSearchToSymphonyView } from './saved-search/convert-symphony-view-and-saved-search'
import {
  SYMPHONY_VIEW_SORT_BY_LAST_MODIFIED,
  SYMPHONY_VIEW_SORT_BY_OPTIONS,
} from './components/SymphonyViewSortByDropdown'

/**
 * These don't have to be the same but it is convenient if they are.
 */
export const SYMPHONY_VIEW_KEY = SearchTargets.symphony.label

export function isSymphonyViewKey(s?: any): boolean {
  s = Array.isArray(s) ? firstString(s) : s
  return typeof s === 'string' && s.toUpperCase() === SYMPHONY_VIEW_KEY.toUpperCase()
}

// All serializable/saveable state should go into this.
export interface SymphonyViewPersisted {
  // Some common stuff, not sure how to colocate this stuff yet.
  // SavedSearch dropdown is not itself persisted.
  // The SavedSearch dropdown is still populated by query, etc.
  // savedSearch: { id: string; value: string; label: string } | null
  advanced: boolean | null
  reviewStatus: {
    id: string
    value: Array<BookmarkReason> | 'all-opps'
    label: string
  } | null
  sortBy: { id: string; value: string; label: string } | null
  sortByDirection: 'asc' | 'desc' | null

  // "The" query string.
  queryString: string

  // Symphony Specific stuff
  opportunitySource: Array<{ id: string; value: string; label: string }> | null
  opportunityType: Array<{ id: string; value: string; label: string }> | null

  postedOnDate: DateFilterValue | null
  respondByDate: DateFilterValue | null
}

export interface SymphonyViewState {
  type: typeof SYMPHONY_VIEW_KEY

  // All serializable/saveable state should go into this.
  persisted: SymphonyViewPersisted

  savedSearch: { id: string; value: string; label: string } | null

  selectedListing: any
  pageFrom: number
  hitCount: number | null
}

export const SYMPHONY_VIEW_DEFAULT_STATE: SymphonyViewPersisted = {
  advanced: true,
  reviewStatus: null,
  sortBy: SYMPHONY_VIEW_SORT_BY_OPTIONS.find(({ value }) => value === SYMPHONY_VIEW_SORT_BY_LAST_MODIFIED)!,
  sortByDirection: 'desc',

  // "The" query string.
  queryString: '',

  // Symphony Specific stuff
  opportunitySource: null,
  opportunityType: null,

  postedOnDate: null,
  respondByDate: null,
}

export const SYMPHONY_VIEW_EMPTY_STATE: SymphonyViewPersisted = {
  advanced: null,
  reviewStatus: null,
  sortBy: null,
  sortByDirection: null,

  // "The" query string.
  queryString: '',

  // Symphony Specific stuff
  opportunitySource: null,
  opportunityType: null,

  postedOnDate: null,
  respondByDate: null,
}

/**
 * This may return null if the query fields are really malformed.
 * @param query
 */
export function createSymphonyViewFromQuery(query: Record<string, string | string[]>): SymphonyViewState | null {
  // This should be checked before calling this function ideally.
  if (!isSymphonyViewKey(query[SEARCH_VIEW_QUERY_PARAM_KEY])) return null

  const savedSearch = convertQueryParamsToSavedSearch({
    params: query,
  })

  return {
    type: SYMPHONY_VIEW_KEY,
    selectedListing: null,
    pageFrom: 0,
    hitCount: null,
    savedSearch: getSavedSearchFromQueryParams({ params: query }),
    persisted:
      savedSearch && !SavedSearch2.isEmptySavedSearch(savedSearch)
        ? convertSavedSearchToSymphonyView(savedSearch)
        : SYMPHONY_VIEW_DEFAULT_STATE,
  }
}
