import { DateFilterValue } from '@src/partials/searchv2/components/CommonDateFilter/CommonDateFilter'
import { useSelector } from '@src/redux/use-selector'
import { SearchPageAPFSSliceTypeReady, isSearchPageAPFSSliceReady } from './SearchPageAPFSSlice.types'

export function useAPFSSelector<T extends (state: SearchPageAPFSSliceTypeReady) => any>(
  selector: T
): null | ReturnType<T> {
  return useSelector((state) => {
    if (isSearchPageAPFSSliceReady(state.searchAPFSView)) return selector(state.searchAPFSView)
    else return null
  })
}

export function useAPFSRFPReleaseDateFilter(): DateFilterValue | null {
  return useAPFSSelector((state) => {
    return state.view.persisted.rfpReleaseDate
  })
}

export function useAPFSAPFSPublishDateFilter(): DateFilterValue | null {
  return useAPFSSelector((state) => {
    return state.view.persisted.apfsPublishDate
  })
}

export function useAPFSPeriodOfPerformanceStartDateFilter(): DateFilterValue | null {
  return useAPFSSelector((state) => {
    return state.view.persisted.periodOfPerformanceStart
  })
}

export function useAPFSPeriodOfPerformanceEndDateFilter(): DateFilterValue | null {
  return useAPFSSelector((state) => {
    return state.view.persisted.periodOfPerformanceEnd
  })
}

export function useAPFSSortBy(): {
  id: string
  value: string
  label: string
} | null {
  return useAPFSSelector((state) => {
    return state.view.persisted.sortBy
  })
}

export function useAPFSSortByDirection(): 'asc' | 'desc' | null {
  return useAPFSSelector((state) => {
    return state.view.persisted.sortByDirection
  })
}

export function useAPFSReviewStatus(): {
  id: string
  value: any
  label: string
} | null {
  return useAPFSSelector((state) => {
    return state.view.persisted.reviewStatus
  })
}

export function useAPFSQuery(): string {
  const value = useAPFSSelector((state) => {
    return state.view.persisted.queryString
  })

  return value ?? ''
}

export function useAPFSActiveSavedSearch(): {
  id: string
  value: string
  label: string
} | null {
  return useAPFSSelector((state) => {
    return state.view.savedSearch
  })
}

export function useAPFSSelectedListing(): any {
  return useAPFSSelector((state) => {
    return state.view.selectedListing
  })
}

export function useAPFSPageFrom(): number | null {
  return useAPFSSelector((state) => {
    return state.view.pageFrom
  })
}

export function useAPFSHitCount(): number | null {
  return useAPFSSelector((state) => {
    return state.view.hitCount
  })
}

export function useAPFSNAICSFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useAPFSSelector((state) => {
    return state.view.persisted.naics
  })
}

export function useAPFSPlaceOfPerformanceFilter(): Array<{
  id: string
  value: string
  label: string
}> | null {
  return useAPFSSelector((state) => {
    return state.view.persisted.placeOfPerformance
  })
}

export function useAPFSAdvanced(): boolean | null {
  return useAPFSSelector((state) => {
    return state.view.persisted.advanced
  })
}

export const APFS_PAGE_SIZE = 40
