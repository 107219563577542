import { FEDCONNECT_SAVED_SEARCH_FIELDS, SavedSearch2, SearchTargets } from '@nextweb/common'
import {
  getDropdownValuesFromFilterValues,
  getReviewStatusDropdownValueFromFilterValue,
  serializeCommonMultiDropdownViewProperty,
} from '@src/partials/searchv2/saved-search/common-conversions'
import {
  convertCommonDateFilterValueToSavedSearchValue,
  convertSavedSearchValueToCommonDateFilterValue,
} from '@src/partials/searchv2/saved-search/convert-common-date-filter-value-to-saved-search-value'
import _ from 'lodash'
import {
  FEDCONNECT_VIEW_SORT_BY_ISSUE_DATE,
  FEDCONNECT_VIEW_SORT_BY_RESPONSE_DUE_DATE,
} from '../components/FedConnectViewSortByDropdown'
import { FEDCONNECT_VIEW_EMPTY_STATE, FedConnectViewPersisted } from '../fedconnect-view-state'

/**
 * Convert the SAM View Dropdown value into the value expected by the backend.
 * @param dropdownValue
 * @returns
 */
function serializeFedConnectViewSortByValue(dropdownValue: string) {
  switch (dropdownValue) {
    case FEDCONNECT_VIEW_SORT_BY_ISSUE_DATE:
      return FEDCONNECT_SAVED_SEARCH_FIELDS.sorts.issueDate
    case FEDCONNECT_VIEW_SORT_BY_RESPONSE_DUE_DATE:
      return FEDCONNECT_SAVED_SEARCH_FIELDS.sorts.responseDueDate
    default:
      return null
  }
}

function deserializeFedConnectViewSortByValue(dropdownValue: string) {
  switch (dropdownValue) {
    case FEDCONNECT_SAVED_SEARCH_FIELDS.sorts.issueDate:
      return FEDCONNECT_VIEW_SORT_BY_ISSUE_DATE
    case FEDCONNECT_SAVED_SEARCH_FIELDS.sorts.responseDueDate:
      return FEDCONNECT_VIEW_SORT_BY_RESPONSE_DUE_DATE
    default:
      return null
  }
}

export function convertFedConnectViewToSavedSearch(view: FedConnectViewPersisted): SavedSearch2.SavedSearch {
  const filters: Array<SavedSearch2.Filter> = []
  const sorts: Array<SavedSearch2.Sort> = []
  const options: Array<SavedSearch2.Option> = []

  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'agency', //
    FEDCONNECT_SAVED_SEARCH_FIELDS.filters.agency
  )
  serializeCommonMultiDropdownViewProperty(filters, view, 'type', FEDCONNECT_SAVED_SEARCH_FIELDS.filters.type)
  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'naics', //
    FEDCONNECT_SAVED_SEARCH_FIELDS.filters.naics
  )
  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'placeOfPerformance',
    FEDCONNECT_SAVED_SEARCH_FIELDS.filters.placeOfPerformance
  )
  serializeCommonMultiDropdownViewProperty(filters, view, 'setAside', FEDCONNECT_SAVED_SEARCH_FIELDS.filters.setaside)
  serializeCommonMultiDropdownViewProperty(filters, view, 'psc', FEDCONNECT_SAVED_SEARCH_FIELDS.filters.psc)

  if (view.responseDueDate) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.responseDueDate,
      FEDCONNECT_SAVED_SEARCH_FIELDS.filters.responseDueDate
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.issueDate) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.issueDate,
      FEDCONNECT_SAVED_SEARCH_FIELDS.filters.issueDate
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.queryString) {
    filters.push({
      type: 'QueryFilter',
      field: 'query',
      display: view.queryString,
      value: view.queryString,
    })
  }

  if (view.reviewStatus) {
    filters.push({
      type: 'MatchAllFilter',
      field: FEDCONNECT_SAVED_SEARCH_FIELDS.filters.reviewStatus,
      display: [view.reviewStatus.label],
      values: view.reviewStatus.value === 'all-opps' ? ['all-opps'] : view.reviewStatus.value,
    })
  }

  if (view.sortBy) {
    const convertToBEUnderstood = serializeFedConnectViewSortByValue(view.sortBy.value)
    if (convertToBEUnderstood) {
      sorts.push({
        type: 'Sort',
        display: '',
        overrideMode: null,
        field: convertToBEUnderstood,
        order: view.sortByDirection === 'asc' ? 'asc' : 'desc',
      })
    }
  }

  if (view.advanced !== null) {
    options.push({
      type: 'FlagOption',
      field: FEDCONNECT_SAVED_SEARCH_FIELDS.options.advanced,
      value: view.advanced,
    })
  }

  return {
    filters: filters,
    sorts: sorts,
    options: options,
    searchTarget: SearchTargets.fedconnect.key,
  }
}

export function convertSavedSearchToFedConnectView(savedSearch: SavedSearch2.SavedSearch): FedConnectViewPersisted {
  const view: FedConnectViewPersisted = _.cloneDeep(FEDCONNECT_VIEW_EMPTY_STATE)

  for (const filter of savedSearch.filters) {
    switch (filter.field) {
      case FEDCONNECT_SAVED_SEARCH_FIELDS.filters.query: {
        if (filter.type === 'QueryFilter') {
          view.queryString = filter.value
        } else {
          console.error('Unexpected filter type for query')
        }
        break
      }
      case FEDCONNECT_SAVED_SEARCH_FIELDS.filters.reviewStatus: {
        if (filter.type === 'MatchAllFilter') {
          view.reviewStatus = getReviewStatusDropdownValueFromFilterValue(filter)
        } else {
          console.error('Unexpected filter type for reviewStatus')
        }
        break
      }
      case FEDCONNECT_SAVED_SEARCH_FIELDS.filters.agency: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.agency = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for agency')
        }
        break
      }
      case FEDCONNECT_SAVED_SEARCH_FIELDS.filters.type: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.type = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for type')
        }
        break
      }
      case FEDCONNECT_SAVED_SEARCH_FIELDS.filters.naics: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.naics = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for naics')
        }
        break
      }
      case FEDCONNECT_SAVED_SEARCH_FIELDS.filters.placeOfPerformance: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.placeOfPerformance = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for placeOfPerformance')
        }
        break
      }
      case FEDCONNECT_SAVED_SEARCH_FIELDS.filters.setaside: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.setAside = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for setAside')
        }
        break
      }
      case FEDCONNECT_SAVED_SEARCH_FIELDS.filters.psc: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.psc = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for psc')
        }
        break
      }
      case FEDCONNECT_SAVED_SEARCH_FIELDS.filters.issueDate: {
        if (filter.type === 'DateFilter') {
          view.issueDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for issueDate')
        }
        break
      }
      case FEDCONNECT_SAVED_SEARCH_FIELDS.filters.responseDueDate: {
        if (filter.type === 'DateFilter') {
          view.responseDueDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for responseDueDate')
        }
        break
      }
      default:
        console.error('Unexpected filter field: ' + filter.field)
        break
    }
  }

  for (const sort of savedSearch.sorts) {
    if (sort.type === 'Sort') {
      const dropdownValueValue = deserializeFedConnectViewSortByValue(sort.field)
      if (dropdownValueValue) {
        view.sortBy = {
          id: dropdownValueValue,
          value: dropdownValueValue,
          label: sort.display,
        }
        view.sortByDirection = sort.order
      }

      // We only support 1 sort at the moment.
      break
    } else {
      console.error('Unexpected sort type: ' + sort.type)
    }
  }

  for (const option of savedSearch.options) {
    switch (option.field) {
      case FEDCONNECT_SAVED_SEARCH_FIELDS.options.advanced: {
        if (option.type === 'FlagOption') {
          view.advanced = option.value
        } else {
          console.error('Unexpected option type for advanced')
        }
        break
      }
      default:
        console.error('Unexpected option field: ' + option.field)
        break
    }
  }

  return view
}
