// External
import { createSlice } from '@reduxjs/toolkit'

const initState = {
  showModal: false,
  header: false,
  content: false,
  success: false,
}

const modalSlice = createSlice({
  name: 'modalReducer',
  initialState: initState,
  reducers: {
    toggleShowModal: (state, action) => ({ ...state, showModal: !state.showModal }),

    resetModal: () => initState,

    setModalContent(state, action) {
      let { content, header, showModal = true } = action.payload
      state.showModal = showModal
      state.header = header
      state.content = content
      state.onClose = action.payload.onClose
    },

    setModalSuccess(state, action) {
      state.success = action.payload
    },
  },
})

export const { toggleShowModal, setModalContent, setModalSuccess, resetModal } = modalSlice.actions

export default modalSlice.reducer
