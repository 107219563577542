import { AlexandriaViewState } from '../alexandria-view-state'

export type SearchPageAlexandriaSliceType = SearchPageAlexandriaSliceTypeInitial | SearchPageAlexandriaSliceTypeReady

export type SearchPageAlexandriaSliceTypeInitial = {
  state: 'initial'
}

export type SearchPageAlexandriaSliceTypeReady = {
  state: 'ready'

  // 'view' contains state as the state is displayed on the page, or, in a form
  // that the components on the page require.
  view: AlexandriaViewState
}

export type SearchPageAlexandriaSliceViewType = Exclude<SearchPageAlexandriaSliceTypeReady['view'], null>['type']

export type SearchPageViewWithType<T extends SearchPageAlexandriaSliceViewType> = Extract<
  SearchPageAlexandriaSliceTypeReady['view'],
  { type: T }
>

export function isSearchPageAlexandriaSliceReady(
  s: SearchPageAlexandriaSliceType
): s is SearchPageAlexandriaSliceTypeReady {
  return s.state === 'ready'
}
