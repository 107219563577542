import { SymphonyViewState } from '../symphony-view-state'

export type SearchPageSymphonySliceType = SearchPageSymphonySliceTypeInitial | SearchPageSymphonySliceTypeReady

export type SearchPageSymphonySliceTypeInitial = {
  state: 'initial'
}

export type SearchPageSymphonySliceTypeReady = {
  state: 'ready'

  // 'view' contains state as the state is displayed on the page, or, in a form
  // that the components on the page require.
  view: SymphonyViewState
}

export type SearchPageSymphonySliceViewType = Exclude<SearchPageSymphonySliceTypeReady['view'], null>['type']

export type SearchPageViewWithType<T extends SearchPageSymphonySliceViewType> = Extract<
  SearchPageSymphonySliceTypeReady['view'],
  { type: T }
>

export function isSearchPageSymphonySliceReady(s: SearchPageSymphonySliceType): s is SearchPageSymphonySliceTypeReady {
  return s.state === 'ready'
}
