import { EBUY_SAVED_SEARCH_FIELDS, SavedSearch2, SearchTargets } from '@nextweb/common'
import {
  getDropdownValuesFromFilterValues,
  getReviewStatusDropdownValueFromFilterValue,
  serializeCommonMultiDropdownViewProperty,
} from '@src/partials/searchv2/saved-search/common-conversions'
import {
  convertCommonDateFilterValueToSavedSearchValue,
  convertSavedSearchValueToCommonDateFilterValue,
} from '@src/partials/searchv2/saved-search/convert-common-date-filter-value-to-saved-search-value'
import _ from 'lodash'
import {
  EBUY_VIEW_SORT_BY_LAST_MODIFIED,
  EBUY_VIEW_SORT_BY_POSTED_ON,
  EBUY_VIEW_SORT_BY_RESPOND_BY,
} from '../components/EBuyViewSortByDropdown'
import { EBUY_VIEW_EMPTY_STATE, EBuyViewPersisted } from '../ebuy-view-state'

/**
 * Convert the EBuy View Dropdown value into the value expected by the backend.
 * @param dropdownValue
 * @returns
 */
function serializeEBuyViewSortByValue(dropdownValue: string) {
  switch (dropdownValue) {
    case EBUY_VIEW_SORT_BY_RESPOND_BY:
      return EBUY_SAVED_SEARCH_FIELDS.sorts.respondByDate
    case EBUY_VIEW_SORT_BY_POSTED_ON:
      return EBUY_SAVED_SEARCH_FIELDS.sorts.postedOnDate
    case EBUY_VIEW_SORT_BY_LAST_MODIFIED:
      return EBUY_SAVED_SEARCH_FIELDS.sorts.lastModified
    default:
      return null
  }
}

function deserializeEBuyViewSortByValue(value: string) {
  switch (value) {
    case EBUY_SAVED_SEARCH_FIELDS.sorts.respondByDate:
      return EBUY_VIEW_SORT_BY_RESPOND_BY
    case EBUY_SAVED_SEARCH_FIELDS.sorts.postedOnDate:
      return EBUY_VIEW_SORT_BY_POSTED_ON
    case EBUY_SAVED_SEARCH_FIELDS.sorts.lastModified:
      return EBUY_VIEW_SORT_BY_LAST_MODIFIED
    default:
      return null
  }
}

export function convertEBuyViewToSavedSearch(view: EBuyViewPersisted): SavedSearch2.SavedSearch {
  const filters: Array<SavedSearch2.Filter> = []
  const sorts: Array<SavedSearch2.Sort> = []
  const options: Array<SavedSearch2.Option> = []

  // Note! The key in the state happens to coincide with the "Field" value,
  // but make no mistake, they are not the same, you can store the value
  // in any field you want on the client side, but the "Field" value must match
  // a EBuy field on the BE.
  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'agency', //
    EBUY_SAVED_SEARCH_FIELDS.filters.agency
  )

  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'subAgency', //
    EBUY_SAVED_SEARCH_FIELDS.filters.subAgency
  )

  serializeCommonMultiDropdownViewProperty(
    filters,
    view,
    'sins', //
    EBUY_SAVED_SEARCH_FIELDS.filters.sins
  )

  if (view.postedOnDate) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.postedOnDate,
      EBUY_SAVED_SEARCH_FIELDS.filters.postedOnDate
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.respondByDate) {
    const filterValue = convertCommonDateFilterValueToSavedSearchValue(
      view.respondByDate,
      EBUY_SAVED_SEARCH_FIELDS.filters.respondByDate
    )
    if (filterValue) filters.push(filterValue)
  }

  if (view.queryString) {
    filters.push({
      type: 'QueryFilter',
      field: 'query',
      display: view.queryString,
      value: view.queryString,
    })
  }

  if (view.reviewStatus) {
    filters.push({
      type: 'MatchAllFilter',
      field: EBUY_SAVED_SEARCH_FIELDS.filters.reviewStatus,
      display: [view.reviewStatus.label],
      values: view.reviewStatus.value === 'all-opps' ? ['all-opps'] : view.reviewStatus.value,
    })
  }

  if (view.sortBy) {
    const convertToBEUnderstood = serializeEBuyViewSortByValue(view.sortBy.value)
    if (convertToBEUnderstood) {
      sorts.push({
        type: 'Sort',
        display: '',
        overrideMode: null,
        field: convertToBEUnderstood,
        order: view.sortByDirection === 'asc' ? 'asc' : 'desc',
      })
    }
  }

  if (view.advanced !== null) {
    options.push({
      type: 'FlagOption',
      field: EBUY_SAVED_SEARCH_FIELDS.options.advanced,
      value: view.advanced,
    })
  }

  return {
    filters: filters,
    sorts: sorts,
    options: options,
    searchTarget: SearchTargets.ebuy.key,
  }
}

export function convertSavedSearchToEBuyView(savedSearch: SavedSearch2.SavedSearch): EBuyViewPersisted {
  const view: EBuyViewPersisted = _.cloneDeep(EBUY_VIEW_EMPTY_STATE)

  for (const filter of savedSearch.filters) {
    switch (filter.field) {
      case EBUY_SAVED_SEARCH_FIELDS.filters.agency: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.agency = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for agency')
        }
        break
      }
      case EBUY_SAVED_SEARCH_FIELDS.filters.subAgency: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.subAgency = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for subagency')
        }
        break
      }
      case EBUY_SAVED_SEARCH_FIELDS.filters.sins: {
        if (filter.type === 'AtLeastOneOfFilter') {
          view.sins = getDropdownValuesFromFilterValues(filter)
        } else {
          console.error('Unexpected filter type for sins')
        }
        break
      }
      case EBUY_SAVED_SEARCH_FIELDS.filters.postedOnDate: {
        if (filter.type === 'DateFilter') {
          view.postedOnDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for postedOnDate')
        }
        break
      }
      case EBUY_SAVED_SEARCH_FIELDS.filters.respondByDate: {
        if (filter.type === 'DateFilter') {
          view.respondByDate = convertSavedSearchValueToCommonDateFilterValue(filter)
        } else {
          console.error('Unexpected filter type for respondByDate')
        }
        break
      }
      case EBUY_SAVED_SEARCH_FIELDS.filters.query: {
        if (filter.type === 'QueryFilter') {
          view.queryString = filter.value
        } else {
          console.error('Unexpected filter type for query')
        }
        break
      }
      case EBUY_SAVED_SEARCH_FIELDS.filters.reviewStatus: {
        if (filter.type === 'MatchAllFilter') {
          view.reviewStatus = getReviewStatusDropdownValueFromFilterValue(filter)
        } else {
          console.error('Unexpected filter type for reviewStatus')
        }
        break
      }

      default:
        console.error('Unexpected filter field: ' + filter.field)
        break
    }
  }

  for (const sort of savedSearch.sorts) {
    if (sort.type === 'Sort') {
      const dropdownValueValue = deserializeEBuyViewSortByValue(sort.field)
      if (dropdownValueValue) {
        view.sortBy = {
          id: dropdownValueValue,
          value: dropdownValueValue,
          label: sort.display,
        }
        view.sortByDirection = sort.order
      }

      // We only support 1 sort at the moment.
      break
    } else {
      console.error('Unexpected sort type: ' + sort.type)
    }
  }

  for (const option of savedSearch.options) {
    switch (option.field) {
      case EBUY_SAVED_SEARCH_FIELDS.options.advanced: {
        if (option.type === 'FlagOption') {
          view.advanced = option.value
        } else {
          console.error('Unexpected option type for advanced')
        }
        break
      }
      default:
        console.error('Unexpected option field: ' + option.field)
        break
    }
  }

  return view
}
