import { GOVWIN_EVENT_TYPE, GovWinEvent } from '@src/apollo/govwin/GovWinErrors'
import Link from 'next/link'
import { useEffect } from 'react'
import { toast } from 'react-toastify'

function getAreThisMessage(errorType: 'Expired' | 'Bad') {
  switch (errorType) {
    case 'Bad':
      return 'incorrect'
    case 'Expired':
      return 'expired'
  }
}

interface GovWinToastProps {
  errorType: 'Expired' | 'Bad'
}
export const GovWinToast = (props: GovWinToastProps) => {
  const { errorType } = props

  return (
    <div>
      <div className="red">
        <b>GovWin API Error</b>
      </div>
      <span>
        We have detected an error with the GovWin API credentials in your workspace.
        <br />
        <br />
        The credentials for the GovWin account in this workspace are <b>{getAreThisMessage(errorType)}</b>. Please
        review and update them from the{' '}
        <Link className="link" style={{ margin: '0 4px' }} href="/admin">
          admin page
        </Link>{' '}
        to resume API connectivity.
      </span>
    </div>
  )
}

export function useGovWinToast() {
  useEffect(() => {
    function onWindowGovWinEventListener(e: Event) {
      if (e instanceof GovWinEvent) {
        toast(<GovWinToast errorType={e.govWinErrorType} />, {
          position: 'bottom-center',
          autoClose: 30000,
          hideProgressBar: true,
        })
      }
    }
    if (typeof window !== 'undefined') {
      window.addEventListener(GOVWIN_EVENT_TYPE, onWindowGovWinEventListener)

      return () => {
        window.removeEventListener(GOVWIN_EVENT_TYPE, onWindowGovWinEventListener)
      }
    }
  }, [])
}
