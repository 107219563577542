// External
import { createSlice } from '@reduxjs/toolkit'
import { Pipeline, PipelineView, SortDirection, PipelineViewMode } from '@interfaces/graphql'

export interface CurrentPipelineState {
  pipeline?: Pipeline
  view?: PipelineView
  sortColumn?: string
  sortDirection?: SortDirection
  tableColumnOverrides?: any
  viewMode: PipelineViewMode
}

const initState: CurrentPipelineState = {
  pipeline: undefined,
  view: undefined,
  sortColumn: undefined,
  sortDirection: undefined,
  tableColumnOverrides: undefined,
  viewMode: PipelineViewMode.Board,
}

const pipelineSlice = createSlice({
  name: 'modalReducer',
  initialState: initState,
  reducers: {
    setCurrentPipeline(state, action) {
      let { pipeline, view, sortColumn, sortDirection, tableColumnOverrides, viewMode } =
        action.payload as CurrentPipelineState
      state.pipeline = pipeline
      state.view = view
      state.sortColumn = sortColumn
      state.sortDirection = sortDirection
      state.tableColumnOverrides = tableColumnOverrides
      state.viewMode = viewMode
    },
    resetCurrentPipeline: () => initState,
  },
})

export const { setCurrentPipeline, resetCurrentPipeline } = pipelineSlice.actions

export default pipelineSlice.reducer
