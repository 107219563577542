import { BookmarkReason } from '@interfaces/graphql'
import { SavedSearch2, SearchTargets, convertQueryParamsToSavedSearch } from '@nextweb/common'
import { DateFilterValue } from '../../components/CommonDateFilter/CommonDateFilter'
import { getSavedSearchFromQueryParams } from '../../redux/saved-search-query-params'
import { firstString } from '../../redux/utils'
import { SEARCH_VIEW_QUERY_PARAM_KEY } from '../../url-management/search-view-query-param-key'
import { convertSavedSearchToAPFSView } from './saved-search/convert-apfs-view-and-saved-search'
import { APFS_VIEW_SORT_BY_OPTIONS, APFS_VIEW_SORT_BY_RFP_RELEASE_DATE } from './components/APFSViewSortByDropdown'

export const APFS_VIEW_KEY = SearchTargets.apfs.label

export function isAPFSViewKey(s?: any): boolean {
  s = Array.isArray(s) ? firstString(s) : s
  return typeof s === 'string' && s.toUpperCase() === APFS_VIEW_KEY.toUpperCase()
}

// All serializable/saveable state should go into this.
export interface APFSViewPersisted {
  advanced: boolean | null
  reviewStatus: {
    id: string
    value: Array<BookmarkReason> | 'all-opps'
    label: string
  } | null
  sortBy: { id: string; value: string; label: string } | null
  sortByDirection: 'asc' | 'desc' | null

  queryString: string

  naics: Array<{ id: string; value: string; label: string }> | null
  placeOfPerformance: Array<{ id: string; value: string; label: string }> | null

  rfpReleaseDate: DateFilterValue | null
  apfsPublishDate: DateFilterValue | null
  periodOfPerformanceStart: DateFilterValue | null
  periodOfPerformanceEnd: DateFilterValue | null
}

export interface APFSViewState {
  type: typeof APFS_VIEW_KEY

  // All serializable/saveable state should go into this.
  persisted: APFSViewPersisted

  savedSearch: { id: string; value: string; label: string } | null

  selectedListing: any
  pageFrom: number
  hitCount: number | null
}

/**
 * Attention! If a particular alexandria source begins to deviate too much from
 * this structure, consider creating a new view type!
 */
export const APFS_VIEW_DEFAULT_STATE: APFSViewPersisted = {
  advanced: true,
  reviewStatus: null,
  sortBy: APFS_VIEW_SORT_BY_OPTIONS.find(({ value }) => value === APFS_VIEW_SORT_BY_RFP_RELEASE_DATE)!,
  sortByDirection: 'desc',

  // "The" query string.
  queryString: '',

  naics: null,
  placeOfPerformance: null,

  rfpReleaseDate: null,
  apfsPublishDate: null,
  periodOfPerformanceStart: null,
  periodOfPerformanceEnd: null,
}

export const APFS_VIEW_EMPTY_STATE: APFSViewPersisted = {
  advanced: null,
  reviewStatus: null,
  sortBy: null,
  sortByDirection: null,

  // "The" query string.
  queryString: '',

  naics: null,
  placeOfPerformance: null,

  rfpReleaseDate: null,
  apfsPublishDate: null,
  periodOfPerformanceStart: null,
  periodOfPerformanceEnd: null,
}

/**
 * This may return null if the query fields are really malformed.
 * @param query
 */
export function createAPFSViewFromQuery(query: Record<string, string | string[]>): APFSViewState | null {
  // This should be checked before calling this function ideally.
  if (!isAPFSViewKey(query[SEARCH_VIEW_QUERY_PARAM_KEY])) return null

  const savedSearch = convertQueryParamsToSavedSearch({
    params: query,
  })

  return {
    type: APFS_VIEW_KEY,
    selectedListing: null,
    pageFrom: 0,
    hitCount: null,
    savedSearch: getSavedSearchFromQueryParams({ params: query }),
    persisted:
      savedSearch && !SavedSearch2.isEmptySavedSearch(savedSearch)
        ? convertSavedSearchToAPFSView(savedSearch)
        : APFS_VIEW_DEFAULT_STATE,
  }
}
